import { RouteDeclaration } from 'core/routes.types';
import { matchPath } from 'react-router-dom';

export const ROOT_PATH = '/:brandHash';

export const getRouteAttributesFromPath = (
    pathName: string,
    routes: RouteDeclaration[],
): RouteDeclaration | undefined =>
    routes.find(({ path }) => {
        return matchPath(
            {
                path: `${ROOT_PATH}/${path}`,
            },
            pathName,
        );
    });
