import styled from '@emotion/styled';
import { logoHeaderSize } from 'components/WithHeader/WithHeader.style';
import { BottomBarContainer } from 'components/PageWithBottomBar/PageWithBottomBar.style';

export const ButtonContainer = styled(BottomBarContainer)`
    padding: ${({ theme }) => theme.base.spacing[2]};
    width: 100%;
    bottom: 0;
    position: sticky;
    background: ${({ theme }) => theme.feature.backgroundColor};
`;

export const HomeLogoContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const HomeLogo = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: ${logoHeaderSize};
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: ${({ theme }) => theme.base.radius.xl};
    background-image: url(${({ theme }) => theme.base.background});
`;
