import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ENV } from 'utils/env';
import StripeForm from '../StripeForm';

const stripePromise = loadStripe(ENV.STRIPE_PUBLIC_KEY);

export type StripeFormProps = {
    handleStripePaymentSubmit: (stripePaymentMethodId: string | undefined) => void;
    isLoading?: boolean;
};

export const StripePaymentMethod: React.FunctionComponent<StripeFormProps> = ({
    handleStripePaymentSubmit,
    isLoading: isLoadingCreateOrder,
}) => {
    return (
        <Elements stripe={stripePromise}>
            <StripeForm
                handleStripePaymentSubmit={handleStripePaymentSubmit}
                isLoading={isLoadingCreateOrder}
            />
        </Elements>
    );
};

export default StripePaymentMethod;
