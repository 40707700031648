import styled from '@emotion/styled';
import { Text } from 'components/Text';

export const crossSellingProductHeight = '120px';

export const Container = styled.div`
    width: 100%;
    position: relative;
`;

export const ImgContainer = styled.div<{ imgUrl: string; isSelected: boolean }>`
    position: relative;
    height: ${crossSellingProductHeight};
    border-radius: ${({ theme }) => theme.base.radius.default};
    background-image: url(${({ imgUrl }) => imgUrl});
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border: solid
        ${({ theme, isSelected }) =>
            isSelected
                ? `2px ${theme.feature.ctaColor}`
                : `1px ${theme.feature.thirdaryTextColor}`};
`;

export const NameContainer = styled(Text)<{ isSelected: boolean }>`
    display: flex;
    max-height: 48px;
    width: 100%;
    margin-top: ${({ theme }) => theme.base.spacing[1]};
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
    ${({ isSelected }) => isSelected && 'text-decoration: underline;'};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const LoaderShadow = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
