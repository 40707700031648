import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AccountPaymentType } from 'services/customer/customer.type';

export const userSliceName = 'user';

export type UserState =
    | {
          userName: string | null;
          accessToken: null;
          customerId: null;
          email: null;
          phone: null;
          accountPaymentType: null;
          ewalletOverdraftAmount: null;
      }
    | {
          userName: string;
          accessToken: string;
          customerId: number;
          email: string;
          phone: string;
          accountPaymentType: AccountPaymentType | null;
          ewalletOverdraftAmount: number | null;
      };

const initialState: UserState = {
    userName: null,
    accessToken: null,
    customerId: null,
    email: null,
    phone: null,
    accountPaymentType: null,
    ewalletOverdraftAmount: null,
};

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>, string>({
    name: userSliceName,
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<UserState | { userName: string }>) => ({
            ...state,
            ...action.payload,
        }),
        resetUser: (_state, _action: PayloadAction) => ({ ...initialState }),
    },
});

export const getUser = (state: RootState) => state.user;
export const getUserName = (state: RootState) => getUser(state).userName;
export const getAccessToken = (state: RootState) => getUser(state).accessToken;
export const getCustomerId = (state: RootState) => getUser(state).customerId;
export const getEmail = (state: RootState) => getUser(state).email;
export const getPhone = (state: RootState) => getUser(state).phone;
export const getAccountPaymentType = (state: RootState) => getUser(state).accountPaymentType;
export const getEwalletOverdraftAmount = (state: RootState) =>
    getUser(state).ewalletOverdraftAmount;

export const { updateUser, resetUser } = userSlice.actions;
