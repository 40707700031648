"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeTotalPriceFromItems = void 0;
var initialize_price_values_1 = require("../mappers/initialize-price-values");
var computeTotalPriceFromItems = function (items) {
    return items.reduce(function (cumul, item) { return ({
        totalInclTax: cumul.totalInclTax.plus(item.totalPriceNotRounded.totalInclTax),
        totalExclTax: cumul.totalExclTax.plus(item.totalPriceNotRounded.totalExclTax),
        totalInclTaxWithRemise: cumul.totalInclTaxWithRemise.plus(item.totalPriceNotRounded.totalInclTaxWithRemise),
        totalExclTaxWithRemise: cumul.totalExclTaxWithRemise.plus(item.totalPriceNotRounded.totalExclTaxWithRemise),
        totalInclTaxWithDiscount: cumul.totalInclTaxWithDiscount.plus(item.totalPriceNotRounded.totalInclTaxWithDiscount),
        totalExclTaxWithDiscount: cumul.totalExclTaxWithDiscount.plus(item.totalPriceNotRounded.totalExclTaxWithDiscount),
    }); }, (0, initialize_price_values_1.initPricesNotRounded)());
};
exports.computeTotalPriceFromItems = computeTotalPriceFromItems;
