import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from 'locales/fr';
import { computePrice } from 'utils/price';
import { Typography } from 'components/Text/TextStyle/TextStyle';
import { SAVORY_THEME } from '@innovorder/savory';
import { computeCaptionTypography } from './Caption.utils';

const CaptionStyle = styled.p<{ typography: Typography }>`
    ${({ typography }) => SAVORY_THEME.typography[typography]};
`;

export type CaptionFontWeights = 'Regular' | 'Bold';

type CaptionProps = {
    weight?: CaptionFontWeights;
    values?: Record<string, string | number | Date>;
};

type Props = CaptionProps &
    (
        | {
              noTranslation?: false;
              isCurrency?: false;
              currency?: string;
              text: TranslationKey;
          }
        | {
              noTranslation: true;
              isCurrency?: false;
              currency?: string;
              text: string;
          }
        | {
              noTranslation?: false;
              isCurrency: true;
              currency: string;
              text: number;
          }
    );

export const Caption: React.FunctionComponent<Props> = ({
    text,
    weight = 'Regular',
    values,
    noTranslation = false,
    isCurrency,
    currency,
    ...otherProps
}) => {
    const { t, i18n } = useTranslation();
    const typography = computeCaptionTypography(weight);
    return (
        <CaptionStyle {...otherProps} typography={typography}>
            {noTranslation && text}
            {isCurrency && currency && computePrice(i18n, text, currency)}
            {!noTranslation && !isCurrency && t(text as TranslationKey, values)}
        </CaptionStyle>
    );
};
