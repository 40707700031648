import { TranslationKey } from 'locales/fr';

export const computeLoginErrorMessage = (code: string): TranslationKey => {
    switch (code) {
        case 'login_failed':
            return 'login_page.login_failed';
        default:
            return 'login_page.uncatched_error';
    }
};
