import { SelectOptions } from 'components/RadioSelect';
import { SummarySelectedItem } from 'components/SelectionSummary/SelectionSummary';
import i18n from 'core/InitialiseThirdParties/i18n';
import {
    MissingSteps,
    CompositeProduct,
    CurrentPath,
    CompositeStep,
} from 'redux/currentCompositeProduct';
import { isConsumptionFlagActivated } from 'utils/consumptionFlags';
import { MenuState } from 'redux/menu';
import {
    HashMappedStepProduct,
    ProductStepRelation,
    StepAttributes,
    StepType,
} from 'services/menu/menu.type';
import { computePrice } from 'utils/price';
import { ConsumptionModeType } from '@innovorder/order_detail';
import { computeIsMaxChoiceReached as computeIsMaxChoiceReachedCheckbox } from 'components/CheckboxSelect/CheckboxSelect.utils';
import { computeIsMaxChoiceReached as computeIsMaxChoiceReachedQuantityPicker } from 'components/QuantityPickerSelect/QuantityPickerSelect.utils';
import {
    ComputedProductStep,
    SelectType,
    StepSelectType,
    StepStatusType,
} from './useProductStepsVM';

const computePriceOrExcess = (
    isQuantityPicker: boolean,
    isSelected: boolean,
    isInExcess: boolean,
    isMaxChoiceReached: boolean,
    price: number,
    exceedPrice: number,
    currency?: string,
) => {
    if (
        (!(isSelected && !isQuantityPicker) || isInExcess) &&
        isMaxChoiceReached &&
        exceedPrice &&
        currency
    ) {
        return `(+${computePrice(i18n, exceedPrice, currency)})`;
    }

    return price && currency ? `(+${computePrice(i18n, price, currency)})` : '';
};

// Sorting step prices to have a deterministic price on step, no matter the order
// See https://github.com/InnovOrder/monorepo/blob/f6ffb71e/services/api/src/infra/models/step/instance_methods/verify.ts#L58-L78
// and https://github.com/InnovOrder/monorepo/blob/dd993eb1/packages/angularcore/assets/core/ordering/step/step.factory.js#L423-L435
export const stepPriceSorting =
    (isWeightStrategyEnabled?: boolean) =>
    (productA: HashMappedStepProduct, productB: HashMappedStepProduct) => {
        const stepProductA = productA.step_product;
        const stepProductB = productB.step_product;

        let aPriceCompare = stepProductA.exceedPrice - stepProductA.price;
        let bPriceCompare = stepProductB.exceedPrice - stepProductB.price;
        if (isWeightStrategyEnabled) {
            aPriceCompare /= stepProductA.weight;
            bPriceCompare /= stepProductB.weight;
        }
        const priceComparison = bPriceCompare - aPriceCompare;
        if (!priceComparison) {
            return stepProductB.productId - stepProductA.productId;
        }
        return priceComparison;
    };

export type Selected =
    | {
          type: 'radio';
          value: number;
      }
    | {
          type: 'checkbox';
          value: Map<number, boolean>;
      }
    | {
          type: 'quantityPicker';
          value: Map<number, number>;
      };

export const computeSelectOptions = (
    menu: MenuState,
    step: StepAttributes,
    currency: string | undefined,
    selection: Selected,
    currentConsumptionModeType: ConsumptionModeType | null,
    allDisabled?: boolean,
): SelectOptions<number>[] => {
    const { stepId, isWeightStrategyEnabled, maxChoice } = step;
    const stepProducts = menu?.steps?.[stepId]?.stepProduct;
    const isStep = (product: HashMappedStepProduct) =>
        !!filterCrossSellSteps(menu?.products?.[product.productId]?.productStep).length;
    if (!stepProducts) {
        return [];
    }
    const isMaxChoiceReached =
        // only compute maxChoiceReached for checkbox & quantityPicker
        (selection.type === 'checkbox' &&
            computeIsMaxChoiceReachedCheckbox(selection.value, maxChoice)) ||
        (selection.type === 'quantityPicker' &&
            computeIsMaxChoiceReachedQuantityPicker(selection.value, maxChoice));

    const filteredProducts = stepProducts
        .filter(({ webAvailability }) => !!webAvailability)
        .filter(({ currentQuantity }) => currentQuantity !== 0)
        .filter(({ consumptionFlags }) =>
            isConsumptionFlagActivated(consumptionFlags, currentConsumptionModeType),
        );

    const sortedSelectedProducts = !isMaxChoiceReached
        ? []
        : filteredProducts
              .filter((product) => {
                  return selection.value.get(product.productId) !== undefined;
              })
              .sort(stepPriceSorting(isWeightStrategyEnabled));

    return filteredProducts
        .sort((a, b) => a.step_product.position - b.step_product.position)
        .map((product) => {
            const stepProduct = product?.step_product || {};
            const index = sortedSelectedProducts.findIndex(
                (selectedProduct) => selectedProduct.productId === product.productId,
            );
            const description = computePriceOrExcess(
                selection.type === 'quantityPicker',
                index >= 0,
                index >= step.maxChoice,
                isMaxChoiceReached,
                stepProduct.price,
                stepProduct.exceedPrice,
                currency,
            );

            return {
                name: product.name,
                description,
                value: product.productId,
                noTranslation: true,
                isStep: isStep(product),
                max: stepProduct.maxProductQuantity,
                ...(allDisabled ? { disabled: true } : {}),
            };
        });
};

const computeRemoveRadioPayload = (
    step: ProductStepRelation,
    currentProduct: CompositeProduct,
): CompositeProduct => {
    if (currentProduct.steps) {
        const { [step.stepId]: __, ...restSteps } = currentProduct.steps;

        return {
            quantity: currentProduct.quantity,
            steps: {
                ...restSteps,
            },
        };
    }

    return {
        quantity: currentProduct.quantity,
    };
};

const computeAddRadioPayload = (
    productId: number,
    step: ProductStepRelation,
    currentProduct: CompositeProduct,
): CompositeProduct | null => {
    const currentStep = currentProduct.steps?.[step.stepId];

    if (currentStep?.[productId]) {
        return null;
    }

    return {
        quantity: currentProduct.quantity,
        steps: {
            ...currentProduct.steps,
            [step.stepId]: {
                [productId]: {
                    quantity: 1,
                },
            },
        },
    };
};

const computeCheckboxPayload = (
    productId: number,
    step: ProductStepRelation,
    currentProduct: CompositeProduct,
    isSummary?: boolean,
): CompositeProductWithActions => {
    const currentStep = currentProduct.steps?.[step.stepId];

    if (currentStep?.[productId]) {
        const { [productId]: _, ...restProducts } = currentStep;

        if (isSummary) {
            // do not check/uncheck, just go inside the step when clicking on summary
            return {
                compositeProduct: null,
                shouldUpdateCurrentPath: true,
            };
        }

        // uncheck product/step section
        if (currentProduct.steps && Object.keys(restProducts).length === 0) {
            const { [step.stepId]: __, ...restSteps } = currentProduct.steps;

            // uncheck the last product and therefore the whole step
            return {
                compositeProduct: {
                    quantity: currentProduct.quantity,
                    steps: {
                        ...restSteps,
                    },
                },
                shouldUpdateCurrentPath: false,
            };
        }

        // uncheck the product (other products are still selected in the step)
        return {
            compositeProduct: {
                quantity: currentProduct.quantity,
                steps: {
                    ...currentProduct.steps,
                    [step.stepId]: {
                        ...restProducts,
                    },
                },
            },
            shouldUpdateCurrentPath: false,
        };
    }

    // check the new product
    return {
        compositeProduct: {
            quantity: currentProduct.quantity,
            steps: {
                ...currentProduct.steps,
                [step.stepId]: {
                    ...currentStep,
                    [productId]: {
                        quantity: 1,
                    },
                },
            },
        },
        shouldUpdateCurrentPath: true,
    };
};

const computeQuantityPickerPayload = (
    productId: number,
    step: ProductStepRelation,
    currentProduct: CompositeProduct,
    quantity: number,
    isSummary?: boolean,
): CompositeProductWithActions => {
    const currentStep = currentProduct.steps?.[step.stepId];

    // just go inside the step when clicking on summary
    if (isSummary) {
        return {
            compositeProduct: null,
            shouldUpdateCurrentPath: true,
        };
    }

    // remove product/step section
    if (currentStep?.[productId] && quantity === 0) {
        const { [productId]: _, ...restProducts } = currentStep;

        if (currentProduct.steps && Object.keys(restProducts).length === 0) {
            const { [step.stepId]: __, ...restSteps } = currentProduct.steps;

            // remove the last product and therefore the whole step
            return {
                compositeProduct: {
                    quantity: currentProduct.quantity,
                    steps: {
                        ...restSteps,
                    },
                },
                shouldUpdateCurrentPath: false,
            };
        }

        // remove the product (other products are still selected in the step)
        return {
            compositeProduct: {
                quantity: currentProduct.quantity,
                steps: {
                    ...currentProduct.steps,
                    [step.stepId]: {
                        ...restProducts,
                    },
                },
            },
            shouldUpdateCurrentPath: false,
        };
    }

    const restProducts = currentStep?.[productId];

    // update quantity
    return {
        compositeProduct: {
            quantity: currentProduct.quantity,
            steps: {
                ...currentProduct.steps,
                [step.stepId]: {
                    ...currentStep,
                    [productId]: {
                        ...restProducts,
                        quantity,
                    },
                },
            },
        },
        // go inside step only when adding the product (previous qty undefined or 0 => new qty 1)
        shouldUpdateCurrentPath: !restProducts?.quantity && quantity === 1,
    };
};

export type CompositeProductWithActions = {
    compositeProduct: CompositeProduct | null;
    shouldUpdateCurrentPath: boolean;
};

export const computeCompositeProductWithActions = (
    productId: number | undefined,
    step: ProductStepRelation,
    currentProduct: CompositeProduct,
    type: SelectType,
    isSummary?: boolean,
    quantity?: number,
): CompositeProductWithActions => {
    if (!productId) {
        return {
            compositeProduct: computeRemoveRadioPayload(step, currentProduct),
            shouldUpdateCurrentPath: true,
        };
    }

    if (type === 'radio') {
        return {
            compositeProduct: computeAddRadioPayload(productId, step, currentProduct),
            shouldUpdateCurrentPath: true,
        };
    }

    if (type === 'quantityPicker' && quantity !== undefined) {
        return computeQuantityPickerPayload(productId, step, currentProduct, quantity, isSummary);
    }

    return computeCheckboxPayload(productId, step, currentProduct, isSummary);
};

export type SelectedOptions = {
    radio: Record<number, number>;
    checkbox: Record<number, Map<number, boolean>>;
    quantityPicker: Record<number, Map<number, number>>;
};

export const computeSelectedOptions = (
    currentProduct: CompositeProduct | null,
    stepSelectType: StepSelectType,
): SelectedOptions => {
    const selected: SelectedOptions = {
        radio: {},
        checkbox: {},
        quantityPicker: {},
    };

    const steps = currentProduct?.steps;

    if (steps) {
        Object.entries(steps).forEach(([stepId, products]) => {
            if (stepSelectType[Number(stepId)] === 'radio') {
                const [prodId] = Object.keys(products);
                selected.radio[Number(stepId)] = Number(prodId);
            } else if (stepSelectType[Number(stepId)] === 'checkbox') {
                const newMap = new Map();

                Object.keys(products).forEach((prodId) => {
                    newMap.set(Number(prodId), true);
                });

                selected.checkbox[Number(stepId)] = newMap;
            } else {
                const newMap = new Map();

                Object.keys(products).forEach((prodId) => {
                    newMap.set(Number(prodId), products[prodId].quantity);
                });

                selected.quantityPicker[Number(stepId)] = newMap;
            }
        });
    }

    return selected;
};

const computeSumSelectedOptions = (mapToSum: Map<number, number>, minChoice: number) => {
    let sumSelectedOptions = 0;
    mapToSum.forEach((v) => {
        sumSelectedOptions += v;
    });

    if (sumSelectedOptions >= minChoice) {
        return true;
    }

    return false;
};

export const computeStatus = (
    computedProductSteps: ComputedProductStep[],
    selectedOptions: SelectedOptions,
    errorOptions: MissingSteps | null,
    hasTriedAddingToCart = false,
): StepStatusType => {
    const status: StepStatusType = {};

    computedProductSteps.forEach(({ stepId, minChoice }) => {
        const hasMissingItems =
            errorOptions?.[String(stepId)] &&
            Object.values(errorOptions?.[String(stepId)]).some(
                (missingItemCount) => !!missingItemCount,
            );

        if (hasMissingItems) {
            status[stepId] = 'destructive';
            // radio
        } else if (selectedOptions.radio[stepId]) {
            status[stepId] = 'success';
            // checkbox
        } else if (
            selectedOptions.checkbox[stepId] &&
            selectedOptions.checkbox[stepId].size >= minChoice
        ) {
            status[stepId] = 'success';
            // quantityPicker
        } else if (
            selectedOptions.quantityPicker[stepId] &&
            computeSumSelectedOptions(selectedOptions.quantityPicker[stepId], minChoice)
        ) {
            status[stepId] = 'success';
        } else if (hasTriedAddingToCart) {
            status[stepId] = 'destructive';
        } else {
            status[stepId] = 'primary';
        }
    });

    return status;
};

export type SelectedSummary = Record<number, Record<number, SummarySelectedItem[]>>;

const computeSelectionSummaryItemValue = (
    menu: MenuState,
    products: SummaryProductWithUnitPrice[],
    currency: string | undefined,
): string[] => {
    return products.reduce<string[]>((result, product) => {
        const { productId, quantity, unitPrice } = product;

        let productName = menu?.products[productId].name;

        if (!productName || !currency) {
            return result;
        }

        if (quantity > 1) {
            productName = `${quantity}x ${productName}`;
        }

        if (unitPrice > 0) {
            productName = `${productName} (+${computePrice(i18n, unitPrice, currency)})`;
        }

        return [...result, productName];
    }, []);
};

const computeSelectionSummaryProduct = (
    menu: MenuState,
    compositeProduct: CompositeProduct,
    currency: string | undefined,
): SummarySelectedItem[] => {
    if (!compositeProduct.steps) {
        return [];
    }

    const selectedProducts: SummarySelectedItem[] = [];

    Object.entries(compositeProduct.steps).forEach(([nestedStepId, nestedProduct]) => {
        const stepFromMenu = menu?.steps[Number(nestedStepId)];

        const products = Object.entries(nestedProduct).map(([productId, { quantity }]) => ({
            productId: Number(productId),
            quantity,
        }));

        if (!stepFromMenu) {
            return;
        }

        const sortedProducts = sortProductsByExcessPrice(products, stepFromMenu);
        const sortedProductsWithUnitPrice = addUnitPriceToProducts(sortedProducts, stepFromMenu);

        selectedProducts.push({
            name: menu?.steps[Number(nestedStepId)].name || '',
            value: computeSelectionSummaryItemValue(
                menu,
                sortedProductsWithUnitPrice,
                currency,
            ).join(', '),
        });

        const nestedSteps = computeSelectionSummaryStep(menu, nestedProduct, currency);

        const nestedStepsFlatten = Object.values(nestedSteps).flatMap((nestedStep) => nestedStep);

        if (nestedStepsFlatten.length) {
            selectedProducts.push(...nestedStepsFlatten);
        }
    });

    return selectedProducts;
};

const computeSelectionSummaryStep = (
    menu: MenuState,
    compositeStep: CompositeStep,
    currency: string | undefined,
): Record<number, SummarySelectedItem[]> => {
    const selectedSteps: Record<number, SummarySelectedItem[]> = {};

    Object.entries(compositeStep).forEach(([productId, product]) => {
        const selectedProducts: SummarySelectedItem[] = computeSelectionSummaryProduct(
            menu,
            product,
            currency,
        );

        if (selectedProducts.length) {
            selectedSteps[Number(productId)] = selectedProducts;
        }
    });

    return selectedSteps;
};

export const computeSelectedSummaryItems = (
    menu: MenuState,
    currentProduct: CompositeProduct | null,
    currency: string | undefined,
): SelectedSummary => {
    if (!currentProduct || !currentProduct.steps) {
        return {};
    }

    const selectedSummaryItems: SelectedSummary = {};

    Object.entries(currentProduct.steps).forEach(([stepId, parentProduct]) => {
        if (parentProduct) {
            selectedSummaryItems[Number(stepId)] = computeSelectionSummaryStep(
                menu,
                parentProduct,
                currency,
            );
        }
    });

    return selectedSummaryItems;
};

type SummaryProduct = { productId: number; quantity: number };
type SummaryProductWithUnitPrice = { productId: number; quantity: number; unitPrice: number };

/**
 * Almost same as sortProductsByExcessPrice in API (cf. https://github.com/InnovOrder/monorepo/blob/1ec072ef69c4e4257dcfd76a0adff1f46ad4c31c/services/api/src/app/services/menu/cart/libs/build.ts#L23)
 */
export const sortProductsByExcessPrice = (
    products: SummaryProduct[],
    stepFromMenu: StepAttributes,
): SummaryProduct[] => {
    const { isWeightStrategyEnabled, stepProduct: stepProducts } = stepFromMenu;

    const getExcessPrice = (product: SummaryProduct): number => {
        const productFound = stepProducts.find(
            ({ productId }) => productId === product.productId,
        ) as HashMappedStepProduct;

        const excessPrice = productFound.step_product.exceedPrice - productFound.step_product.price;
        return isWeightStrategyEnabled
            ? excessPrice / productFound.step_product.weight
            : excessPrice;
    };

    // The products which are more expensive in excess compared to their normal price are put forward
    return products.sort(
        (productA, productB) => getExcessPrice(productB) - getExcessPrice(productA),
    );
};

/**
 * Almost same as addUnitPriceToProducts in API (cf. https://github.com/InnovOrder/monorepo/blob/1ec072ef69c4e4257dcfd76a0adff1f46ad4c31c/services/api/src/app/services/menu/cart/libs/build.ts#L47)
 */
export const addUnitPriceToProducts = (
    products: SummaryProduct[],
    stepFromMenu: StepAttributes,
): SummaryProductWithUnitPrice[] => {
    const { maxChoice, isWeightStrategyEnabled, stepProduct: stepProducts } = stepFromMenu;

    const { productsWithUnitPriceLists: _productsWithUnitPriceLists } = products.reduce(
        (
            {
                totalQuantity,
                productsWithUnitPriceLists,
            }: {
                totalQuantity: number;
                productsWithUnitPriceLists: SummaryProductWithUnitPrice[][];
            },
            product,
        ) => {
            const productFound = stepProducts.find(
                ({ productId }) => productId === product.productId,
            ) as HashMappedStepProduct;

            const { quantity } = product;
            const normalPriceInStep = productFound.step_product.price;
            const exceedPriceInStep = productFound.step_product.exceedPrice;
            const weightInStep = productFound.step_product.weight;
            const quantityToCount = isWeightStrategyEnabled ? weightInStep * quantity : quantity;
            const newTotalQuantity = totalQuantity + quantityToCount;

            if (totalQuantity >= maxChoice) {
                productsWithUnitPriceLists.push([{ ...product, unitPrice: exceedPriceInStep }]);
                return { totalQuantity: newTotalQuantity, productsWithUnitPriceLists };
            }

            if (newTotalQuantity <= maxChoice) {
                productsWithUnitPriceLists.push([{ ...product, unitPrice: normalPriceInStep }]);
                return { totalQuantity: newTotalQuantity, productsWithUnitPriceLists };
            }

            // totalQuantity < maxChoice < newTotalQuantity => the product must be split
            const quantityBeforeMax = maxChoice - totalQuantity;
            const normalQuantity = isWeightStrategyEnabled
                ? Math.floor(quantityBeforeMax / weightInStep)
                : quantityBeforeMax;
            const exceedQuantity = quantity - normalQuantity;

            productsWithUnitPriceLists.push([
                ...(normalQuantity
                    ? [{ ...product, unitPrice: normalPriceInStep, quantity: normalQuantity }]
                    : []),
                ...(exceedQuantity
                    ? [{ ...product, unitPrice: exceedPriceInStep, quantity: exceedQuantity }]
                    : []),
            ]);
            return { totalQuantity: newTotalQuantity, productsWithUnitPriceLists };
        },
        { totalQuantity: 0, productsWithUnitPriceLists: [] },
    );

    return _productsWithUnitPriceLists.flatMap((_product) => _product);
};

export const computeResetMissingSteps = (
    currentStepId: string,
    currentProduct: CompositeProduct,
    missingSteps: MissingSteps | null,
    currentProductId: string,
): MissingSteps => {
    if (!missingSteps?.[currentStepId]) {
        return missingSteps || {};
    }

    let newMissingSteps: MissingSteps = missingSteps ? { ...missingSteps } : {};

    const currentStep = currentProduct.steps?.[currentStepId] || {};

    Object.entries(currentStep).forEach(([productId, product]) => {
        if (currentProductId !== productId) {
            const productSteps = product?.steps || {};

            Object.keys(productSteps).forEach((nestedStepId) => {
                newMissingSteps = computeResetMissingSteps(
                    nestedStepId,
                    product,
                    missingSteps,
                    currentProductId,
                );
            });

            newMissingSteps = {
                ...newMissingSteps,
                [currentStepId]: {
                    ...newMissingSteps[currentStepId],
                    [productId]: 0,
                },
            };
        }
    });

    return newMissingSteps;
};

export const getNextStepFromPreviousStepId = (
    productSteps: ProductStepRelation[],
    status: StepStatusType,
    previousStepId: number | null,
) => {
    if (!previousStepId) {
        return [];
    }

    // get steps not already filled
    const unsuccessfulStepIds = Object.entries(status).reduce<number[]>(
        (result, [stepId, stepStatus]) => {
            if (stepStatus === 'success') {
                return result;
            }
            return [...result, Number(stepId)];
        },
        [previousStepId],
    );
    const productStepsForScroll = productSteps.filter(({ stepId }) =>
        unsuccessfulStepIds.includes(stepId),
    );

    // find previousStep position in those
    const previousStepIndex = productStepsForScroll.findIndex(
        ({ stepId }) => stepId === previousStepId,
    );

    // find the existing (or not) next step
    const nextStepId = productStepsForScroll?.[previousStepIndex + 1]?.stepId;
    const nextStep = document.querySelectorAll(`[data-step-id="${nextStepId}"]`);

    return nextStep;
};

export const computeFilteredProductSteps = (
    productSteps: ProductStepRelation[],
    currentPath: CurrentPath | null,
    menu: MenuState,
): ProductStepRelation[] => {
    // get existing products from the currentPath
    const parentProductsIds = (currentPath || []).filter((_, index) => {
        return index % 2 === 0 && index !== (currentPath || []).length - 1;
    });
    const parentProducts = parentProductsIds.map((productId) => menu?.products[+productId]);
    const existingCrossSellStepsIds = parentProducts.flatMap((product) =>
        product?.productStep
            .filter((step) => step.type === StepType.CROSS_SELLING)
            .flatMap((step) => step.stepId),
    );
    return productSteps.filter((step) => !existingCrossSellStepsIds.includes(step.stepId));
};

export const filterCrossSellSteps = (steps: ProductStepRelation[] | undefined) =>
    (steps || []).filter((step) => step.type !== StepType.CROSS_SELLING);
