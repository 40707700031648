import styled from '@emotion/styled';
import { SvyRadio } from '@innovorder/savory';
import { css } from '@emotion/react';
import { RadioProps } from './Radio';

export const StyledRadio = styled(SvyRadio)<RadioProps>`
    --svy-radio-size: 28px !important;

    ${({ theme, color, checked, disabled }) => {
        if (color || disabled) {
            return css``;
        }

        if (checked) {
            return css`
                --svy-radio-color: ${theme.feature.ctaColor} !important;
                --svy-radio-hover-color: ${theme.feature.ctaColor} !important;
            `;
        }

        return css`
            --svy-radio-hover-color: ${theme.feature.ctaColor} !important;
        `;
    }};
`;
