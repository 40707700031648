import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useGetConsumptionModes } from 'hooks/useGetConsumptionModes';
import { parseRestaurantId } from 'pages/Home/Home.utils';
import { useMemo } from 'react';
import { getOrders } from 'redux/orders';
import { useAppSelector } from 'redux/store';
import { isNumber } from 'utils/typescript';

export const useBaseConfirmPaymentVM = () => {
    const { navigateBackToChoosePayment } = useCustomNavigate();

    const orders = useAppSelector(getOrders);
    const restaurantIds = useMemo(
        () =>
            orders
                ? Object.keys(orders)
                      .map((id) => parseRestaurantId(id))
                      .filter(isNumber)
                : [],
        [orders],
    );
    const { isFetching } = useGetConsumptionModes(restaurantIds);

    return { isFetching, navigateBackToChoosePayment };
};
