const unsecuredCopyToClipboard = (callback: () => void) => {
    const textArea = document.createElement('textarea');
    textArea.value = window.location.href;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        callback();
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Unable to copy to clipboard in unsecuredCopyToClipboard', err);
    }
    document.body.removeChild(textArea);
};

const securedCopyToClipboard = (callback: () => void) => {
    navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
            callback();
        })
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Unable to copy to clipboard in securedCopyToClipboard', err);
        });
};

export const handleCopyUrlToClipboard = (callback: () => void) => {
    if (navigator.clipboard) {
        // If normal copy method available, use it
        securedCopyToClipboard(callback);
    } else {
        // Otherwise fallback to the above function
        unsecuredCopyToClipboard(callback);
    }
};
