import { ChannelId } from 'services/order/order.type';
import { ConsumptionModeType } from '@innovorder/order_detail';

export interface ConsumptionMode {
    consumptionModeId: number;
    restaurantId: number;
    brandId: number;
    channelId: ChannelId;
    delay: number;
    paymentMethods: PaymentMethod[];
    type: ConsumptionModeType;
}

export interface PaymentMethod {
    paymentMethodId: number;
    brandId: number;
    channelId: ChannelId;
    restaurantId: number;
    posDeviceId: number | null;
    code: PaymentMethodCode;
    active: boolean;
}

export enum PaymentMethodCode {
    EWallet = 'EWallet',
    Stripe = 'Stripe',
    Paygreen = 'Paygreen',
    Cash = 'Cash',
    CreditCard = 'CreditCard',
    Edenred = 'Edenred',
}
