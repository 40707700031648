import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { OrderRecap, PriceBreakdown } from 'services/order/order.type';
import { EnrichedProduct, VerifiedOrder } from '@innovorder/order_detail';
import { RootState } from '../store';

export const ordersSliceName = 'orders';

export type OrdersState = Record<number, VerifiedOrder> | null;

const initialState: OrdersState = null;

export const ordersSlice = createSlice<OrdersState, SliceCaseReducers<OrdersState>, string>({
    name: ordersSliceName,
    initialState,
    reducers: {
        updateOrders: (_state, action: PayloadAction<OrdersState>) => ({
            ...action.payload,
        }),
        resetOrders: (_state, _action: PayloadAction) => {
            return null;
        },
    },
});

export const getOrders = (state: RootState): OrdersState => state.orders;
export const getOrdersTotalQuantity = (state: RootState): number => {
    const orders = getOrders(state);

    if (!orders) {
        return 0;
    }

    return Object.values(orders).reduce((totalQuantity, order) => {
        return (
            totalQuantity +
            (order?.cart?.reduce((quantity, product) => {
                return quantity + product.quantity;
            }, 0) || 0)
        );
    }, 0);
};
export const getOrdersTotalInclTaxWithDiscount = (state: RootState): number => {
    const orders = getOrders(state);

    if (!orders) {
        return 0;
    }

    return Object.values(orders).reduce((totalInclTaxWithDiscount, order) => {
        return totalInclTaxWithDiscount + (order?.recap?.totalPrice?.totalInclTaxWithDiscount || 0);
    }, 0);
};
export const getOrdersTotalPriceBreakdown = (state: RootState): PriceBreakdown => {
    const orders = getOrders(state);

    if (!orders) {
        return {
            totalInclTax: 0,
            totalExclTax: 0,
            totalTax: 0,
            totalInclTaxWithDiscount: 0,
            totalExclTaxWithDiscount: 0,
            totalTaxWithDiscount: 0,
            totalInclTaxWithRemise: 0,
            totalExclTaxWithRemise: 0,
            totalTaxWithRemise: 0,
        };
    }

    return Object.values(orders).reduce<PriceBreakdown>(
        (totalPrice, order) => {
            return {
                totalInclTax:
                    totalPrice.totalInclTax + (order?.recap?.totalPrice?.totalInclTax || 0),
                totalExclTax:
                    totalPrice.totalExclTax + (order?.recap?.totalPrice?.totalExclTax || 0),
                totalTax: totalPrice.totalTax + (order?.recap?.totalPrice?.totalTax || 0),
                totalInclTaxWithDiscount:
                    totalPrice.totalInclTaxWithDiscount +
                    (order?.recap?.totalPrice?.totalInclTaxWithDiscount || 0),
                totalExclTaxWithDiscount:
                    totalPrice.totalExclTaxWithDiscount +
                    (order?.recap?.totalPrice?.totalExclTaxWithDiscount || 0),
                totalTaxWithDiscount:
                    totalPrice.totalTaxWithDiscount +
                    (order?.recap?.totalPrice?.totalTaxWithDiscount || 0),
                totalInclTaxWithRemise:
                    totalPrice.totalInclTaxWithRemise +
                    (order?.recap?.totalPrice?.totalInclTaxWithRemise || 0),
                totalExclTaxWithRemise:
                    totalPrice.totalExclTaxWithRemise +
                    (order?.recap?.totalPrice?.totalExclTaxWithRemise || 0),
                totalTaxWithRemise:
                    totalPrice.totalTaxWithRemise +
                    (order?.recap?.totalPrice?.totalTaxWithRemise || 0),
            };
        },
        {
            totalInclTax: 0,
            totalExclTax: 0,
            totalTax: 0,
            totalInclTaxWithDiscount: 0,
            totalExclTaxWithDiscount: 0,
            totalTaxWithDiscount: 0,
            totalInclTaxWithRemise: 0,
            totalExclTaxWithRemise: 0,
            totalTaxWithRemise: 0,
        },
    );
};
export const getOrdersCarts = (state: RootState): { [key: number]: EnrichedProduct[] } => {
    const orders = getOrders(state);

    if (!orders) {
        return {};
    }

    return Object.values(orders).reduce((formatedOrders, order) => {
        return { ...formatedOrders, [order.restaurantId]: order.cart };
    }, {} as { [key: number]: EnrichedProduct[] });
};

export const getOrder =
    (restaurantId: number | null) =>
    (state: RootState): VerifiedOrder | undefined =>
        restaurantId ? state.orders?.[restaurantId] : undefined;
export const getOrderCart =
    (restaurantId: number | null) =>
    (state: RootState): EnrichedProduct[] =>
        getOrder(restaurantId)(state)?.cart || [];
export const getOrderRecap =
    (restaurantId: number | null) =>
    (state: RootState): OrderRecap | undefined =>
        getOrder(restaurantId)(state)?.recap;
export const getOrderComment =
    (restaurantId: number | null) =>
    (state: RootState): string | undefined =>
        getOrder(restaurantId)(state)?.comment;
export const getOrderPromocode =
    (restaurantId: number | null) =>
    (state: RootState): string | undefined =>
        getOrder(restaurantId)(state)?.promocode;
export const getOrderTotalPriceBreakdown =
    (restaurantId: number | null) =>
    (state: RootState): PriceBreakdown | undefined =>
        getOrder(restaurantId)(state)?.recap.totalPrice;

export const { updateOrders, resetOrders } = ordersSlice.actions;
