import styled from '@emotion/styled';

export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: ${({ theme }) => theme.base.spacing[5]};
    margin: ${({ theme }) => theme.base.spacing[2]};
    text-align: center;
`;
