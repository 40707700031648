import { Caption } from 'components';
import { SmallerCaption, StyledFrenchRepublicLogo, TopCallout } from './AlcoholBanner.style';

const AlcoholBanner = () => {
    return (
        <TopCallout>
            <StyledFrenchRepublicLogo />

            <div>
                <Caption text={'home_page.alcohol_banner.title'} weight="Bold" />
                <SmallerCaption text={'home_page.alcohol_banner.subtitle'} />
                <SmallerCaption text={'home_page.alcohol_banner.code'} />
            </div>
        </TopCallout>
    );
};

export default AlcoholBanner;
