"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.max = exports.big10000 = exports.big100 = exports.big1 = exports.big0 = void 0;
var bignumber_js_1 = require("bignumber.js");
exports.big0 = new bignumber_js_1.BigNumber(0);
exports.big1 = new bignumber_js_1.BigNumber(1);
exports.big100 = new bignumber_js_1.BigNumber(100);
exports.big10000 = new bignumber_js_1.BigNumber(10000);
function max(firstNumber, secondNumber) {
    if (firstNumber.isGreaterThanOrEqualTo(secondNumber)) {
        return firstNumber;
    }
    return secondNumber;
}
exports.max = max;
