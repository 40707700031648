import styled from '@emotion/styled';
import { Title } from 'components/Title';

export const PriceBreakDownSummaryContainer = styled.div``;

const row = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BreakDownRow = styled(row)`
    padding-bottom: ${({ theme }) => theme.base.spacing[1]};
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;

export const TotalRow = styled(row)``;

export const PriceBreakDownSummaryTitle = styled(Title)`
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;
