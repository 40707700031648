import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledSvyStepperWrapper = styled.div`
    & > ol {
        ${({ theme }) => css`
            --svy-stepper-active-background-color: ${theme.feature.ctaColor} !important;
            --svy-stepper-active-text-color: ${theme.feature.primaryTextColor} !important;
        `}
    }
`;
