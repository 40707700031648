import styled from '@emotion/styled';
import { Caption } from 'components';

export const MargedForm = styled.form`
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
    width: 100%;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const SuccessCaption = styled(Caption)`
    color: ${({ theme }) => theme.base.colors.success[500]};
`;
