import { Text } from 'components';
import React, { useCallback } from 'react';
import { CarouselItem, ImageContainer, ScrollContainer } from './Carousel.style';

export type Item = {
    id: number;
    name: string;
    pictureUrl: string;
};

export type CarouselProps = {
    items: Item[];
    selectedItemId?: number | null;
    onChange?: (id: number) => void;
};

export const Carousel: React.FunctionComponent<CarouselProps> = ({
    items,
    selectedItemId,
    onChange,
}) => {
    const handleOnClick = useCallback(
        (id: number) => () => {
            onChange && onChange(id);
        },
        [onChange],
    );

    return (
        <ScrollContainer data-testid="carousel">
            {items.map(({ id, name, pictureUrl }) => (
                <CarouselItem id={`carousel-item-${id}`} key={id} onClick={handleOnClick(id)}>
                    <ImageContainer selected={selectedItemId === id}>
                        <img src={pictureUrl} alt="" />
                    </ImageContainer>
                    <Text text={name} noTranslation type={3} weight="Bold" />
                </CarouselItem>
            ))}
        </ScrollContainer>
    );
};
