import { ConsumptionModeType } from '@innovorder/order_detail';
import { ProductAttributesFromCategories } from 'services/menu/menu.type';
import { isConsumptionFlagActivated } from 'utils/consumptionFlags';

export const computeProductList = (
    productList: ProductAttributesFromCategories[],
    currentConsumptionModeType: ConsumptionModeType | null,
): ProductAttributesFromCategories[] =>
    productList
        .filter(({ webAvailability }) => !!webAvailability)
        .filter(({ currentQuantity }) => currentQuantity !== 0)
        .filter(({ consumptionFlags }) =>
            isConsumptionFlagActivated(consumptionFlags, currentConsumptionModeType),
        )
        .sort(
            (
                { category_product: { position: positionA } },
                { category_product: { position: positionB } },
            ) => positionA - positionB,
        );
