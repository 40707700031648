import React, { useMemo, useState } from 'react';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import {
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElement,
    StripeCardNumberElementChangeEvent,
    StripeCardNumberElementOptions,
} from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

type CardStatus = {
    error: string | null;
    complete: boolean;
};

export const useStripeFormVM = (isLoading: boolean | undefined) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const stripe = useStripe();
    const elements = useElements();
    const options: StripeCardNumberElementOptions = useMemo(
        () => ({
            style: {
                base: {
                    /**
                    To avoid iOS auto zooming in Input
                    https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming#bootstrap-default-input-font-sizing-1rem
                    */
                    fontSize: '16px',
                },
                invalid: {
                    color: theme.feature.errorColor,
                },
            },
        }),
        [theme],
    );

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);

    const [numberStatus, setNumberStatus] = useState<CardStatus>({
        error: null,
        complete: false,
    });
    const [expiryStatus, setExpiryStatus] = useState<CardStatus>({
        error: null,
        complete: false,
    });
    const [cvcStatus, setCvcStatus] = useState<CardStatus>({
        error: null,
        complete: false,
    });
    const [focus, setFocus] = useState({
        number: false,
        expiry: false,
        cvc: false,
    });

    const setStatusMap = {
        number: (status: CardStatus) => {
            if (status.complete) {
                elements?.getElement('cardExpiry')?.focus();
            }
            setNumberStatus(status);
        },
        expiry: (status: CardStatus) => {
            if (status.complete) {
                elements?.getElement('cardCvc')?.focus();
            }
            setExpiryStatus(status);
        },
        cvc: (status: CardStatus) => setCvcStatus(status),
    };

    const handleTermsAndConditionsChange = () => {
        !isLoading && setTermsAndConditions(!termsAndConditions);
    };

    const handleChange =
        (field: keyof typeof setStatusMap) =>
        (
            event:
                | StripeCardNumberElementChangeEvent
                | StripeCardExpiryElementChangeEvent
                | StripeCardCvcElementChangeEvent,
        ): void => {
            setStatusMap[field]({ error: event.error?.message || null, complete: event.complete });
        };

    const handleFocusChange = (field: keyof typeof setStatusMap) => (value: boolean) => () => {
        setFocus({
            number: false,
            expiry: false,
            cvc: false,
            [field]: value,
        });
    };

    const handleSubmit =
        (handleStripePaymentSubmit: (stripePaymentMethodId: string | undefined) => void) =>
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setIsSubmitting(true);

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
            });

            setIsSubmitting(false);
            handleStripePaymentSubmit(payload.paymentMethod?.id);
        };

    return {
        buttonText: t('confirm_and_pay'),
        termsAndConditions,
        canSubmit:
            stripe &&
            numberStatus.complete &&
            expiryStatus.complete &&
            cvcStatus.complete &&
            !isLoading &&
            !isSubmitting &&
            termsAndConditions,
        options,
        numberStatus,
        expiryStatus,
        cvcStatus,
        handleChange,
        handleSubmit,
        handleTermsAndConditionsChange,
        focus,
        handleFocusChange,
    };
};
