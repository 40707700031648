"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initPricesNotRounded = exports.initPricesRounded = void 0;
var big_numbers_1 = require("../../utils/big-numbers");
var initPricesRounded = function () { return ({
    totalInclTax: 0,
    totalExclTax: 0,
    totalTax: 0,
    totalInclTaxWithDiscount: 0,
    totalExclTaxWithDiscount: 0,
    totalTaxWithDiscount: 0,
    totalInclTaxWithRemise: 0,
    totalExclTaxWithRemise: 0,
    totalTaxWithRemise: 0,
}); };
exports.initPricesRounded = initPricesRounded;
var initPricesNotRounded = function (inclTax, exclTax) {
    if (inclTax === void 0) { inclTax = big_numbers_1.big0; }
    if (exclTax === void 0) { exclTax = big_numbers_1.big0; }
    return ({
        totalInclTax: inclTax,
        totalExclTax: exclTax,
        totalInclTaxWithDiscount: inclTax,
        totalExclTaxWithDiscount: exclTax,
        totalInclTaxWithRemise: inclTax,
        totalExclTaxWithRemise: exclTax,
    });
};
exports.initPricesNotRounded = initPricesNotRounded;
