"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.posOrderDiscountsAdapter = void 0;
var types_1 = require("../types");
var pos_discounts_adapters_1 = require("./pos-discounts.adapters");
var total_amount_force_discount_adapter_1 = require("./total-amount-force-discount.adapter");
var utils_1 = require("./utils");
function posOrderDiscountsAdapter(_a, digitsRange, posApiVersion) {
    var _b = _a.orderItems, orderItems = _b === void 0 ? [] : _b, discount = _a.discount, grants = _a.grants, pricingRule = _a.pricingRule, loyalty = _a.loyalty, _c = _a.orderFlags, orderFlags = _c === void 0 ? [] : _c, totalPriceDiscountedWithTaxIncluded = _a.totalPriceDiscountedWithTaxIncluded, totalPriceWithTaxIncluded = _a.totalPriceWithTaxIncluded;
    var totalAmountForceDiscounts = (0, total_amount_force_discount_adapter_1.totalAmountForceDiscountAdapter)({
        totalPriceDiscountedWithTaxIncluded: totalPriceDiscountedWithTaxIncluded,
        orderItems: orderItems,
    });
    orderItems = orderItems.map(utils_1.generateApplyScopeId);
    switch (posApiVersion) {
        case types_1.PosOrderApiVersion.V1: {
            return __spreadArray(__spreadArray([], totalAmountForceDiscounts, true), (0, pos_discounts_adapters_1.posOrderDiscountsAdapterV1)({ orderItems: orderItems, discount: discount, orderFlags: orderFlags }, digitsRange), true);
        }
        case types_1.PosOrderApiVersion.V3:
        case types_1.PosOrderApiVersion.V2: {
            return __spreadArray(__spreadArray([], totalAmountForceDiscounts, true), (0, pos_discounts_adapters_1.posOrderDiscountsAdapterV2)({ orderItems: orderItems, discount: discount, grants: grants, pricingRule: pricingRule, loyalty: loyalty, orderFlags: orderFlags }, digitsRange), true);
        }
        case types_1.PosOrderApiVersion.V5:
        case types_1.PosOrderApiVersion.V4: {
            return __spreadArray(__spreadArray([], totalAmountForceDiscounts, true), (0, pos_discounts_adapters_1.posOrderDiscountsAdapterV4)({
                orderItems: orderItems,
                discount: discount,
                grants: grants,
                pricingRule: pricingRule,
                loyalty: loyalty,
                orderFlags: orderFlags,
                totalPriceDiscountedWithTaxIncluded: totalPriceDiscountedWithTaxIncluded,
                totalPriceWithTaxIncluded: totalPriceWithTaxIncluded,
            }, digitsRange), true);
        }
        default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error("Undefined PosOrderApiVersion ".concat(posApiVersion));
    }
}
exports.posOrderDiscountsAdapter = posOrderDiscountsAdapter;
