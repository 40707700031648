import { useMemo } from 'react';
import { getRestaurantId } from 'redux/app';
import { getOrderCart } from 'redux/orders';
import { useAppSelector } from 'redux/store';

export const useLuncheonVoucherEligibilityRules = () => {
    const restaurantId = useAppSelector(getRestaurantId);
    const products = useAppSelector(getOrderCart(restaurantId));

    const isEveryProductEligible = useMemo(() => {
        return products.every((product) => product.isEligibleLuncheon);
    }, [products]);

    const cartContainsOnlyEligibleProducts = isEveryProductEligible;

    return {
        cartContainsOnlyEligibleProducts,
    };
};
