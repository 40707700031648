import React from 'react';
import { StyledStepperProps, SvyStepper } from '@innovorder/savory';
import { useCartStepper } from './useCartStepper';
import { StyledSvyStepperWrapper } from './CartStepper.style';

export type CartStepperProps = StyledStepperProps;

export const CartStepper: React.FunctionComponent = () => {
    const { steps } = useCartStepper();
    if (!steps) return null;
    return (
        <StyledSvyStepperWrapper>
            <SvyStepper steps={steps} stepType="vertical" />
        </StyledSvyStepperWrapper>
    );
};
