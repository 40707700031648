import styled from '@emotion/styled';
import { SvyIcon } from '@innovorder/savory';
import { Button, Caption, Title } from 'components';

export const MargedTitle = styled(Title)`
    text-align: center;
    margin-left: ${({ theme }) => theme.base.spacing[2]};
`;
export const MargedCaption = styled(Caption)`
    margin: 0 ${({ theme }) => theme.base.spacing[3]};
    text-align: center;
`;

export const MargedButton = styled(Button)`
    margin-top: ${({ theme }) => theme.base.spacing[4]};
`;

export const StyledIcon = styled(SvyIcon)`
    color: ${({ theme }) => theme.base.colors.destructive[500]};
    font-size: 40px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;
