import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const Drawer = styled(motion.div)`
    padding: ${({ theme }) => theme.base.spacing[4]} ${({ theme }) => theme.base.spacing[2]};
    padding-bottom: ${({ theme }) => theme.base.spacing[4]};
    background-color: ${({ theme }) => theme.feature.backgroundColor};
    border-radius: ${({ theme }) => theme.base.radius.xl} ${({ theme }) => theme.base.radius.xl} 0 0;
    min-height: 10px;
    width: 100%;
    bottom: 0px;
    position: absolute;
`;
