import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { FollowUpStatus } from 'services/order/order.type';
import { RootState } from '../store';

export const ordersStatusSliceName = 'ordersStatus';

type OrderStatusInformation = {
    status: FollowUpStatus;
    restaurantId: string;
    orderId: string;
};
export type OrdersStatusState = Record<string, OrderStatusInformation> | null;

const initialState: OrdersStatusState = null;

export const ordersStatusSlice = createSlice<
    OrdersStatusState,
    SliceCaseReducers<OrdersStatusState>,
    string
>({
    name: ordersStatusSliceName,
    initialState,
    reducers: {
        updateOrdersStatus: (state, action: PayloadAction<OrdersStatusState>) => ({
            ...state,
            ...action.payload,
        }),
        resetStatuses: (_state, _action: PayloadAction) => {
            return null;
        },
    },
});

export const getStatuses = (state: RootState): OrdersStatusState => state.ordersStatus;
export const getFollowUpOrdersId = (state: RootState): string[] | null => {
    const stateCopy = getStatuses(state);
    if (!stateCopy) return null;
    return Object.keys(stateCopy);
};

export const { updateOrdersStatus, resetStatuses } = ordersStatusSlice.actions;
