import styled from '@emotion/styled';
import { Text } from 'components';

export const EmptyRestaurantContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-bottom: 90px;
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const CartRestaurantSubtitle = styled(Text)`
    text-align: center;
    margin-top: ${({ theme }) => theme.base.spacing[1]};
`;
