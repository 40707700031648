const QUARTER_HOUR_IN_SECONDS = 15 * 60;

export const executeNowAndEveryQuarterHour = (functionToExecute: () => void) => {
    functionToExecute();

    const now = new Date();
    const secondsBeforeQuarterHour =
        QUARTER_HOUR_IN_SECONDS -
        ((now.getSeconds() + now.getMinutes() * 60) % QUARTER_HOUR_IN_SECONDS);

    let intervalId: NodeJS.Timer;
    const timerId = setTimeout(() => {
        functionToExecute();

        intervalId = setInterval(() => functionToExecute(), QUARTER_HOUR_IN_SECONDS * 1000);
    }, secondsBeforeQuarterHour * 1000);

    return {
        clearTimers: () => {
            clearTimeout(timerId);
            clearInterval(intervalId);
        },
    };
};
