import React from 'react';
import { BottomDrawer } from 'components';
import { Outlet } from 'react-router-dom';
import { Container } from './DrawerLayout.style';

const DrawerLayout: React.FunctionComponent = () => {
    return (
        <Container data-testid="drawer-layout">
            <BottomDrawer>
                <Outlet />
            </BottomDrawer>
        </Container>
    );
};

export default DrawerLayout;
