export enum ErrorEnum {
    CART_ERROR,
    ORDER_ERROR,
    NOT_FOUND,
    NO_RESTAURANT,
    NO_CONSUMPTION_MODE,
    QR_CODE_ERROR,
}

export type ErrorProps = {
    error: ErrorEnum;
};
