import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};

    textarea {
        resize: none;
        padding: ${({ theme }): string => theme.base.spacing[1]};
        ${({ theme }) => theme.base.typography['Body/Regular/Text3']}
        border: ${({ theme }) => `1px solid ${theme.feature.thirdaryTextColor}`};
        border-radius: ${({ theme }) => theme.base.radius.s};

        &:focus {
            -moz-appearance: none;
            outline: 0px none transparent;
        }
    }

    p {
        position: absolute;
        bottom: 5px;
        right: 7px;
        ${({ theme }) => theme.base.typography['Caption/Regular/Caption1']}
    }
`;
