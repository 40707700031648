import { useRedirectToHome } from 'hooks/useRedirectHome/useRedirectHome';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { getIsFoodCourt, getRestaurantId } from 'redux/app';
import { getBrand, getRestaurant } from 'redux/brand';
import { useAppSelector } from 'redux/store';
import { getAccessToken } from 'redux/user/user.slice';

export const useWithHeaderVM = () => {
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const brand = useAppSelector(getBrand);
    const currentRestaurantId = useAppSelector(getRestaurantId);
    const currentRestaurant = useAppSelector(getRestaurant(currentRestaurantId));
    const isAuthenticated = useAppSelector(getAccessToken);

    const { redirectToHome } = useRedirectToHome();
    const { goPrevious } = useWorkflowStateMachine();

    return {
        isFoodCourt,
        redirectToHome,
        goPrevious,
        restaurantLogo: currentRestaurant?.imageUrl,
        logoAriaLabel: isFoodCourt ? brand?.name : currentRestaurant?.name,
        isAuthenticated: !!isAuthenticated,
    };
};
