import React from 'react';
import { BottomBar, Button, Loader, Text } from 'components';
import { LoaderContainer } from './StripeConfirmPayment.style';
import { useStripeConfirmPaymentVM } from './useStripeConfirmPaymentVM';

export const StripeConfirmPayment: React.FunctionComponent = () => {
    const { done, displayThreeDSErrorMessage, buttonText, handleThreeDSError } =
        useStripeConfirmPaymentVM();

    if (done) {
        return null;
    }

    return (
        <>
            <LoaderContainer>
                <Loader size={50} />
                {displayThreeDSErrorMessage ? (
                    <Text text={'order_error_3ds.title'} type={2} />
                ) : (
                    <Text text={'stripe_confirm'} type={2} />
                )}
            </LoaderContainer>
            {displayThreeDSErrorMessage && (
                <BottomBar withPadding>
                    <Button onClick={handleThreeDSError} fullWidth size="s" color="destructive">
                        {buttonText}
                    </Button>
                </BottomBar>
            )}
        </>
    );
};

export default StripeConfirmPayment;
