import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Callout, Loader, QuantityPicker, Text, BottomBar } from 'components';
import ProductErrorModal from 'components/ProductErrorModal';
import SimpleProduct from './SimpleProduct/SimpleProduct';
import {
    ProductContainer,
    ProductQuantityPickerContainer,
    LoaderContainer,
    StyledIcon,
} from './Product.style';

import { useProductVM } from './useProductVM';
import ProductSteps from './ProductSteps';

const Product: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        isError,
        productId,
        product,
        isComposite,
        currency,
        isLoading,
        isNested,
        quantity,
        handleConfirmAddProduct,
        handleChangeProductQuantity,
        navigateBackToMenuHome,
        saveAndReturn,
        buttonText,
        productSteps,
        ordersErrors,
        isButtonDisabled,
        shouldDisplayNextOpening,
        nextOpeningI18n,
        handleDisabledAddProduct,
        hasTriedAddingToCart,
    } = useProductVM();

    if (isLoading && !ordersErrors) {
        return (
            <LoaderContainer>
                <Loader size={50} />
            </LoaderContainer>
        );
    }

    if (isError || !product || !currency) {
        return <h1>{t('product_doesnt_exist')}...</h1>;
    }

    return (
        <ProductContainer>
            <SimpleProduct
                product={product}
                isComposite={isComposite}
                isNested={isNested}
                currency={currency}
                onGoBack={isNested ? saveAndReturn : navigateBackToMenuHome}
            />

            {!!productId && isComposite && (
                <ProductSteps
                    hasTriedAddingToCart={hasTriedAddingToCart}
                    productSteps={productSteps}
                />
            )}

            <ProductErrorModal productId={productId} displayStepErrorName={true} />

            {!isNested && !shouldDisplayNextOpening && (
                <ProductQuantityPickerContainer data-testid="quantity-picker">
                    <QuantityPicker
                        value={quantity}
                        onValueChange={handleChangeProductQuantity}
                        hideTrash={true}
                        variant="primary"
                    />
                </ProductQuantityPickerContainer>
            )}

            <BottomBar withPadding={!shouldDisplayNextOpening}>
                {shouldDisplayNextOpening && (
                    <Callout>
                        <StyledIcon icon="time-line" data-testid="time-line" />
                        <Text type={3} weight="Bold" noTranslation text={nextOpeningI18n} />
                    </Callout>
                )}
                {!shouldDisplayNextOpening && (
                    <Button
                        fullWidth
                        disabled={isLoading || isButtonDisabled}
                        isLoading={isLoading}
                        onClick={isNested ? saveAndReturn : handleConfirmAddProduct}
                        onDisabledClick={handleDisabledAddProduct}
                        data-testid="add-button"
                        size="l"
                    >
                        {buttonText}
                    </Button>
                )}
            </BottomBar>
        </ProductContainer>
    );
};

export default Product;
