import i18n from 'i18next';
import translationEn from 'locales/en';
import translationFr from 'locales/fr';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: translationEn,
    },
    fr: {
        translation: translationFr,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(detector)
    .init({
        resources,
        fallbackLng: 'fr', // use fr if detected lng is not available
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        compatibilityJSON: 'v3',

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
