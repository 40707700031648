"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapFeeToFeeRecap = void 0;
var mapFeeToFeeRecap = function (fee) { return ({
    label: fee.label,
    id: fee.id.toString(),
    value: fee.totalPriceRounded.totalInclTax,
    quantity: fee.quantity,
    taxRate: fee.taxRate,
    type: fee.type,
    totalPrice: fee.totalPriceRounded,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    additionalData: fee.additionalData,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    entranceFeeRawData: fee.entranceFeeRawData,
}); };
exports.mapFeeToFeeRecap = mapFeeToFeeRecap;
