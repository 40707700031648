import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import StripeConfirmPayment from '../StripeConfirmPayment';
import { useStripeRequireActionVM } from './useStripeRequireActionVM';

export const StripeRequireAction: React.FunctionComponent = () => {
    const { stripePromise } = useStripeRequireActionVM();

    return (
        <Elements stripe={stripePromise}>
            <StripeConfirmPayment />
        </Elements>
    );
};

export default StripeRequireAction;
