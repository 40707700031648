import React from 'react';
import { SvyBadge } from '@innovorder/savory';
import { Loader } from 'components/Loader';
import { Container, ImgContainer, LoaderShadow, NameContainer } from './CrossSellingProduct.style';

export type CrossSellingProductProps = {
    image: string;
    name: string;
    price: string;
    isSelected: boolean;
    onClick: () => void;
};

export const CrossSellingProduct: React.FunctionComponent<CrossSellingProductProps> = ({
    image,
    name,
    price,
    isSelected,
    onClick,
}) => {
    return (
        <Container onClick={onClick}>
            <ImgContainer
                data-testid="cross-selling-product-img"
                imgUrl={image}
                isSelected={isSelected}
            >
                {isSelected && (
                    <LoaderShadow>
                        <Loader />
                    </LoaderShadow>
                )}
            </ImgContainer>

            <NameContainer
                data-testid="cross-selling-product-name"
                isSelected={isSelected}
                text={name}
                noTranslation
                type={2}
                weight="Bold"
            />

            <SvyBadge color="primary" label={price} />
        </Container>
    );
};
