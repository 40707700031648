import { ConsumptionModeType } from '@innovorder/order_detail';

export const convertModeToUrl = (mode: ConsumptionModeType | null) => {
    if (!mode) return null;

    return mode.replace('MODE_', '').replace('_', '-').toLowerCase();
};

export const convertModeFromUrl = (urlMode: string | undefined) => {
    if (!urlMode) return null;

    const mode = `MODE_${urlMode.replace('-', '_').toUpperCase()}`;
    if (!(mode in ConsumptionModeType)) return null;

    return mode as ConsumptionModeType;
};
