import { useAppSelector } from 'redux/store';
import { getCurrentConsumptionModeType } from 'redux/app';
import { ProductAttributesFromCategories } from 'services/menu/menu.type';
import { computeProductList } from './ProductList.utils';

export const useProductListVM = (productList: ProductAttributesFromCategories[]) => {
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const computedProductList = computeProductList(productList, currentConsumptionModeType);

    return {
        computedProductList,
    };
};
