import { Button, Modal, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { ModalContainer, ModalContent } from './LoggedOutModal.style';

export type LoggedOutModalProps = {
    isOpen: boolean;
    onHide: () => void;
};

const LoggedOutModal: React.FunctionComponent<LoggedOutModalProps> = ({ isOpen, onHide }) => {
    const { t } = useTranslation();

    return (
        <Modal headerTitle={'modal_logged_out.title'} isOpen={isOpen} onHide={onHide}>
            <ModalContainer>
                <ModalContent>
                    <Text text={'modal_logged_out.body'} type={3} />
                </ModalContent>
                <Button onClick={onHide} variant="text" color="primary">
                    {t('modal_logged_out.submit_button')}
                </Button>
            </ModalContainer>
        </Modal>
    );
};

export default LoggedOutModal;
