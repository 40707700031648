import { RestaurantCartToVerify, EnrichedProduct, EnrichedStep } from '@innovorder/order_detail';
import { v4 as uuidv4 } from 'uuid';
import { OrdersState } from 'redux/orders';
import { ProductError } from 'redux/ordersErrors/ordersErrors.slice';
import { Cart, OrderVerified, ProductDTO, StepDTO } from 'services/order/order.type';
import { StepType } from 'services/menu/menu.type';

export const rectifyCart = (cart: EnrichedProduct[], productErrors: ProductError[]) => {
    return productErrors.reduce((rectifiedCart, productError) => {
        if (productError.productCartId) {
            return rectifiedCart.filter(
                (cartItem) => cartItem.productCartId !== productError.productCartId,
            );
        }
        return rectifiedCart.filter((cartItem) => cartItem.productId !== productError.productId);
    }, cart);
};

export const addProductInCart = (
    cart: Cart,
    productId: number,
    productCartId: string,
    quantity: number,
): Cart => {
    if (cart.find((p) => p.productCartId === productCartId)) {
        return cart;
    }

    return [...cart, { productId, productCartId, quantity, steps: [] }];
};

export const updateProductQuantityInCart = (
    cart: Cart,
    productCartId: string,
    quantity: number,
    productId?: number,
    steps?: StepDTO[],
): Cart => {
    // if quantity is 0, we remove the product from the cart
    if (!quantity) {
        return cart.filter((p) => p.productCartId !== productCartId);
    }

    if (productId && !cart.find((p) => p.productCartId === productCartId)) {
        const newProduct = { productId, productCartId, quantity, steps: steps || [] };
        const crossSellProducts = extractCrossSellProducts(newProduct);
        cart = [...cart, newProduct, ...crossSellProducts];

        return removeCrossSellSteps(cart);
    }

    // if the product is in the cart and newQuantity > 0, we update the quantity
    return cart.map((p) => {
        if (p.productCartId === productCartId) {
            return { ...p, quantity };
        }
        return p;
    });
};

const extractCrossSellProducts = (product: ProductDTO): ProductDTO[] => {
    const extractCrossSellFromSteps = (steps: StepDTO[], productId: number): ProductDTO[] => {
        return steps.flatMap((step) => {
            const crossSellProducts: ProductDTO[] = [];

            if (step.type === StepType.CROSS_SELLING) {
                crossSellProducts.push(
                    ...step.products.map((p) => ({
                        productId: p.productId,
                        steps: p.steps,
                        quantity: p.quantity,
                        productCartId: p.productCartId || uuidv4(),
                        crossSelling: {
                            stepId: step.stepId,
                            productId,
                        },
                    })),
                );
            }

            if (step.products) {
                step.products.forEach((nestedProduct) => {
                    if (nestedProduct.steps) {
                        crossSellProducts.push(
                            ...extractCrossSellFromSteps(
                                nestedProduct.steps,
                                nestedProduct.productId,
                            ),
                        );
                    }
                });
            }

            return crossSellProducts;
        });
    };

    return extractCrossSellFromSteps(product.steps ?? [], product.productId);
};

const removeCrossSellSteps = (cart: Cart): Cart => {
    return cart.map((product) => ({
        ...product,
        steps: product.steps
            .filter((step) => step.type !== StepType.CROSS_SELLING)
            // At the time of wrting this code, the API preview endpoint doesn't accept step.type property
            .map(({ products, ...step }) => {
                const productsWithFilteredSteps = removeCrossSellSteps(products);
                return { ...step, products: productsWithFilteredSteps };
            }),
    }));
};

export const computeStepsPayload = (steps: EnrichedStep[] | undefined): StepDTO[] => {
    if (!steps) {
        return [];
    }

    return steps.map(({ stepId, products }) => ({
        stepId,
        products: computeCartPayload(products),
    }));
};

export const computeCartPayload = (cart: EnrichedProduct[]): Cart =>
    cart.map((product) => ({
        productId: product.productId,
        productCartId: product.productCartId || '',
        quantity: product.quantity,
        steps: computeStepsPayload(product.steps),
        ...(product.crossSelling !== undefined && { crossSelling: product.crossSelling }),
    }));

export const computeRestaurantCarts = ({
    orders,
    ordersToUpdate,
}: {
    orders: OrdersState;
    ordersToUpdate: {
        restaurantId: number;
        menuId: number;
        cart: Cart;
    }[];
}): RestaurantCartToVerify[] => {
    const multiOrders = { ...orders };

    ordersToUpdate.forEach((order) => {
        const { restaurantId, menuId, cart } = order;
        multiOrders[restaurantId] = {
            restaurantId,
            menuId,
            cart,
        } as unknown as OrderVerified;
    });

    return Object.values(multiOrders).map((order) => ({
        restaurantId: order.restaurantId,
        menuId: order.menuId,
        cart: computeCartPayload(order.cart),
    }));
};
