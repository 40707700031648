"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cartRawDataAdapter = void 0;
var bignumber_js_1 = require("bignumber.js");
var big_numbers_1 = require("../../utils/big-numbers");
var getChildrenPrices = function (steps, parentQuantity) {
    return steps.reduce(function (sum, step) {
        return step.products.reduce(function (sumStep, product) {
            var quantity = parentQuantity * product.quantity;
            sumStep.sumChildrenAmount = new bignumber_js_1.BigNumber(product.originalUnitPrice || 0)
                .multipliedBy(quantity)
                .plus(sumStep.sumChildrenAmount)
                .toNumber();
            sumStep.sumChildrenExtra = new bignumber_js_1.BigNumber(product.price || 0)
                .multipliedBy(parentQuantity)
                .plus(sumStep.sumChildrenExtra)
                .toNumber();
            return sumStep;
        }, sum);
    }, { sumChildrenAmount: 0, sumChildrenExtra: 0 });
};
var flattenProducts = function (products, depth, parentTaxRate, parentQuantity, sumSiblings, parentOriginPrice, parentRealPrice) {
    return products.reduce(function (extractedProducts, _a) {
        var productId = _a.productId, productCartId = _a.productCartId, name = _a.name, vat = _a.vat, price = _a.price, quantity = _a.quantity, steps = _a.steps, _b = _a.tags, tags = _b === void 0 ? {} : _b, _c = _a.originalUnitPrice, originalUnitPrice = _c === void 0 ? 0 : _c, _d = _a.isEligibleLuncheon, isEligibleLuncheon = _d === void 0 ? false : _d, _e = _a.isProducible, isProducible = _e === void 0 ? false : _e, sku = _a.sku, purchasePrice = _a.purchasePrice, billingCode = _a.billingCode, crossSelling = _a.crossSelling;
        steps = steps || [];
        // eslint-disable-next-line no-restricted-globals
        var taxRate = isNaN(vat) ? parentTaxRate : vat;
        var productQuantity = quantity * parentQuantity;
        var _f = getChildrenPrices(steps, productQuantity), sumChildrenAmount = _f.sumChildrenAmount, sumChildrenExtra = _f.sumChildrenExtra;
        var productBasicPrice = new bignumber_js_1.BigNumber(originalUnitPrice).multipliedBy(productQuantity);
        var productExtra = new bignumber_js_1.BigNumber(price).multipliedBy(parentQuantity);
        var isTopLevelProduct = !parentOriginPrice;
        var currentProducts = [];
        var productCalculatedPrice;
        if (!isTopLevelProduct) {
            var ratio = void 0;
            if (parentOriginPrice.isGreaterThan(sumSiblings)) {
                ratio = productBasicPrice.dividedBy(parentOriginPrice);
            }
            else if (sumSiblings !== 0) {
                ratio = productBasicPrice.dividedBy(sumSiblings);
            }
            else {
                ratio = big_numbers_1.big0;
            }
            productCalculatedPrice = parentRealPrice.multipliedBy(ratio).integerValue();
            if (productCalculatedPrice.toNumber() === 0) {
                productCalculatedPrice = productCalculatedPrice.plus(productExtra).minus(sumChildrenExtra);
            }
        }
        else {
            productCalculatedPrice = productBasicPrice;
        }
        var _g = steps.reduce(function (sum, step) {
            return flattenProducts(step.products, depth + 1, taxRate, productQuantity, sumChildrenAmount, productBasicPrice, productCalculatedPrice).reduce(function (destSum, extractedProduct) {
                currentProducts.push(extractedProduct);
                destSum.childrenAmountWithoutUpsell = destSum.childrenAmountWithoutUpsell.plus(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
                extractedProduct.additionalData.amountWithoutUpsell);
                destSum.childrenAmount = destSum.childrenAmount.plus(extractedProduct.amount);
                return destSum;
            }, sum);
        }, { childrenAmountWithoutUpsell: big_numbers_1.big0, childrenAmount: big_numbers_1.big0 }), childrenAmountWithoutUpsell = _g.childrenAmountWithoutUpsell, childrenAmount = _g.childrenAmount;
        productCalculatedPrice = productCalculatedPrice.minus(childrenAmountWithoutUpsell);
        if (productCalculatedPrice.lt(0)) {
            productCalculatedPrice = big_numbers_1.big0;
        }
        if (isTopLevelProduct) {
            var overCents_1 = childrenAmount.plus(productCalculatedPrice).minus(productExtra);
            if (productCalculatedPrice.gt(0) || !originalUnitPrice) {
                productCalculatedPrice = productCalculatedPrice.minus(overCents_1);
            }
            else {
                currentProducts.reduce(function (amountToApply, product) {
                    if (!amountToApply.eq(0) && product.amount.gt(0)) {
                        var amountAfterApply = product.amount.minus(overCents_1);
                        if (amountAfterApply.gte(0)) {
                            product.amount = amountAfterApply;
                            amountToApply = big_numbers_1.big0;
                        }
                    }
                    return amountToApply;
                }, overCents_1);
            }
        }
        currentProducts.unshift({
            id: productId,
            productCartId: productCartId,
            sku: sku,
            depth: depth,
            quantity: quantity * parentQuantity,
            label: name,
            taxRate: taxRate,
            amount: productCalculatedPrice,
            tags: Object.values(tags).map(function (tag) { return tag.name; }),
            isEligibleLuncheon: isEligibleLuncheon,
            isProducible: isProducible,
            additionalData: {
                amountWithoutUpsell: productCalculatedPrice.minus(productExtra).plus(sumChildrenExtra),
                crossSelling: crossSelling,
            },
            purchasePrice: purchasePrice,
            billingCode: billingCode,
        });
        return __spreadArray(__spreadArray([], extractedProducts, true), currentProducts, true);
    }, []);
};
function duplicateProducts(products, shouldDuplicate) {
    if (shouldDuplicate === void 0) { shouldDuplicate = false; }
    return products.flatMap(function (product) {
        var steps = product.steps
            ? product.steps.map(function (_a) {
                var stepProducts = _a.products;
                return ({
                    products: duplicateProducts(stepProducts, shouldDuplicate || product.quantity > 1),
                });
            })
            : [];
        if (!shouldDuplicate) {
            return __assign(__assign({}, product), { steps: steps });
        }
        var initializedArray = Array(product.quantity).fill(1);
        return initializedArray.map(function () { return (__assign(__assign({}, product), { price: product.price / product.quantity, quantity: 1, steps: steps })); });
    });
}
function cartRawDataAdapter(_a) {
    var products = _a.products;
    return flattenProducts(duplicateProducts(products), 0, 0, 1);
}
exports.cartRawDataAdapter = cartRawDataAdapter;
