import { SvyIcon } from '@innovorder/savory';
import { useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { handleCopyUrlToClipboard } from './Incognito.utils';

export const IncognitoSteps = ['step_1', 'step_2'] as const;
export type IncognitoStep = typeof IncognitoSteps[number];
export type IncognitoButton = {
    name: string;
    onClick: () => void;
    elementRight?: ReactElement;
};

export const useIncognitoVM = () => {
    const { t } = useTranslation();

    const [step, setStep] = useState<IncognitoStep>('step_1');
    const [hasCopiedUrl, setHasCopiedUrl] = useState<boolean>(false);

    const handleHasCopiedUrl = () => {
        setHasCopiedUrl(true);
    };

    const buttons: Record<IncognitoStep, IncognitoButton> = {
        step_1: {
            name: t(`incognito_page.${step}.cta`),
            onClick: () => setStep('step_2'),
            elementRight: <SvyIcon icon="question-line" />,
        },
        step_2: {
            name: hasCopiedUrl ? t('incognito_page.copied') : t(`incognito_page.${step}.cta`),
            onClick: () => handleCopyUrlToClipboard(handleHasCopiedUrl),
            elementRight: <SvyIcon icon={hasCopiedUrl ? 'check-line' : 'file-copy-line'} />,
        },
    };

    return {
        step,
        button: buttons[step],
    };
};
