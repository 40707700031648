import styled from '@emotion/styled';
import { GrowingContainer as BaseGrowingContainer } from 'components';

export const TitleContainer = styled.div`
    margin-top: ${({ theme }) => theme.base.spacing[3]};
    margin-bottom: -${({ theme }) => theme.base.spacing[3]};
    padding-left: ${({ theme }) => theme.base.spacing[4]};
`;

export const GridContainer = styled.div`
    margin: ${({ theme }) => theme.base.spacing[2]} 0;
    padding: 0 ${({ theme }) => theme.base.spacing[2]};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.base.spacing[2]};
    grid-row-gap: ${({ theme }) => theme.base.spacing[2]};
`;

export const ButtonContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[4]};
    width: 100%;
`;

export const HeaderContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[3]};
`;

export const GrowingContainer = styled(BaseGrowingContainer)`
    display: flex;
    flex-direction: column;
`;
