import React from 'react';
import PaygreenRequireActionModal from './PaygreenRequireActionModal';

type Props = {
    paymentUrl: string;
};

export const PaygreenRequireAction: React.FunctionComponent<Props> = ({ paymentUrl }: Props) => {
    const [modalOpened, setModalOpened] = React.useState(true);

    return (
        <PaygreenRequireActionModal
            isOpen={modalOpened}
            paymentUrl={paymentUrl}
            onHide={() => {
                setModalOpened(false);
            }}
        />
    );
};

export default PaygreenRequireAction;
