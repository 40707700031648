import React from 'react';
import { Drawer } from './BottomDrawer.style';

type BottomDrawerProps = {
    children?: React.ReactNode;
};
export const BottomDrawer: React.FunctionComponent<BottomDrawerProps> = ({ children }) => {
    return (
        <Drawer
            initial={{ translateY: 300 }}
            animate={{ translateY: 0 }}
            transition={{
                duration: 0.6,
                delay: 0.5,
                type: 'spring',
                bounce: 0.4,
            }}
            data-testid="bottom-drawer"
        >
            {children}
        </Drawer>
    );
};
