import { Button, Modal, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { FollowUpStatus } from 'services/order/order.type';
import { TranslationKey } from 'locales/fr';
import { ModalContainer, ModalContent } from './OrderStatusModal.style';

export type OrderStatusModalProps = {
    payload: {
        restaurantName: string;
        status: FollowUpStatus;
    } | null;
    onHide: () => void;
};

const OrderStatusModal: React.FunctionComponent<OrderStatusModalProps> = ({ payload, onHide }) => {
    const { t } = useTranslation();

    if (!payload) {
        return null;
    }

    const { restaurantName, status } = payload;
    const title = `modal_order_status.title_${status.toLowerCase()}` as TranslationKey;
    const description = `modal_order_status.order_${status.toLowerCase()}` as TranslationKey;

    return (
        <Modal headerTitle={title} isOpen={true} onHide={onHide}>
            <ModalContainer>
                <ModalContent>
                    <Text text={description} values={{ restaurantName }} type={3} />
                </ModalContent>
                <Button onClick={onHide} variant="text">
                    {t('modal_order_status.i_understand')}
                </Button>
            </ModalContainer>
        </Modal>
    );
};

export default OrderStatusModal;
