"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultDiscountScope = exports.DiscountScope = void 0;
var discount_types_1 = require("../../types/discount.types");
var getTaggedDiscountablesWithChildren = function (taggedDiscountables, allDiscountables) {
    var taggedDiscountablesWithChildren = [];
    var currentDepth = null;
    var addDiscountables = false;
    allDiscountables.forEach(function (discountable) {
        if (currentDepth === discountable.depth) {
            addDiscountables = false;
        }
        if (!addDiscountables && taggedDiscountables.includes(discountable)) {
            currentDepth = discountable.depth;
            addDiscountables = true;
        }
        if (addDiscountables) {
            taggedDiscountablesWithChildren.push(discountable);
        }
    });
    return taggedDiscountablesWithChildren;
};
var DiscountScope = /** @class */ (function () {
    function DiscountScope(_a) {
        var type = _a.type, filter = _a.filter;
        this.type = type;
        this.filter = filter;
    }
    DiscountScope.prototype.isEqualTo = function (_a) {
        var type = _a.type, filter = _a.filter;
        return (type.size === this.type.size &&
            ((!filter && !this.filter) ||
                (!!filter &&
                    !!this.filter &&
                    filter.type === this.filter.type &&
                    filter.value.size === this.filter.value.size &&
                    Array.from(this.filter.value.values()).every(function (id) { return filter.value.has(id); }))) &&
            Array.from(this.type.values()).every(function (discountableType) { return type.has(discountableType); }));
    };
    DiscountScope.prototype.apply = function (discountables) {
        return this.applyScopeFilter(this.applyScopeType(discountables));
    };
    DiscountScope.prototype.applyScopeFilter = function (discountables) {
        var _this = this;
        if (!this.filter) {
            return discountables;
        }
        switch (this.filter.type) {
            case discount_types_1.DiscountFilterType.MANUAL: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
                return discountables.filter(function (discountable) { return _this.filter.value.has(discountable.data.applyScopeId); });
            }
            case discount_types_1.DiscountFilterType.TAGS: {
                var taggedDiscountables = discountables.filter(function (discountable) {
                    // IF CHILD PRODUCT
                    if (discountable.type !== discount_types_1.DISCOUNTABLE_TYPE.FEE && discountable.depth > 0) {
                        return (discountable.parentTags || []).some(function (parentTag) {
                            return _this.filter.value.has(parentTag.toLocaleLowerCase());
                        });
                    }
                    // CASE 2 - PARENT PRODUCT OR FEE
                    if (discountable.type !== discount_types_1.DISCOUNTABLE_TYPE.FEE && discountable.depth === 0) {
                        return (discountable.tags || []).some(function (tag) {
                            return _this.filter.value.has(tag.toLocaleLowerCase());
                        });
                    }
                    return (discountable.tags || []).some(function (tag) {
                        return _this.filter.value.has(tag.toLocaleLowerCase());
                    });
                });
                return getTaggedDiscountablesWithChildren(taggedDiscountables, discountables);
            }
            default: {
                return discountables;
            }
        }
    };
    DiscountScope.prototype.applyScopeType = function (discountables) {
        var _this = this;
        return discountables.filter(function (discountable) { return _this.type.has(discountable.type); });
    };
    return DiscountScope;
}());
exports.DiscountScope = DiscountScope;
exports.defaultDiscountScope = (_a = {},
    _a[discount_types_1.DiscountApplyMethod.ABSOLUTE_CART] = {
        type: new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT]),
        filter: null,
    },
    _a[discount_types_1.DiscountApplyMethod.GRANT_ABSOLUTE_GLOBAL] = {
        type: new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT, discount_types_1.DISCOUNTABLE_TYPE.FEE]),
        filter: null,
    },
    _a[discount_types_1.DiscountApplyMethod.FORCE_TOTAL_AMOUNT] = {
        type: new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT]),
        filter: null,
    },
    _a[discount_types_1.DiscountApplyMethod.RATE] = {
        type: new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT]),
        filter: null,
    },
    _a[discount_types_1.DiscountApplyMethod.ABSOLUTE_GLOBAL] = {
        type: new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT, discount_types_1.DISCOUNTABLE_TYPE.FEE]),
        filter: null,
    },
    _a);
