import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyledCalloutProps } from './Callout.interface';

export const StyledContainer = styled.div<StyledCalloutProps>`
    --text-color: ${({ theme }) => theme.base.colors.secondary[50]};
    --icon-color: ${({ theme }) => theme.base.colors.secondary[50]};
    --background-color: ${({ theme }) => theme.base.colors.orange[400]};

    ${({ variant, theme }) => {
        switch (variant) {
            case 'danger':
                return css`
                    --background-color: ${theme.base.colors.destructive[400]};
                `;
            case 'info':
                return css`
                    --background-color: ${theme.base.colors.info[100]};
                    --text-color: ${theme.base.colors.neutral[900]};
                    --icon-color: ${theme.base.colors.info[500]};
                `;
            default:
                return css``;
        }
    }}

    > span {
        color: var(--text-color);
        &::before {
            color: var(--icon-color);
        }
    }

    color: var(--text-color);
    background-color: var(--background-color);
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
