import { TicketStatus, FollowUpStatus } from 'services/order/order.type';

export const mapToFollowUpStatus = (status: TicketStatus): FollowUpStatus => {
    switch (status) {
        case 'DISPLAYING':
            return 'READY';
        case 'DONE':
            return 'COLLECTED';
        case 'MODIFIED':
        case 'TO_DISPLAY':
        case 'TO_DO':
            return 'PROCESSING';
        case 'CANCELLED':
        case 'TO_CANCEL':
            return 'CANCELLED';
        case 'WAITING':
        default:
            return 'WAITING';
    }
};
