import styled from '@emotion/styled';

export const TitleContainer = styled.div<{ isFirst?: boolean }>`
    margin-top: ${({ theme }) => theme.base.spacing[1]};
    padding-top: ${({ theme }) => theme.base.spacing[2]};
    display: flex;
    justify-content: left;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: ${({ theme, isFirst }) =>
        !isFirst && `1px solid ${theme.feature.thirdaryTextColor}`};
    & > div:first-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    & img {
        max-width: 80px;
        border: 1px solid ${({ theme }) => theme.feature.thirdaryTextColor};
        border-radius: 10px;
    }
`;

export const StyledImg = styled.img`
    margin-right: ${({ theme }) => theme.base.spacing[2]};
`;

export const OrderNumber = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${({ theme }) => theme.base.spacing[2]} 0;
`;
