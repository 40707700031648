import React from 'react';
import { useConfirmPayment } from 'hooks/useConfirmPayment/useConfirmPayment';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';
import BaseConfirmPayment from '../BaseConfirmPayment';
import EdenredAuth from './EdenredAuth';

const Edenred: React.FunctionComponent = () => {
    const { handlePaymentSubmit, isLoadingCreateOrder } = useConfirmPayment('edenred');
    const { value: accessToken, setInLocalStorage } = useLocalStorage('edenredAccessToken');

    const handleEdenredPaymentSubmit = async () => {
        const edenredAccessToken = accessToken as string;
        await handlePaymentSubmit({ edenredAccessToken });
        setInLocalStorage(null);
    };

    return (
        <BaseConfirmPayment isLoading={isLoadingCreateOrder}>
            <EdenredAuth
                handleEdenredPaymentSubmit={handleEdenredPaymentSubmit}
                isLoading={isLoadingCreateOrder}
            />
        </BaseConfirmPayment>
    );
};

export default Edenred;
