import styled from '@emotion/styled';
import { SvyBadge } from '@innovorder/savory';
import { Text } from 'components/Text';

export const restaurantCardMinWidth = '164px';
export const restaurantCardMaxWidth = '246px';
export const restaurantCardMinHeight = '135px';

export const Container = styled.div`
    min-width: ${restaurantCardMinWidth};
    width: 100%;
    max-width: ${restaurantCardMaxWidth};
`;

export const ImgContainer = styled.div<{ imgUrl: string }>`
    position: relative;
    display: flex;
    height: ${restaurantCardMinHeight};
    border: solid 1px ${({ theme }) => theme.base.colors.secondary[200]};
    border-radius: ${({ theme }) => theme.base.radius.default};
    background-image: url(${({ imgUrl }) => imgUrl});
    background-size: cover;
    background-position: center;
    overflow: hidden;
`;

export const OverlayBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.base.colors.neutral[900]};
    opacity: 0.6;
`;

export const OverlayTextContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledOverlayText = styled(Text)`
    width: 80%;
    text-align: center;
    color: ${({ theme }) => theme.feature.headerTextColor};
`;

export const StyledText = styled(Text)`
    margin-top: ${({ theme }) => theme.base.spacing[1]};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const TagsContainer = styled.div`
    position: absolute;
    bottom: ${({ theme }) => theme.base.spacing[1]};
    left: ${({ theme }) => theme.base.spacing[1]};
`;

export const TagBadge = styled(SvyBadge)`
    position: absolute;
    bottom: ${({ theme }) => theme.base.spacing[1]};
    left: ${({ theme }) => theme.base.spacing[1]};
`;

export const DelayBadge = styled(SvyBadge)`
    position: absolute;
    top: ${({ theme }) => theme.base.spacing[1]};
    right: ${({ theme }) => theme.base.spacing[1]};
`;
