export const computeIsMaxChoiceReached = (
    selected: Map<unknown, number> | undefined,
    maxChoice: number | undefined,
): boolean => {
    if (!selected || !maxChoice) {
        return false;
    }

    let count = 0;

    selected.forEach((value) => {
        if (value) count += value;
    });

    return count >= maxChoice;
};
