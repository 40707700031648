import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export const nextOpeningToI18n = (t: TFunction, date?: string) => {
    if (date === undefined) return t('order_closed');

    const startOfToday = DateTime.now().startOf('day');
    const startOfTomorrow = startOfToday.plus({ day: 1 });

    const opening = DateTime.fromISO(date);

    if (opening.startOf('day').equals(startOfToday))
        return t('open_today_at', {
            hours: opening.toFormat('HH'),
            minutes: opening.toFormat('mm'),
        });

    if (opening.startOf('day').equals(startOfTomorrow))
        return t('open_tomorrow_at', {
            hours: opening.toFormat('HH'),
            minutes: opening.toFormat('mm'),
        });

    return t('open_at', {
        date: opening.toLocaleString({
            month: 'long',
            day: 'numeric',
            weekday: 'long',
        }),
        hours: opening.toFormat('HH'),
        minutes: opening.toFormat('mm'),
    });
};

export const useNextOpeningI18n = (date?: string) => {
    const { t } = useTranslation();

    return useMemo(() => {
        return nextOpeningToI18n(t, date);
    }, [t, date]);
};
