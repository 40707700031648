import styled from '@emotion/styled';
import { Caption } from 'components';
import { ReactComponent as LogoFrenchRepublic } from './logo_french_republic.svg';

export const TopCallout = styled.div`
    position: fixed;
    top: ${({ theme }) => theme.base.spacing[2]};
    left: ${({ theme }) => theme.base.spacing[2]};
    right: ${({ theme }) => theme.base.spacing[2]};
    background-color: ${({ theme }) => theme.feature.backgroundColor};
    border-radius: ${({ theme }) => theme.base.radius.default};

    padding: ${({ theme }) => theme.base.spacing[1]};

    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.base.spacing[1]};
    opacity: 0;

    @keyframes smoothSpawn {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    animation: 1s ease-out 0s 1 smoothSpawn forwards;
    animation-delay: 1s;
`;

export const StyledFrenchRepublicLogo = styled(LogoFrenchRepublic)`
    flex-shrink: 0;
`;

export const SmallerCaption = styled(Caption)`
    font-size: 10px;
`;
