"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.totalAmountForceDiscountAdapter = void 0;
var bignumber_js_1 = require("bignumber.js");
var discount_types_1 = require("../../../types/discount.types");
var types_1 = require("../types");
var hasDuplicated = function (type, orderItems) {
    var distinctItems = {};
    var duplicatedItemsFound = false;
    orderItems
        .filter(function (_a) {
        var orderItemType = _a.orderItemType, cancelReason = _a.cancelReason;
        return orderItemType === type && !cancelReason;
    })
        .forEach(function (_a) {
        var name = _a.name, totalPriceDiscountedWithTaxIncluded = _a.totalPriceDiscountedWithTaxIncluded;
        // items grants or entranceFees have no unique id. I consider it's the same grant duplicated if the name and the amount is the same
        var uniqueAggregate = "".concat(name).concat(totalPriceDiscountedWithTaxIncluded);
        if (distinctItems[uniqueAggregate]) {
            duplicatedItemsFound = true;
        }
        distinctItems[uniqueAggregate] = true;
    });
    return duplicatedItemsFound;
};
var shouldApplyTotalAmountForceDiscount = function (orderItems) {
    return !hasDuplicated(types_1.PosOrderItemType.GRANT, orderItems) && !hasDuplicated(types_1.PosOrderItemType.ENTRANCE_FEE, orderItems);
};
var totalAmountForceDiscountAdapter = function (_a) {
    var totalPriceDiscountedWithTaxIncluded = _a.totalPriceDiscountedWithTaxIncluded, orderItems = _a.orderItems;
    return shouldApplyTotalAmountForceDiscount(orderItems)
        ? [
            {
                id: 'POS_FORCE_TOTAL_AMOUNT_DISCOUNT',
                label: 'POS_FORCE_TOTAL_AMOUNT_DISCOUNT',
                strategy: {
                    applyMethod: discount_types_1.DiscountApplyMethod.FORCE_TOTAL_AMOUNT,
                    type: discount_types_1.DISCOUNT_TYPE.FORCE_TOTAL_AMOUNT,
                    value: 0,
                    scope: {
                        type: new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT, discount_types_1.DISCOUNTABLE_TYPE.FEE]),
                    },
                },
                additionalData: {
                    targetTotalAmount: new bignumber_js_1.BigNumber(totalPriceDiscountedWithTaxIncluded).shiftedBy(2),
                },
            },
        ]
        : [];
};
exports.totalAmountForceDiscountAdapter = totalAmountForceDiscountAdapter;
