// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker/locale/fr';
import { ConsumptionModeType } from '@innovorder/order_detail';
import {
    ConsumptionMode,
    PaymentMethod,
    PaymentMethodCode,
} from 'services/consumptionMode/consumptionMode.type';
import { ChannelId } from 'services/order/order.type';

faker.locale = 'fr';

export const paymentMethodFixtureFactory = (params: Partial<PaymentMethod> = {}): PaymentMethod => {
    const defaultValues: PaymentMethod = {
        paymentMethodId: 1,
        brandId: 1,
        channelId: ChannelId.WEB,
        restaurantId: 1,
        posDeviceId: 1,
        code: PaymentMethodCode.Stripe,
        active: true,
    };

    return { ...defaultValues, ...params };
};

export const consumptionModeFixtureFactory = (
    params: Partial<ConsumptionMode> = {},
): ConsumptionMode => {
    const defaultValues: ConsumptionMode = {
        consumptionModeId: 1,
        brandId: 1,
        restaurantId: 1,
        channelId: ChannelId.WEB,
        delay: 5,
        paymentMethods: [paymentMethodFixtureFactory()],
        type: ConsumptionModeType.MODE_SIT_IN,
    };

    return { ...defaultValues, ...params };
};
