import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getBrandCurrency } from 'redux/brand';
import { getMenuState, getProductById } from 'redux/menu';
import { useAppSelector, useAppDispatch } from 'redux/store';
import { getOrdersErrors } from 'redux/ordersErrors/ordersErrors.slice';
import { useCartHandler } from 'hooks/useCartHandler';
import {
    getCurrentPath,
    getNestedProductId,
    setCurrentCompositeProduct,
    returnToPreviousStep,
    getProductByPath,
    updateMissingSteps,
    getMissingSteps,
} from 'redux/currentCompositeProduct';
import { getCurrentMenuId, getRestaurantId } from 'redux/app';
import { getRestaurantScheduleByRestaurantId } from 'redux/restaurantSchedules';
import { useNextOpeningI18n } from 'hooks/useNextOpeningI18n';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useMenuHandler } from 'hooks/useMenuHandler';
import { useTranslation } from 'react-i18next';

import { useConsumptionModeHandler } from 'hooks/useConsumptionModeHandler/useConsumptionModeHandler';
import {
    parseProductId,
    computeTotalAmount,
    computeMissingSteps,
    computeProductSteps,
    computeIsButtonDisabled,
} from './Product.utils';
import { StatusType } from './ProductSteps/useProductStepsVM';

export const useProductVM = () => {
    const { productId } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { navigateBackToMenuHome } = useCustomNavigate();

    const restaurantId = useAppSelector(getRestaurantId);
    const menuId = useAppSelector(getCurrentMenuId);
    const restaurantSchedule = useAppSelector(getRestaurantScheduleByRestaurantId(restaurantId));
    const currency = useAppSelector(getBrandCurrency);
    const menu = useAppSelector(getMenuState);
    const currentPath = useAppSelector(getCurrentPath);
    const currentProduct = useAppSelector(getProductById(parseProductId(productId)));
    const mainCompositeProduct = useAppSelector(getProductByPath(productId ? [productId] : []));
    const missingSteps = useAppSelector(getMissingSteps);
    const nestedProductId = useAppSelector(getNestedProductId);
    const nestedProduct = useAppSelector(getProductById(parseProductId(nestedProductId)));
    const nestedCompositeProduct = useAppSelector(getProductByPath(currentPath || []));
    const ordersErrors = useAppSelector(getOrdersErrors);

    const isNested = !!nestedProductId;
    const productSteps = nestedProduct?.productStep || currentProduct?.productStep;
    const shouldDisplayNextOpening = restaurantSchedule?.isCurrentlyOpen === false;
    const nextOpeningI18n = useNextOpeningI18n(restaurantSchedule?.nextOpening);
    const [hasTriedAddingToCart, setHasTriedAddingToCart] = useState<boolean>(false);

    const DEFAULT_QUANTITY = 1;
    const [quantity, setQuantity] = useState<number>(DEFAULT_QUANTITY);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [userShouldRetry, setUserShouldRetry] = useState<boolean>(false);

    const { handleAddProductToCart, data, isLoading } = useCartHandler();

    const { isLoading: isMenuLoading, isError: isMenuError } = useMenuHandler();

    useConsumptionModeHandler();

    const totalAmountWithCurrency = computeTotalAmount(
        currentProduct?.price,
        quantity,
        currency,
        mainCompositeProduct,
        menu,
    );

    const isComposite = !!nestedProduct?.productStep.length || !!currentProduct?.productStep.length;

    const buttonText = t(isNested ? 'save' : 'add_to_cart_with_price', {
        amount: totalAmountWithCurrency,
    });

    const handleChangeProductQuantity = (newQuantity: number) => {
        setQuantity(newQuantity);
    };

    const handleConfirmAddProduct = () => {
        if (restaurantId && menuId) {
            currentProduct &&
                handleAddProductToCart({
                    productId: currentProduct.productId,
                    quantity,
                    restaurantId,
                    menuId,
                });
            setUserShouldRetry(false);
        }
    };

    const scrollToMandatory = (status: StatusType) => {
        const mandatoryStatuses = document.querySelectorAll(`[data-mandatory-status="${status}"]`);

        if (mandatoryStatuses.length >= 1)
            mandatoryStatuses[0].scrollIntoView({ behavior: 'smooth' });
    };

    const handleDisabledAddProduct = () => {
        // First call will occur with mandatory status to primary
        if (!hasTriedAddingToCart) {
            scrollToMandatory('primary');

            setHasTriedAddingToCart(true);
        } else {
            scrollToMandatory('destructive');
        }
    };

    const saveAndReturn = useCallback(() => {
        if (currentPath && isNested) {
            dispatch(returnToPreviousStep(currentPath));
            dispatch(
                updateMissingSteps(
                    computeMissingSteps(
                        currentPath,
                        nestedCompositeProduct,
                        productSteps,
                        missingSteps,
                    ),
                ),
            );

            setUserShouldRetry(false);
        }
    }, [currentPath, isNested, nestedCompositeProduct, productSteps, missingSteps, dispatch]);

    useEffect(() => {
        setIsButtonDisabled(
            computeIsButtonDisabled(nestedCompositeProduct, productSteps, missingSteps, isNested),
        );
    }, [nestedCompositeProduct, productSteps, missingSteps, isNested]);

    useEffect(() => {
        if (productId && isComposite && !currentPath) {
            dispatch(
                setCurrentCompositeProduct({
                    currentStep: {
                        [productId]: { quantity: DEFAULT_QUANTITY },
                    },
                    currentPath: [productId],
                }),
            );
        }
    }, [productId, isComposite, currentPath, dispatch]);

    useEffect(() => {
        if (!isLoading && data && !userShouldRetry) {
            if (ordersErrors && productId) {
                const error = ordersErrors.find((e) => e.productId.toString() === productId);
                if (error && error.errorFromSteps) {
                    setUserShouldRetry(true);
                    return;
                }
            }

            navigateBackToMenuHome();
        }
    }, [
        isLoading,
        data,
        dispatch,
        navigateBackToMenuHome,
        ordersErrors,
        productId,
        userShouldRetry,
    ]);

    return {
        isError: !currentProduct || isMenuError,
        productId: parseProductId(nestedProductId || productId),
        product: nestedProduct || currentProduct,
        currency,
        isLoading: isLoading || isMenuLoading,
        buttonText,
        quantity,
        isComposite,
        isNested,
        productSteps: computeProductSteps(productSteps),
        handleChangeProductQuantity,
        handleConfirmAddProduct,
        navigateBackToMenuHome,
        saveAndReturn,
        ordersErrors,
        isButtonDisabled,
        shouldDisplayNextOpening,
        nextOpeningI18n,
        handleDisabledAddProduct,
        hasTriedAddingToCart,
    };
};
