"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCeilingFromOrderDetailDiscount = void 0;
var big_numbers_1 = require("../../utils/big-numbers");
function getCeilingFromOrderDetailDiscount(data) {
    var _a, _b;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    var ceilingValue = (_b = (_a = data === null || data === void 0 ? void 0 : data.additionalData) === null || _a === void 0 ? void 0 : _a.ceiling) !== null && _b !== void 0 ? _b : null;
    return Number.isFinite(ceilingValue) ? big_numbers_1.big0.plus(Number(ceilingValue)) : undefined;
}
exports.getCeilingFromOrderDetailDiscount = getCeilingFromOrderDetailDiscount;
