import styled from '@emotion/styled';
import { GrowingContainer, PageWithBottomBarContainer } from 'components';

export const CartContainer = styled(PageWithBottomBarContainer)`
    position: relative;
`;

export const CartContentContainer = styled(GrowingContainer)`
    padding: ${({ theme }) => theme.base.spacing[2]};
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const ProductSummaryContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.base.spacing[5]};
`;

export const PriceBreakDownSummaryContainer = styled.div`
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;
