import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;

export const SummaryContainer = styled.div`
    flex-grow: unset;
    padding-bottom: ${({ theme }) => theme.base.spacing[2]};
`;
