import { ConsumptionModeType } from '@innovorder/order_detail';

export const CONSUMPTION_FLAGS = {
    MODE_DELIVERY: 1, // 0 0 0 1
    MODE_TAKE_AWAY: 2, // 0 0 1 0
    MODE_SIT_IN: 4, // 0 1 0 0
    MODE_DRIVE: 8, // 1 0 0 0
};

export const isConsumptionFlagActivated = (
    productConsumptionFlags: number,
    currentConsumptionMode: ConsumptionModeType | null,
) => {
    if (currentConsumptionMode === null) return false;

    const currentConsumptionFlag = CONSUMPTION_FLAGS[currentConsumptionMode];
    return (productConsumptionFlags & currentConsumptionFlag) > 0;
};
