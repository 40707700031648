"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APPLY_ORDER = exports.DISCOUNTABLE_TYPE = exports.APPLY_METHOD = exports.DiscountApplyOrder = exports.DiscountApplyMethod = exports.RemiseTypes = exports.GrantStrategy = exports.DISCOUNT_TYPE = exports.DiscountFilterType = void 0;
var DiscountFilterType;
(function (DiscountFilterType) {
    DiscountFilterType["MANUAL"] = "MANUAL";
    DiscountFilterType["TAGS"] = "TAGS";
})(DiscountFilterType = exports.DiscountFilterType || (exports.DiscountFilterType = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
var DISCOUNT_TYPE;
(function (DISCOUNT_TYPE) {
    DISCOUNT_TYPE["GRANT"] = "GRANT";
    DISCOUNT_TYPE["FORCE_TOTAL_AMOUNT"] = "FORCE_TOTAL_AMOUNT";
    DISCOUNT_TYPE["COMPENSATION"] = "COMPENSATION";
    DISCOUNT_TYPE["PROMOCODE"] = "PROMOCODE";
    DISCOUNT_TYPE["LOYALTY"] = "LOYALTY";
    DISCOUNT_TYPE["TAKE_AWAY_REDUCTION"] = "TAKE_AWAY_REDUCTION";
    DISCOUNT_TYPE["POS"] = "POS";
    DISCOUNT_TYPE["ONLINE_PAYMENT"] = "ONLINE_PAYMENT";
})(DISCOUNT_TYPE = exports.DISCOUNT_TYPE || (exports.DISCOUNT_TYPE = {}));
var GrantStrategy;
(function (GrantStrategy) {
    GrantStrategy[GrantStrategy["Always"] = 1] = "Always";
    GrantStrategy[GrantStrategy["TagSubventionnable"] = 2] = "TagSubventionnable";
})(GrantStrategy = exports.GrantStrategy || (exports.GrantStrategy = {}));
exports.RemiseTypes = [
    DISCOUNT_TYPE.PROMOCODE,
    DISCOUNT_TYPE.LOYALTY,
    DISCOUNT_TYPE.TAKE_AWAY_REDUCTION,
    DISCOUNT_TYPE.POS,
    DISCOUNT_TYPE.ONLINE_PAYMENT,
];
var DiscountApplyMethod;
(function (DiscountApplyMethod) {
    DiscountApplyMethod["RATE"] = "RATE";
    DiscountApplyMethod["GRANT_ABSOLUTE_GLOBAL"] = "GRANT_ABSOLUTE_GLOBAL";
    DiscountApplyMethod["ABSOLUTE_CART"] = "ABSOLUTE_CART";
    DiscountApplyMethod["ABSOLUTE_GLOBAL"] = "ABSOLUTE_GLOBAL";
    DiscountApplyMethod["FORCE_TOTAL_AMOUNT"] = "FORCE_TOTAL_AMOUNT";
})(DiscountApplyMethod = exports.DiscountApplyMethod || (exports.DiscountApplyMethod = {}));
exports.DiscountApplyOrder = [
    DiscountApplyMethod.ABSOLUTE_CART,
    DiscountApplyMethod.GRANT_ABSOLUTE_GLOBAL,
    DiscountApplyMethod.RATE,
    DiscountApplyMethod.ABSOLUTE_GLOBAL,
];
// eslint-disable-next-line @typescript-eslint/naming-convention
var APPLY_METHOD;
(function (APPLY_METHOD) {
    APPLY_METHOD["RATE"] = "RATE";
    APPLY_METHOD["ABSOLUTE"] = "ABSOLUTE";
})(APPLY_METHOD = exports.APPLY_METHOD || (exports.APPLY_METHOD = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
var DISCOUNTABLE_TYPE;
(function (DISCOUNTABLE_TYPE) {
    DISCOUNTABLE_TYPE["PRODUCT"] = "PRODUCT";
    DISCOUNTABLE_TYPE["FEE"] = "FEE";
    DISCOUNTABLE_TYPE["COMPENSATORY_PRODUCT"] = "COMPENSATORY_PRODUCT";
})(DISCOUNTABLE_TYPE = exports.DISCOUNTABLE_TYPE || (exports.DISCOUNTABLE_TYPE = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
var APPLY_ORDER;
(function (APPLY_ORDER) {
    APPLY_ORDER[APPLY_ORDER["PRODUCT"] = 0] = "PRODUCT";
    APPLY_ORDER[APPLY_ORDER["FEE"] = 1] = "FEE";
})(APPLY_ORDER = exports.APPLY_ORDER || (exports.APPLY_ORDER = {}));
