import React from 'react';
import { Button, GrowingContainer, PageWithBottomBarContainer, HeaderLogo } from 'components';
import { Carousel } from 'components/Carousel';
import { ButtonContainer, HomeLogo, HomeLogoContainer } from './Restaurants.style';
import { useRestaurantsVM } from './useRestaurantsVM';
import RestaurantGrid from './RestaurantGrid/RestaurantGrid';
import EmptyRestaurants from './EmptyRestaurants';
import RestaurantsSkeleton from './RestaurantsSkeleton';

const Restaurants: React.FunctionComponent = () => {
    const {
        restaurants,
        families: items,
        isLoading,
        isError,
        shouldDisplayCart,
        shouldDisplayFamilies,
        buttonText,
        currentFamilyFilter,
        emptinessSubtitle,
        handleSelectRestaurant,
        handleSelectFamily,
        handleGoCart,
    } = useRestaurantsVM();

    if (isLoading) {
        return <RestaurantsSkeleton />;
    }

    return (
        <PageWithBottomBarContainer>
            <GrowingContainer>
                <HomeLogoContainer>
                    <HomeLogo>
                        <HeaderLogo data-testid="home-logo" />
                    </HomeLogo>
                </HomeLogoContainer>

                {shouldDisplayFamilies && (
                    <Carousel
                        items={items}
                        selectedItemId={currentFamilyFilter}
                        onChange={handleSelectFamily}
                    />
                )}

                {(restaurants.length === 0 || isError) && (
                    <EmptyRestaurants subtitle={emptinessSubtitle} />
                )}

                {restaurants.length > 0 && (
                    <RestaurantGrid
                        restaurants={restaurants}
                        onSelectRestaurant={handleSelectRestaurant}
                    />
                )}
            </GrowingContainer>

            {restaurants.length > 0 && shouldDisplayCart && (
                <ButtonContainer>
                    <Button fullWidth onClick={handleGoCart} size="l">
                        {buttonText}
                    </Button>
                </ButtonContainer>
            )}
        </PageWithBottomBarContainer>
    );
};

export default Restaurants;
