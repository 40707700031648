import React from 'react';
import StripePaymentMethod from './StripePaymentMethod';
import BaseConfirmPayment from '../BaseConfirmPayment';
import { useConfirmPayment } from '../../../../hooks/useConfirmPayment/useConfirmPayment';

const Payment: React.FunctionComponent = () => {
    const { handlePaymentSubmit, isLoadingCreateOrder } = useConfirmPayment('stripe');

    const handleStripePaymentSubmit = (stripePaymentMethodId: string | undefined) => {
        handlePaymentSubmit({ stripePaymentMethodId });
    };

    return (
        <BaseConfirmPayment isLoading={isLoadingCreateOrder}>
            <StripePaymentMethod
                handleStripePaymentSubmit={handleStripePaymentSubmit}
                isLoading={isLoadingCreateOrder}
            />
        </BaseConfirmPayment>
    );
};

export default Payment;
