import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { ENV } from 'utils/env';
import { useLocation } from 'react-router-dom';
import { EdenredPaymentCheckoutBuilder } from '@innovorder/payment';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';

export const useEdenredAuthentication = () => {
    const mounted = useRef<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [authenticationUrl, setAuthenticationUrl] = useState<string>();
    const { i18n } = useTranslation();
    const paymentCheckout = useMemo(
        () =>
            new EdenredPaymentCheckoutBuilder()
                .configureCheckout({
                    backendRootUrl: ENV.PAYMENT_SERVICE_URL,
                    locale: i18n.language,
                })
                .build(),
        [i18n.language],
    );

    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const { value: accessToken, setInLocalStorage } = useLocalStorage('edenredAccessToken');

    const authenticateToEdenred = useCallback(() => {
        setError(undefined);
        setIsLoading(true);
        const windowReference = window.open();
        const source = window.location.href;
        paymentCheckout
            .authenticateCustomer(source)
            .then((edenredAuthUrl) => {
                setAuthenticationUrl(edenredAuthUrl);
                if (windowReference) windowReference.location = edenredAuthUrl;
            })
            .catch((edenredError) => {
                setError(edenredError);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [paymentCheckout]);

    useEffect(() => {
        if (mounted.current && !accessToken && !query.get('access_token')) {
            authenticateToEdenred();
        } else {
            mounted.current = true;
        }
    }, [authenticateToEdenred, accessToken, query]);

    useEffect(() => {
        if (query.get('edenredAuth') && query.get('access_token')) {
            setInLocalStorage(query.get('access_token'));
            window.close();
        }
    }, [setInLocalStorage, query]);

    return {
        data: authenticationUrl,
        isLoading,
        authenticateToEdenred,
        error,
        isAuthenticated: !!accessToken,
        edenredAccessToken: accessToken,
    };
};
