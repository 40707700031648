import axios, { AxiosRequestHeaders } from 'axios';

import { EdenredCheckoutConfiguration } from './types';
import { edenredGetBalancePath, edenredAuthenticationUrlPath, edenredLogoutPath } from './routes';

export class EdenredPaymentServiceClient {
    private configuration: EdenredCheckoutConfiguration;

    public constructor(configuration: EdenredCheckoutConfiguration) {
        this.configuration = configuration;
    }

    public async getCustomerBalance(token: string): Promise<number> {
        const response = await axios({
            method: 'get',
            url: new URL(edenredGetBalancePath, this.configuration.backendRootUrl).href,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data as unknown as number;
    }

    /**
     * Get a customer's balance using its edenred token (anonymous mode).
     * @param edenredToken Access token provided by Edenred
     * @returns
     */
    public async getAnonymousCustomerBalance(edenredToken: string): Promise<number> {
        const response = await axios({
            method: 'get',
            url: new URL(edenredGetBalancePath, this.configuration.backendRootUrl).href,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'edenred-auth-token': edenredToken,
            },
        });

        return response.data as unknown as number;
    }

    public async customerAuthenticationUrl(locale: string, source: string, token?: string): Promise<string> {
        const urlParams = new URL(edenredAuthenticationUrlPath, this.configuration.backendRootUrl);
        urlParams.searchParams.set('locale', locale);
        urlParams.searchParams.set('source', source);
        urlParams.searchParams.set('isAnonymous', token ? 'false' : 'true');
        const headers: AxiosRequestHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) headers.Authorization = `Bearer ${token}`;
        const response = await axios.get<string>(urlParams.href, {
            headers,
        });

        return response.data;
    }

    public async logout(token: string): Promise<string> {
        const urlParams = new URL(edenredLogoutPath, this.configuration.backendRootUrl);
        const response = await axios.post<string>(
            urlParams.href,
            {},
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return response.data;
    }
}
