"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOffTurnoverFees = exports.filterTurnoverFees = void 0;
var order_detail_types_1 = require("../../types/order-detail.types");
var filterTurnoverFees = function (fees) {
    return fees.filter(function (fee) {
        return [order_detail_types_1.FEE_TYPE.DELIVERY, order_detail_types_1.FEE_TYPE.SERVICE, order_detail_types_1.FEE_TYPE.ENTRANCE, order_detail_types_1.FEE_TYPE.ADMISSION_FEE].includes(fee.type);
    });
};
exports.filterTurnoverFees = filterTurnoverFees;
var filterOffTurnoverFees = function (fees) { return fees.filter(function (fee) { return fee.type === order_detail_types_1.FEE_TYPE.ROYALTY_FEE; }); };
exports.filterOffTurnoverFees = filterOffTurnoverFees;
