import i18n from 'core/InitialiseThirdParties/i18n';
import { CrossSellingEnabledProduct } from 'services/crossSelling/crossSelling.type';
import { ProductAttributes } from 'services/menu/menu.type';
import { computePrice } from 'utils/price';

export type SelectedProduct = Pick<ProductAttributes, 'productId' | 'menuId'> & {
    restaurantId: number;
};

export const formatCrossSellingProducts = ({
    products,
    selectedProducts,
    translate,
    currency,
}: {
    products: CrossSellingEnabledProduct[];
    selectedProducts: SelectedProduct[];
    translate: typeof i18n;
    currency: string;
}) => {
    const formatedProducts = products
        .map((p) => {
            return {
                ...p,
                price: computePrice(translate, p.price, currency),
                isSelected: selectedProducts.some((s) => s.productId === p.productId),
            };
        })
        .sort(({ position: positionA }, { position: positionB }) =>
            positionA > positionB ? 1 : -1,
        );

    return formatedProducts;
};
