import styled from '@emotion/styled';

export const PageWithBottomBarContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const GrowingContainer = styled.div`
    flex: 1;
`;

export const BottomBarContainer = styled.div`
    box-shadow: ${({ theme }) => theme.base.shadow.outside[300]};
`;

export const BottomBar = styled(BottomBarContainer)<{ withPadding?: boolean }>`
    backdrop-filter: blur(3.5px);
    background: rgba(255, 255, 255, 0.4);
    padding: ${({ theme, withPadding }) => withPadding && theme.base.spacing[2]}
        ${({ theme, withPadding }) => withPadding && theme.base.spacing[2]};
    position: sticky;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    z-index: 3;
`;
