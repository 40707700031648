import { Button, Input, Modal } from 'components';
import { TouchedSvyIcon } from 'components/Input/Input.style';
import { MargedForm, SuccessCaption, ModalContainer } from './EmailReceiptModal.style';
import { useEmailReceiptModalVM } from './useEmailReceiptModalVM';

type Props = {
    isOpen: boolean;
    onHide: () => void;
};

const EmailReceiptModal: React.FunctionComponent<Props> = ({ isOpen, onHide }) => {
    const {
        buttonText,
        email,
        emailError,
        isEmailTouched,
        isLoading,
        isSuccess,
        isDisabled,
        handleOnChange,
        handleOnblur,
        handleOnHide,
        handleSendEmail,
        handleOnSubmit,
    } = useEmailReceiptModalVM(onHide);

    return (
        <Modal headerTitle={'enter_email'} isOpen={isOpen} onHide={handleOnHide}>
            <ModalContainer>
                <MargedForm onSubmit={handleOnSubmit}>
                    <Input
                        type="email"
                        name="email"
                        placeholder="email"
                        value={email}
                        onChange={handleOnChange}
                        onBlur={handleOnblur}
                        error={emailError}
                        isTouched={isEmailTouched}
                        disabled={isSuccess}
                        isSuccess={isSuccess}
                        successIcon={<TouchedSvyIcon icon="mail-check-line" />}
                    />
                    {isSuccess && <SuccessCaption text={'email_sent'} />}
                </MargedForm>
                {!isSuccess && (
                    <Button disabled={isDisabled} isLoading={isLoading} onClick={handleSendEmail}>
                        {buttonText.send}
                    </Button>
                )}
                {isSuccess && (
                    <>
                        <Button onClick={handleOnHide}>{buttonText.close}</Button>
                    </>
                )}
            </ModalContainer>
        </Modal>
    );
};

export default EmailReceiptModal;
