import { useEffect } from 'react';
import { Modal } from 'components';
import { usePaygreenRequireActionModalVM } from './usePaygreenRequireActionModalVM';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    paymentUrl: string;
};

const PaygreenRequireActionModal: React.FunctionComponent<Props> = ({
    isOpen,
    onHide,
    paymentUrl,
}) => {
    const { handleOnHide, handlePaymentFailed, handlePaymentSuccess } =
        usePaygreenRequireActionModalVM(onHide);

    useEffect(() => {
        window.addEventListener('message', (e: MessageEvent) => {
            if (e.data.id === 'close-modal') {
                handlePaymentFailed();
            } else if (e.data.id === 'paygreen-insite') {
                handlePaymentSuccess();
            }
        });
    }, [handlePaymentFailed, handlePaymentSuccess]);

    return (
        <Modal isOpen={isOpen} onHide={handleOnHide}>
            <iframe width={'100%'} height={'600px'} src={paymentUrl} />
        </Modal>
    );
};

export default PaygreenRequireActionModal;
