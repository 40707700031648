type ComputeTitlePayload = {
    name: string;
    unitPrice: number;
    quantity: number;
    currency: string;
    hasAction: boolean;
    isComposite: boolean;
};

export const computeTitle = ({ name, quantity, hasAction }: ComputeTitlePayload): string => {
    if (hasAction) {
        return `${name}`;
    }

    return `${quantity}x ${name}`;
};
