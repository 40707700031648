import { Button, Modal, Text } from 'components';
import { CartItemDisplayerProps } from 'components/CartItemDisplayer/CartItemDisplayer';
import { useTranslation } from 'react-i18next';
import { ModalContainer, ModalContent } from './OrderNotificationModal.style';
import { useOrderNotificationModalVM } from './useOrderNotificationModalVM';

export type OrderNotificationModalProps = {
    restaurantOrders: CartItemDisplayerProps['restaurantOrders'];
};

const OrderNotificationModal: React.FunctionComponent<OrderNotificationModalProps> = ({
    restaurantOrders,
}) => {
    const { t } = useTranslation();
    const { ordersToNotify, showOrderNotificationModal, handleAcknowledge } =
        useOrderNotificationModalVM(restaurantOrders);

    return (
        <Modal headerTitle={'modal_order_notification.title'} isOpen={showOrderNotificationModal}>
            <ModalContainer>
                <ModalContent>
                    {ordersToNotify.map(({ restaurantName }) => (
                        <Text
                            key={restaurantName}
                            text={'modal_order_notification.order_ready'}
                            values={{ restaurantName }}
                            type={3}
                        />
                    ))}
                </ModalContent>
                <Button onClick={handleAcknowledge} variant="text">
                    {t('modal_order_notification.i_understand')}
                </Button>
            </ModalContainer>
        </Modal>
    );
};

export default OrderNotificationModal;
