import React from 'react';
import { BadgeStatusType } from 'pages/Order/OrderFollowUp/OrderFollowUp.utils';
import { TranslationKey } from 'locales/fr';
import { useTranslation } from 'react-i18next';
import {
    OrdersAccordionContent,
    OrdersAccordionHeader,
    OrdersAccordionItem,
    OrdersAccordionRoot,
    OrdersAccordionTrigger,
    ArrowIndicator,
    FlexContainer,
    OrderNumber,
    SpaceBetweenContainer,
    SpacingContainer,
    StyledBadge,
    StyledIcon,
    StyledImg,
    OrdersAccordionTitle,
} from './OrdersAccordion.style';

export type OrdersAccordionItem = {
    imageUrl?: string;
    title?: string;
    orderNumber: string;
    badgeType?: BadgeStatusType | undefined;
    badgeText?: TranslationKey;
    content?: React.ReactNode;
    onHeaderClick?: () => void;
};

export type OrdersAccordionProps = {
    items: OrdersAccordionItem[];
};

export const OrdersAccordion: React.FunctionComponent<OrdersAccordionProps> = ({ items }) => {
    const { t } = useTranslation();

    return (
        <OrdersAccordionRoot type="single" collapsible data-testid="orders-accordion-root">
            {items &&
                items.map(
                    (
                        {
                            imageUrl,
                            title,
                            orderNumber,
                            badgeType,
                            badgeText,
                            content,
                            onHeaderClick,
                        }: OrdersAccordionItem,
                        index: number,
                    ) => {
                        return (
                            <OrdersAccordionItem
                                value={`orders-accordion-item-${index}`}
                                key={`orders-accordion-item-${index}`}
                            >
                                <OrdersAccordionHeader>
                                    <SpaceBetweenContainer
                                        data-testid="orders-accordion-header-action"
                                        onClick={onHeaderClick}
                                    >
                                        <FlexContainer>
                                            {imageUrl && (
                                                <StyledImg src={imageUrl} role="presentation" />
                                            )}
                                            <FlexContainer>
                                                {title && (
                                                    <OrdersAccordionTitle
                                                        text={title}
                                                        noTranslation={true}
                                                    />
                                                )}
                                                {orderNumber && (
                                                    <OrderNumber>
                                                        {t('order_follow_up.order_number', {
                                                            orderNumber,
                                                        })}
                                                    </OrderNumber>
                                                )}
                                            </FlexContainer>
                                        </FlexContainer>
                                        {badgeText && badgeType && (
                                            <StyledBadge color={badgeType} label={t(badgeText)} />
                                        )}
                                    </SpaceBetweenContainer>
                                    <OrdersAccordionTrigger data-testid="orders-accordion-trigger">
                                        <ArrowIndicator>
                                            <StyledIcon icon="arrow-down-s-line" />
                                        </ArrowIndicator>
                                    </OrdersAccordionTrigger>
                                </OrdersAccordionHeader>
                                <OrdersAccordionContent>
                                    {content && <SpacingContainer>{content}</SpacingContainer>}
                                </OrdersAccordionContent>
                            </OrdersAccordionItem>
                        );
                    },
                )}
        </OrdersAccordionRoot>
    );
};
