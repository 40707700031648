import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from 'locales/fr';
import { computePrice } from 'utils/price';
import { TextFontColors, Typography } from 'components/Text/TextStyle/TextStyle';
import { SAVORY_THEME } from '@innovorder/savory';
import { hexadecimalLowOpacity } from 'components/SelectionSummary/SelectionSummary.style';
import { computeTitleTypography } from './Title.utils';

const TitleStyle = styled.h2<{
    typography: Typography;
    isUnderlined: boolean;
    color?: TextFontColors;
}>`
    ${({ typography }) => SAVORY_THEME.typography[typography]};
    ${({ theme, isUnderlined }) =>
        isUnderlined &&
        `
        width: fit-content;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-position: bottom;
        background-size: 100% 40%;
        background-repeat: no-repeat;
        background-image: linear-gradient(
        90deg,
        ${theme.feature.primaryColor}${hexadecimalLowOpacity},
        ${theme.feature.primaryColor}${hexadecimalLowOpacity}
    );`};
    ${({ theme, color }) => {
        if (!color) return '';
        if (color === 'inherit') return `color: ${color}`;
        return `color: ${theme.feature[color]}`;
    }};
`;

export type TitleFontSize = 1 | 2 | 3 | 4 | 5;

type TitleProps = {
    values?: Record<string, string | number | Date>;
    type: TitleFontSize;
    isUnderlined?: boolean;
    color?: TextFontColors;
};

type Props = TitleProps &
    (
        | {
              noTranslation?: false;
              isCurrency?: false;
              currency?: string;
              text: TranslationKey;
          }
        | {
              noTranslation: true;
              isCurrency?: false;
              currency?: string;
              text: string;
          }
        | {
              noTranslation?: false;
              isCurrency: true;
              currency: string;
              text: number;
          }
    );

export const Title: React.FunctionComponent<Props> = ({
    text,
    values,
    type,
    noTranslation = false,
    isCurrency,
    currency,
    isUnderlined = false,
    ...otherProps
}) => {
    const { t, i18n } = useTranslation();
    const typography = computeTitleTypography(type);
    return (
        <TitleStyle {...otherProps} typography={typography} isUnderlined={isUnderlined}>
            {noTranslation && text}
            {isCurrency && currency && computePrice(i18n, text, currency)}
            {!noTranslation && !isCurrency && t(text as TranslationKey, values)}
        </TitleStyle>
    );
};
