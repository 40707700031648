import React from 'react';
import { Title, Button, BottomBar } from 'components';
import { useTranslation } from 'react-i18next';
import ProductErrorModal from 'components/ProductErrorModal';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { CartEmptySubtitle, EmptyCartContainer } from './EmptyCart.style';

export type CartProps = {
    navigate: () => void;
};

const EmptyCart: React.FunctionComponent<CartProps> = ({ navigate }) => {
    const { t } = useTranslation();

    return (
        <>
            <EmptyCartContainer>
                <SVGIcon icon={SVGIcons.EMPTY} />
                <Title text={'cart.empty'} type={4} />
                <CartEmptySubtitle text={'cart.empty_subtitle'} />
            </EmptyCartContainer>

            <BottomBar withPadding>
                <Button fullWidth onClick={navigate} size="l">
                    {t('cart.empty_cta')}
                </Button>
            </BottomBar>
            <ProductErrorModal />
        </>
    );
};

export default EmptyCart;
