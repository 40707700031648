import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getBrand, getFoodCourtPictureMap } from 'redux/brand';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { CartItem } from 'components';
import { useGetOrders } from 'hooks/useGetOrders';
import { useRedirectToHome } from 'hooks/useRedirectHome/useRedirectHome';
import { useTranslation } from 'react-i18next';
import { getCurrentOrderId, getIsDisconnected, updateAppState } from 'redux/app';
import { getUserName } from 'redux/user/user.slice';
import { getStatuses, updateOrdersStatus } from 'redux/ordersStatus/ordersStatus.slice';
import {
    getOrderStatusAcknowledged,
    updateOrdersStatusAcknowledged,
} from 'redux/ordersStatusAcknowledged/ordersStatusAcknowledged.slice';
import { RestaurantOrder } from 'services/multi-order/multi-order.type';
import { OrderMainStatus, PriceBreakdown } from 'services/order/order.type';
import {
    computeRestaurantNameWithDailyOrderNumber,
    computeRestaurantOrdersForConfirmation,
} from 'utils/order';
import { v4 as uuidv4 } from 'uuid';
import { useCreateOrder } from 'hooks/useCreateOrder';
import { OrderStatusModalProps } from '../OrderStatusModal/OrderStatusModal';
import { useOrderVM } from '../useOrderVM';
import {
    computeOrdersStatusInformation,
    isMultiOrderAlreadyProcessed,
    isOrderOlderThan24hours,
} from './OrderFollowUp.utils';

export type ShowOrderStatusModalPayload = {
    orderId: number;
    restaurantName: string;
    dailyOrderNumber?: number;
};

export const useOrderFollowUpVM = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const brand = useAppSelector(getBrand);
    const stateCurrentOrderId = useAppSelector(getCurrentOrderId);
    const isDisconnected = useAppSelector(getIsDisconnected);
    const userName = useAppSelector(getUserName);

    const { orderUuid } = useParams();
    const { hasStripeRequiredAction } = useOrderVM();
    const { resetIdempotencyKeyAndNavigateToOrderError } = useCreateOrder();
    const [areStatusesAlreadyFetched, setAreStatusesAlreadyFetched] = useState(false);
    const [dailyOrderIds, setDailyOrderIds] = useState<(string | undefined)[] | undefined>();

    const foodCourtPictureMap = useAppSelector(getFoodCourtPictureMap);
    const ordersStatus = useAppSelector(getStatuses);
    const ordersStatusAcknowledged = useAppSelector(getOrderStatusAcknowledged);

    const { redirectToHome } = useRedirectToHome();

    const [isExplanationsPageOpen, setIsExplanationsPageOpen] = useState(false);
    const [isExplanationsPageAlreadyOpened, setIsExplanationsPageAlreadyOpened] = useState(false);

    const [orderStatusModalPayload, setOrderStatusModalPayload] =
        useState<OrderStatusModalProps['payload']>(null);

    const closeExplanationsPage = () => {
        setIsExplanationsPageOpen(false);
        setIsExplanationsPageAlreadyOpened(true);
    };

    const handleShowOrderStatusModal = useCallback(
        ({ orderId, restaurantName, dailyOrderNumber }: ShowOrderStatusModalPayload) => {
            if (!ordersStatus) return;
            if (!ordersStatus[orderId]) return;
            setOrderStatusModalPayload({
                restaurantName: computeRestaurantNameWithDailyOrderNumber(
                    restaurantName,
                    dailyOrderNumber,
                ),
                status: ordersStatus[orderId].status,
            });
        },
        [ordersStatus],
    );

    const handleHideOrderStatusModal = () => {
        setOrderStatusModalPayload(null);
    };

    const setCurrentOrderId = useCallback(
        (currentOrderId: string | null) => dispatch(updateAppState({ currentOrderId })),
        [dispatch],
    );

    const openNewTabToLocateKiosks = () =>
        foodCourtPictureMap && window.open(foodCourtPictureMap, '_blank');

    const getOrdersResult = useGetOrders(orderUuid ?? '');
    const { data, isLoading, isMultiOrder } = getOrdersResult;

    const restaurantOrders = computeRestaurantOrdersForConfirmation(getOrdersResult).map(
        (restaurantOrder) => {
            return {
                ...restaurantOrder,
                imageUrl: brand?.restaurants.find(
                    (restaurant) => restaurant.restaurantId === restaurantOrder.restaurantId,
                )?.imageUrl,
            };
        },
    );

    const date = new Intl.DateTimeFormat('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
    }).format(data ? new Date(isMultiOrder ? data.createdAt : data.startDate) : new Date());

    useEffect(() => {
        const TIME_BEFORE_RELOADING = 5000;

        const timer = setTimeout(() => {
            if (isDisconnected) {
                window.location.reload();
            }
        }, TIME_BEFORE_RELOADING);

        return () => clearTimeout(timer);
    }, [isDisconnected]);

    /**
     * Solo order follow up
     */
    useEffect(() => {
        if (
            !hasStripeRequiredAction &&
            !areStatusesAlreadyFetched &&
            !isMultiOrder &&
            data &&
            orderUuid
        ) {
            if (
                isOrderOlderThan24hours(data.startDate) ||
                [
                    OrderMainStatus.ORDER_PROCESSED,
                    OrderMainStatus.ORDER_CANCELLED,
                    OrderMainStatus.ORDER_REFUSED,
                ].includes(data.mainStatus)
            ) {
                setCurrentOrderId(null);
            } else if (data.mainStatus === OrderMainStatus.ORDER_COULD_NOT_BE_PROCESSED) {
                setCurrentOrderId(null);
                resetIdempotencyKeyAndNavigateToOrderError();
            } else {
                setIsExplanationsPageOpen(true);
                setCurrentOrderId(orderUuid);

                if (!ordersStatus) {
                    const status = [
                        OrderMainStatus.ORDER_CANCELLED,
                        OrderMainStatus.ORDER_REFUSED,
                    ].includes(data.mainStatus)
                        ? 'CANCELLED'
                        : 'LOADING';

                    const initOrdersStatus = {
                        [data.omnichannelOrderId]: {
                            orderId: data.omnichannelOrderId,
                            restaurantId: data.restaurantId.toString(),
                            status,
                        },
                    };
                    dispatch(updateOrdersStatus(initOrdersStatus));

                    const initOrdersStatusAcknowledged = {
                        [data.omnichannelOrderId]:
                            !!ordersStatusAcknowledged?.[data.omnichannelOrderId],
                    };
                    dispatch(updateOrdersStatusAcknowledged(initOrdersStatusAcknowledged));

                    setAreStatusesAlreadyFetched(true);
                }
            }
        }
    }, [
        hasStripeRequiredAction,
        isMultiOrder,
        data,
        dispatch,
        setCurrentOrderId,
        orderUuid,
        ordersStatus,
        ordersStatusAcknowledged,
        areStatusesAlreadyFetched,
        resetIdempotencyKeyAndNavigateToOrderError,
    ]);

    /**
     * Multi order follow up
     */
    useEffect(() => {
        if (
            !hasStripeRequiredAction &&
            !areStatusesAlreadyFetched &&
            isMultiOrder &&
            data &&
            orderUuid
        ) {
            if (isOrderOlderThan24hours(data.createdAt) || isMultiOrderAlreadyProcessed(data)) {
                setCurrentOrderId(null);
            } else if (data.status === 'ERROR') {
                setCurrentOrderId(null);
                resetIdempotencyKeyAndNavigateToOrderError();
            } else {
                setIsExplanationsPageOpen(true);
                setCurrentOrderId(orderUuid);

                if (!ordersStatus) {
                    const initOrdersStatus = Object.fromEntries(
                        data.orders.map(({ orderId, restaurantId, mainStatus }) => {
                            let status = 'LOADING';

                            if (
                                [
                                    OrderMainStatus.ORDER_CANCELLED,
                                    OrderMainStatus.ORDER_REFUSED,
                                ].includes(mainStatus)
                            ) {
                                status = 'CANCELLED';
                            }

                            return [
                                orderId.toString(),
                                {
                                    orderId: orderId.toString(),
                                    restaurantId: restaurantId.toString(),
                                    status,
                                },
                            ];
                        }),
                    );
                    dispatch(updateOrdersStatus(initOrdersStatus));

                    const initOrdersStatusAcknowledged = Object.fromEntries(
                        data.orders.map(({ orderId }) => [
                            orderId.toString(),
                            !!ordersStatusAcknowledged?.[orderId],
                        ]),
                    );
                    dispatch(updateOrdersStatusAcknowledged(initOrdersStatusAcknowledged));

                    setAreStatusesAlreadyFetched(true);
                }
            }
        }
    }, [
        hasStripeRequiredAction,
        isMultiOrder,
        data,
        dispatch,
        setCurrentOrderId,
        orderUuid,
        ordersStatus,
        ordersStatusAcknowledged,
        areStatusesAlreadyFetched,
        resetIdempotencyKeyAndNavigateToOrderError,
    ]);

    const ordersStatusInformation = computeOrdersStatusInformation(restaurantOrders, ordersStatus);

    useEffect(() => {
        if (
            !restaurantOrders ||
            !restaurantOrders.length ||
            (dailyOrderIds && dailyOrderIds.length)
        )
            return;

        setDailyOrderIds([
            ...restaurantOrders
                .filter(
                    (order: RestaurantOrder) =>
                        order.dailyOrderNumber === 0 || !!order.dailyOrderNumber,
                )
                .map((order: RestaurantOrder) => order.dailyOrderNumber!.toString()),
        ]);
    }, [restaurantOrders, dailyOrderIds]);

    const currency = isMultiOrder ? data?.orders[0].currency : data?.currency;

    const accordionItems =
        restaurantOrders && ordersStatusInformation && data && currency
            ? [
                  ...restaurantOrders.map((restaurantOrder) => ({
                      imageUrl: restaurantOrder.imageUrl ?? '',
                      title: restaurantOrder.restaurantName,
                      orderNumber:
                          typeof restaurantOrder.dailyOrderNumber === 'number'
                              ? restaurantOrder.dailyOrderNumber.toString()
                              : '',
                      badgeType: ordersStatusInformation?.[restaurantOrder.orderId!]?.badgeType,
                      badgeText: ordersStatusInformation?.[restaurantOrder.orderId!]?.text,
                      onHeaderClick: () =>
                          handleShowOrderStatusModal({
                              orderId: restaurantOrder.orderId!,
                              restaurantName: restaurantOrder.restaurantName!,
                              dailyOrderNumber: restaurantOrder.dailyOrderNumber,
                          }),
                      content: restaurantOrder!.cartProducts.map((product) => (
                          <CartItem
                              key={uuidv4()}
                              {...product}
                              isLoading={isLoading}
                              currency={currency}
                              noBlurry={true}
                          />
                      )),
                  })),
              ]
            : undefined;

    const priceBreakdown = isMultiOrder
        ? data?.totalPrice
        : ({
              totalExclTax: data?.totalPriceWithTaxExcluded,
              totalInclTaxWithDiscount: data?.totalPriceDiscountedWithTaxIncluded,
              totalTax:
                  (data?.totalPriceWithTaxIncluded ?? 0) - (data?.totalPriceWithTaxExcluded ?? 0),
          } as PriceBreakdown);

    return {
        buttonText: {
            locateKiosk: t('locate_kiosks'),
            receipt: t('get_my_receipt_by_email'),
        },
        totalPriceBreakdown: priceBreakdown,
        currency,
        discounts: isMultiOrder ? [] : data?.discounts,
        entranceFees: isMultiOrder ? [] : data?.entranceFees,
        grants: isMultiOrder ? [] : data?.grants,
        multiOrderCreatedAt: date,
        isDisconnected,
        restaurantOrders,
        accordionItems,
        dailyOrderIds,
        foodCourtPictureMap,
        openNewTabToLocateKiosks,
        ordersStatus,
        ordersStatusInformation,
        orderStatusModalPayload,
        handleHideOrderStatusModal,
        handleShowOrderStatusModal,
        disableHeaderClick: !!stateCurrentOrderId,
        redirectToHome,
        isExplanationsPageOpen: isExplanationsPageOpen && !isExplanationsPageAlreadyOpened,
        closeExplanationsPage,
        userName: userName ?? '',
        isLoading,
    };
};
