import { PaymentIntentResult } from '@stripe/stripe-js';
import { StripeIntentRequiredAction } from 'services/order/order.type';

export const isStripeIntentRequiredAction = (
    requireAction: StripeIntentRequiredAction | null,
): boolean => {
    if (!requireAction) {
        return false;
    }
    return requireAction?.requires_action;
};

export const isStripeIntentSuccess = (paymentIntentResult: PaymentIntentResult) =>
    Boolean(!paymentIntentResult.error);

export const isStripeIntentError = (paymentIntentResult: PaymentIntentResult) =>
    Boolean(paymentIntentResult.error);
