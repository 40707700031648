import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    CrossSellingProduct,
    HeaderTitle,
    PageWithBottomBarContainer,
    Title,
    WithHeader,
    BottomBar,
} from 'components';

import { useCrossSellingVM } from './useCrossSellingVM';
import { GridContainer, GrowingContainer } from './CrossSelling.style';
import CrossSellingSkeleton from './CrossSellingSkeleton';

const CrossSelling: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        data,
        formattedProducts,
        handleSelectProduct,
        handleClickOnNext,
        isLoading,
        isButtonLoading,
        title,
        goCart,
    } = useCrossSellingVM();

    return (
        <WithHeader isSticky={true} onClickBack={goCart}>
            <PageWithBottomBarContainer>
                <GrowingContainer>
                    {isLoading && <CrossSellingSkeleton />}
                    {!isLoading && data && (
                        <>
                            <HeaderTitle>
                                <Title text={title} type={4} noTranslation isUnderlined={true} />
                            </HeaderTitle>

                            <GridContainer data-testid="cross-selling-grid">
                                {formattedProducts &&
                                    formattedProducts.length > 0 &&
                                    formattedProducts.map(
                                        ({
                                            productId,
                                            restaurantId,
                                            menuId,
                                            image,
                                            name,
                                            price,
                                            isSelected,
                                        }) => {
                                            return (
                                                image && (
                                                    <CrossSellingProduct
                                                        key={productId}
                                                        image={image}
                                                        name={name}
                                                        price={price}
                                                        isSelected={isSelected}
                                                        onClick={() =>
                                                            handleSelectProduct({
                                                                productId,
                                                                restaurantId,
                                                                menuId,
                                                            })
                                                        }
                                                    />
                                                )
                                            );
                                        },
                                    )}
                            </GridContainer>
                        </>
                    )}
                </GrowingContainer>

                <BottomBar withPadding>
                    <Button
                        fullWidth
                        onClick={handleClickOnNext}
                        size="m"
                        isLoading={isButtonLoading}
                    >
                        {t('button_next')}
                    </Button>
                </BottomBar>
            </PageWithBottomBarContainer>
        </WithHeader>
    );
};

export default CrossSelling;
