import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.feature.backgroundColor};
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
