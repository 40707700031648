import { EdenredPaymentCheckout } from './payment_checkout';
import { EdenredPaymentServiceClient } from './payment_service.client';
import { EdenredCheckoutConfiguration } from './types';

export class EdenredPaymentCheckoutBuilder {
    private checkoutConfiguration?: EdenredCheckoutConfiguration;

    public configureCheckout(checkoutConfiguration: EdenredCheckoutConfiguration): EdenredPaymentCheckoutBuilder {
        this.checkoutConfiguration = checkoutConfiguration;
        return this;
    }

    public build() {
        if (this.checkoutConfiguration === undefined) {
            throw new Error('Checkout has not been configured');
        }

        const paymentServiceClient = new EdenredPaymentServiceClient(this.checkoutConfiguration);

        const checkout = new EdenredPaymentCheckout(paymentServiceClient, this.checkoutConfiguration);

        return checkout;
    }
}
