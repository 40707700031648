import React, { PropsWithChildren } from 'react';
import { Input } from './Input';
import { InputContainer } from './Input.style';

export type Props = PropsWithChildren<{
    error?: string;
    disabled?: boolean;
    isTouched?: boolean;
    isFocused?: boolean;
}>;

export const InputWrapper: React.FunctionComponent<Props> = ({
    children,
    error,
    disabled,
    isTouched,
    isFocused,
}) => {
    return (
        <InputContainer>
            <Input
                error={error}
                disabled={disabled}
                isTouched={isTouched}
                isFocused={isFocused}
                customInput={<InputContainer>{children}</InputContainer>}
            />
        </InputContainer>
    );
};
