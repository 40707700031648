import styled from '@emotion/styled';
import { BackButton } from 'components';

export const CartItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[2]} 0;
    max-width: 100%;
`;

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ProductImage = styled.div`
    height: 45px;
    min-width: 45px;
    border-radius: ${({ theme }) => theme.base.radius.default};
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ProductSteps = styled.ul<{ blurry?: boolean }>`
    display: list-item;
    transition: max-height ease 0.5s;
    list-style-type: none;

    mask-image: linear-gradient(0deg, transparent 0px, red 0px);
    -webkit-mask-image: linear-gradient(0deg, transparent 0px, red 0px);
    max-height: 350px;

    ${({ blurry }) =>
        blurry &&
        `max-height: 70px; 
        mask-image: linear-gradient(0deg, transparent 0px, red 50px); 
        -webkit-mask-image: linear-gradient(0deg, transparent 0px, red 50px);
    `}
`;

export const ProductStep = styled.li`
    list-style: inside;
    margin-left: 10px;
    opacity: 0.6;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &::marker {
        font-size: 15px;
    }
`;

export const Toggle = styled(BackButton)<{ rotateDown?: boolean }>`
    margin-top: 15px;
    padding: 10px;

    height: 32px !important;
    width: 32px !important;
    border-radius: 24px !important;
    transform: rotate(90deg) !important;

    ${({ rotateDown }) => rotateDown && `transform: rotate(-90deg) !important;`}
`;

export const QuantityPickerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;
