"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEE_TYPE = exports.DiscountFilterType = exports.DiscountApplyMethod = exports.DISCOUNTABLE_TYPE = exports.DISCOUNT_TYPE = exports.ConsumptionModeType = exports.APPLY_METHOD = void 0;
var discount_types_1 = require("./discount.types");
Object.defineProperty(exports, "APPLY_METHOD", { enumerable: true, get: function () { return discount_types_1.APPLY_METHOD; } });
Object.defineProperty(exports, "DISCOUNT_TYPE", { enumerable: true, get: function () { return discount_types_1.DISCOUNT_TYPE; } });
Object.defineProperty(exports, "DISCOUNTABLE_TYPE", { enumerable: true, get: function () { return discount_types_1.DISCOUNTABLE_TYPE; } });
Object.defineProperty(exports, "DiscountApplyMethod", { enumerable: true, get: function () { return discount_types_1.DiscountApplyMethod; } });
Object.defineProperty(exports, "DiscountFilterType", { enumerable: true, get: function () { return discount_types_1.DiscountFilterType; } });
var order_types_1 = require("./order.types");
Object.defineProperty(exports, "ConsumptionModeType", { enumerable: true, get: function () { return order_types_1.ConsumptionModeType; } });
var order_detail_types_1 = require("./order-detail.types");
Object.defineProperty(exports, "FEE_TYPE", { enumerable: true, get: function () { return order_detail_types_1.FEE_TYPE; } });
