import { WeborderingRestaurant } from 'services/brand/brand.type';
import { moduleOrderingFlowFixtureFactory } from '../weborderingConfiguration';

export {
    orderRecapFixtureFactory,
    priceBreakdownFixtureFactory,
} from '@innovorder/order_detail/dist/fixture_factory/order_recap.fixture_factory';

export const weborderingRestaurantFixtureFactory = (
    params: Partial<WeborderingRestaurant> = {},
): WeborderingRestaurant => {
    const defaultValues: WeborderingRestaurant = {
        restaurantId: 1,
        name: 'restaurant',
        imageUrl:
            'https://static-dev.innovorder.fr/uploads/2f250927455d/c42c0dd5ba3c9da0740f6db0d1a752f3.jpg',
        moduleOrderingSchedules: [],
        module_ordering_flows: [moduleOrderingFlowFixtureFactory()],
        menus: [{ menuId: 1 }],
        restaurantTags: [{ value: 'Asiatique' }],
        brandRestaurantFamilies: [],
    };

    return { ...defaultValues, ...params };
};
