import styled from '@emotion/styled';
import { SvyIcon } from '@innovorder/savory';

export const Container = styled.div`
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }): string => theme.base.colors.secondary[900]};
    opacity: 0.8;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 5;
    width: 90%;
    background-color: ${({ theme }) => theme.base.colors.secondary[50]};
    border-radius: ${({ theme }) => theme.base.radius.default};
`;

export const ContentHeader = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.base.spacing[1]};
    border-top-left-radius: ${({ theme }) => theme.base.radius.default};
    border-top-right-radius: ${({ theme }) => theme.base.radius.default};
    padding: ${({ theme }) => theme.base.spacing[2]};
    justify-content: space-between;
`;

export const HeaderAndIconContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.base.spacing[1]};
`;

export const ContentBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    padding: ${({ theme }) => theme.base.spacing[2]};
    padding-top: 0;
`;

export const ContentChildren = styled.div<{ withMargin?: boolean }>`
    flex: 1;
    width: 100%;
    margin-bottom: ${({ theme, withMargin }) => (withMargin ? theme.base.spacing[2] : 0)};
`;

export const StyledIcon = styled(SvyIcon)`
    &:before {
        font-size: 28px;
    }
`;
