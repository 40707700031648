"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeOriginalUnitPrice = void 0;
var computeOriginalUnitPrice = function (product) {
    if (!product.additionalData || !product.quantity) {
        return product.unitPriceRounded.totalInclTax;
    }
    var additionalData = product.additionalData;
    if (!(additionalData === null || additionalData === void 0 ? void 0 : additionalData.amountWithoutUpsell)) {
        return product.unitPriceRounded.totalInclTax;
    }
    var amountWithoutUpsell = parseInt(additionalData.amountWithoutUpsell, 10);
    if (Number.isNaN(amountWithoutUpsell)) {
        return product.unitPriceRounded.totalInclTax;
    }
    return (product.totalPriceRounded.totalInclTax - amountWithoutUpsell) / product.quantity;
};
exports.computeOriginalUnitPrice = computeOriginalUnitPrice;
