import styled from '@emotion/styled';
import { Text } from 'components';
import { motion } from 'framer-motion';
import { QuantityPickerProps } from './QuantityPicker';

export const PRIMARY_WIDTH = '124px';
export const PRIMARY_HEIGHT = '40px';

export const SECONDARY_WIDTH = '88px';
export const SECONDARY_HEIGHT = '32px';

export const Container = styled(motion.div)<Partial<QuantityPickerProps>>`
    display: flex;
    height: ${({ variant }) => (variant === 'primary' ? PRIMARY_HEIGHT : SECONDARY_HEIGHT)};
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme, variant }) =>
        variant === 'primary' ? theme.base.colors.secondary[50] : theme.base.colors.secondary[100]};
    border: ${({ theme, variant }) =>
        variant === 'primary' ? `solid 1px ${theme.base.colors.primary[500]}` : 'none'};
    border-radius: ${({ theme }) => theme.base.radius.default};
    opacity: ${({ disabled, isLoading }) => (disabled || isLoading ? 0.5 : 1)};
`;

export const QuantityPickerCountContainer = styled(Text)<Partial<QuantityPickerProps>>`
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ theme, variant }) =>
        variant === 'primary'
            ? theme.base.typography.Title5
            : theme.base.typography['Caption/Bold/Caption1']}

    color: ${({ theme, disabled }) =>
        disabled ? theme.base.colors.secondary[300] : theme.base.colors.secondary[900]};
    border-left: ${({ theme, variant }) =>
        variant === 'primary' ? `solid 1px ${theme.base.colors.primary[500]}` : 'none'};
    border-right: ${({ theme, variant }) =>
        variant === 'primary' ? `solid 1px ${theme.base.colors.primary[500]}` : 'none'};
`;

export const QuantityPickerModifierContainer = styled.div<Partial<QuantityPickerProps>>`
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme, disabled, variant }) => {
        if (variant === 'primary') {
            return disabled ? theme.base.colors.primary[200] : theme.base.colors.primary[500];
        }

        return disabled ? theme.base.colors.secondary[300] : theme.base.colors.secondary[900];
    }};

    font-size: ${({ variant }) => (variant === 'primary' ? '24px' : '16px')};
    font-weight: 700;

    &:active {
        span {
            font-size: ${({ variant }) => (variant === 'primary' ? '22px' : '14px')};
        }

        transition: font 0.3s ease;
    }
`;

export const QuantityPickerLoaderContainer = styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
`;
