import { innovorderApi } from '../index';
import { ApiResponse } from '../providers/innovorder.types';
import {
    WeborderingConfiguration,
    GetWeborderingConfigurationPayload,
    WeborderingRestaurants,
    GetWeborderingRestaurantsPayload,
    RestaurantFamilies,
} from './brand.type';

const brandApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getWeborderingConfiguration: build.query<
            WeborderingConfiguration,
            GetWeborderingConfigurationPayload
        >({
            query: ({ brandHash }) => ({
                url: `/brands/webordering-configuration/${brandHash}`,
                method: 'GET',
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<WeborderingConfiguration>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getAllWeborderingRestaurants: build.query<
            WeborderingRestaurants,
            GetWeborderingRestaurantsPayload
        >({
            query: ({ brandHash }) => ({
                url: `/brand/${brandHash}/restaurants`,
                method: 'GET',
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<WeborderingRestaurants>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getAllRestaurantFamilies: build.query<RestaurantFamilies, GetWeborderingRestaurantsPayload>(
            {
                query: ({ brandHash }) => ({
                    url: `/brand/${brandHash}/restaurants_families`,
                    method: 'GET',
                }),
                providesTags: [],
                transformResponse: (response: ApiResponse<RestaurantFamilies>) => response.data,
                extraOptions: { maxRetries: 3 },
            },
        ),
    }),
});

export const {
    useGetWeborderingConfigurationQuery,
    useGetAllWeborderingRestaurantsQuery,
    useGetAllRestaurantFamiliesQuery,
} = brandApi;
