import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { FeaturesTheme } from 'core/styled';
import { SAVORY_THEME } from '@innovorder/savory';
import { computeTypography } from './TextStyle.utils';

const StyleText = styled.span<{
    typography: Typography;
    color?: TextFontColors;
    opacity?: number;
    isTruncated?: boolean;
    underlined?: boolean;
}>`
    ${({ typography }) => SAVORY_THEME.typography[typography]}
    text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
    color: ${({ theme, color }) => {
        if (!color) return theme.feature.primaryTextColor;
        if (color === 'inherit') return color;
        return theme.feature[color];
    }};
    opacity: ${({ opacity }) => opacity || 1};
    ${({ isTruncated }) =>
        isTruncated &&
        `
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        `}
`;

export type TextFontWeights = 'Regular' | 'Bold';
export type TextFontSizes = 1 | 2 | 3;
export type TextFontColors = keyof FeaturesTheme | 'inherit';
export type Typography = keyof typeof SAVORY_THEME.typography;

export type TextStyleprops = {
    weight?: TextFontWeights;
    type?: TextFontSizes;
    color?: TextFontColors;
    opacity?: number;
    values?: Record<string, string | number | Date>;
    isTruncated?: boolean;
    underlined?: boolean;
};

export const TextStyle: React.FunctionComponent<PropsWithChildren<TextStyleprops>> = ({
    children,
    type = 1,
    weight = 'Regular',
    color,
    opacity,
    isTruncated,
    ...otherProps
}) => {
    const typography = computeTypography(weight, type);

    return (
        <StyleText {...{ color, opacity, isTruncated, typography }} {...otherProps}>
            {children}
        </StyleText>
    );
};
