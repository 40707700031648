"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTag = exports.ProductLabel = void 0;
var ProductLabel;
(function (ProductLabel) {
    ProductLabel["compensatoryProduct"] = "Produit compensatoire (URSSAF)";
})(ProductLabel = exports.ProductLabel || (exports.ProductLabel = {}));
var ProductTag;
(function (ProductTag) {
    ProductTag["Subventionnable"] = "SUBVENTIONNABLE";
    ProductTag["ExcludedFromUrssaf"] = "EXCLUDED_FROM_URSSAF";
})(ProductTag = exports.ProductTag || (exports.ProductTag = {}));
