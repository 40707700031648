import styled from '@emotion/styled';
import { Text } from 'components/Text';

// Le 1a en hexadécimal, correspond à une opacité de 10%
export const hexadecimalLowOpacity = '1a';

export const SelectionSummaryContainer = styled.div<{ margined: boolean }>`
    background-color: ${({ theme }) => theme.feature.primaryColor}${hexadecimalLowOpacity};
    border-radius: ${({ theme }) => theme.base.radius.default};
    margin-bottom: ${({ theme }) =>
        (props) =>
            props.margined && theme.base.spacing[1]};
`;

export const SelectionSummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[2]};
    gap: ${({ theme }) => theme.base.spacing[2]};
`;

export const ErrorText = styled(Text)`
    color: ${({ theme }) => theme.base.colors.destructive[500]};
    background-color: ${({ theme }) => theme.feature.backgroundColor};
    padding: ${({ theme }) => theme.base.spacing[2]};
    border-radius: ${({ theme }) => theme.base.radius.default};
    border: 1px solid ${({ theme }) => theme.base.colors.destructive[500]};
    padding: ${({ theme }) => theme.base.spacing[1]} ${({ theme }) => theme.base.spacing[2]};
    display: flex;
    justify-content: center;
    align-items: center;
`;
