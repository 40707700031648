import styled from '@emotion/styled';
import { ChipsProps } from './Chips';

export const ChipsContainer = styled.div<ChipsProps>`
    background: ${({ theme }): string => theme.feature.ctaColor};
    color: ${({ theme }): string => theme.feature.ctaTextColor};
    border: none;
    border-radius: ${({ theme }): string => theme.base.radius.s};
    padding: ${({ theme }): string => theme.base.spacing[1]};
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
    min-width: 40px;

    ${({ onClick }) => onClick && 'cursor: pointer'};

    ${({ theme }) => theme.base.typography['Body/Regular/Text3']};

    > *:not(:last-child) {
        margin-right: ${({ theme }): string => theme.base.spacing[1]};
    }

    * > {
        color: ${({ theme }): string => theme.feature.ctaTextColor} !important;
    }
`;
