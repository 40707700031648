import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../providers/innovorder.types';
import { CrossSellingPayload, CrossSellingEnabledWithProducts } from './crossSelling.type';

const crossSellingApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        findCrossSellingEnabledWithProducts: build.mutation<
            CrossSellingEnabledWithProducts[],
            ApiRequest<CrossSellingPayload>
        >({
            query: ({ limit, ...body }) => ({
                url: `/cross-selling/enabled/with-products?limit=${limit}`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: ApiResponse<CrossSellingEnabledWithProducts[]>) =>
                response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useFindCrossSellingEnabledWithProductsMutation } = crossSellingApi;
