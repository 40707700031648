import React from 'react';

import { useTranslation } from 'react-i18next';
import { BackButtonContainer, StyledIcon } from './BackButton.style';

export type BackButtonProps = {
    onClick?: () => void;
};

export const BackButton: React.FunctionComponent<BackButtonProps> = ({
    onClick,
    ...otherProps
}) => {
    const { t } = useTranslation();
    return (
        <BackButtonContainer
            type="button"
            data-testid="back-button"
            aria-label={t('back')}
            onClick={onClick}
            size="s"
            {...otherProps}
        >
            <StyledIcon icon="arrow-left-s-line" />
        </BackButtonContainer>
    );
};
