import React from 'react';
import { HeaderTitle, Title, WithHeader } from 'components';
import {
    Container,
    MargedTitle,
    MargedText,
    TermsAndConditionsContainer,
} from './TermsAndConditions.style';
import { useTermsAndConditionsVM } from './useTermsAndConditionsVM';

const TermsAndConditions: React.FunctionComponent = () => {
    const { brandName, navigateToCardPayment } = useTermsAndConditionsVM();
    return (
        <WithHeader isSticky={true} onClickBack={navigateToCardPayment}>
            <Container>
                <HeaderTitle>
                    <Title text={'terms_and_conditions.title'} type={4} />
                </HeaderTitle>

                <TermsAndConditionsContainer>
                    <MargedTitle
                        text={'terms_and_conditions.price_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.price_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.payment_terms_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.payment_terms_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.securing_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.securing_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.online_order_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.online_order_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.registration_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.registration_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.login_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.login_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.selection_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.selection_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.validation_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.validation_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.signature_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.signature_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                    <MargedTitle
                        text={'terms_and_conditions.delivery_title'}
                        values={{
                            name: brandName,
                        }}
                        type={5}
                    />
                    <MargedText
                        text={'terms_and_conditions.delivery_content'}
                        values={{
                            name: brandName,
                        }}
                    />
                </TermsAndConditionsContainer>
            </Container>
        </WithHeader>
    );
};

export default TermsAndConditions;
