import styled from '@emotion/styled';
import { PageWithBottomBarContainer, Text } from 'components';

export const ColoredPageWithBottomBarContainer = styled(PageWithBottomBarContainer)`
    background-color: #30344a; ;
`;

export const Container = styled.div``;

export const Title = styled.h1`
    ${({ theme }) => theme.base.typography.Title3}
    color: ${({ theme }) => theme.base.colors.secondary[50]};
    text-align: center;
    padding: ${({ theme }) => theme.base.spacing[1]} ${({ theme }) => theme.base.spacing[2]};
    margin-top: ${({ theme }) => theme.base.spacing[3]};
`;

export const Highlighted = styled.span`
    background-image: linear-gradient(
        90deg,
        ${({ theme }) => theme.base.colors.primary[500]},
        ${({ theme }) => theme.base.colors.primary[500]}
    );
    background-position: bottom;
    background-size: 100% 40%;
    background-repeat: no-repeat;
`;

export const List = styled.ol`
    width: fit-content;
    margin: auto;
    padding: ${({ theme }) => theme.base.spacing[3]};
    padding-left: ${({ theme }) => theme.base.spacing[2]};
    padding-bottom: 0;
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    width: fit-content;

    svg {
        flex-shrink: 0;
        width: 150px;
        height: 150px;
    }
`;

export const ColoredText = styled(Text)`
    color: white;
`;
