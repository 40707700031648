import { HeaderLogo } from 'components';
import { HorizontalScrollContainer } from 'components/HorizontalScrollContainer';
import React from 'react';
import {
    Container,
    RestaurantsGridContainer,
    CarouselItemContainer,
    CarouselItemImage,
    CarouselItemText,
    RestaurantsGridImage,
    RestaurantsGridText,
} from './RestaurantsSkeleton.style';
import { HomeLogoContainer, HomeLogo } from '../Restaurants.style';

const RestaurantsSkeleton: React.FunctionComponent = () => {
    return (
        <Container data-testid="restaurants-skeleton">
            <HomeLogoContainer>
                <HomeLogo>
                    <HeaderLogo data-testid="home-logo" />
                </HomeLogo>
            </HomeLogoContainer>

            <HorizontalScrollContainer>
                {new Array(8).fill(1).map((_, index) => (
                    <CarouselItemContainer key={`carousel-item-${index}`}>
                        <CarouselItemImage />
                        <CarouselItemText />
                    </CarouselItemContainer>
                ))}
            </HorizontalScrollContainer>

            <RestaurantsGridContainer>
                {new Array(8).fill(1).map((_, index) => (
                    <div key={`grid-item-${index}`}>
                        <RestaurantsGridImage />
                        <RestaurantsGridText />
                    </div>
                ))}
            </RestaurantsGridContainer>
        </Container>
    );
};

export default RestaurantsSkeleton;
