import {
    getCurrentConsumptionModeType,
    getRestaurantId,
    getRestaurantName,
    getRestaurantTags,
    getTableId,
} from 'redux/app';
import {
    getBrand,
    getBrandCurrency,
    getBrandOptionStatus,
    getMenuId,
    getRushDelay,
} from 'redux/brand';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    getOrdersTotalInclTaxWithDiscount,
    getOrder,
    getOrdersTotalQuantity,
    getOrders,
} from 'redux/orders';
import { computePrice } from 'utils/price';
import { useTranslation } from 'react-i18next';
import { setCurrentCompositeProduct } from 'redux/currentCompositeProduct';
import { getRestaurantScheduleByRestaurantId } from 'redux/restaurantSchedules';
import { useNextOpeningI18n } from 'hooks/useNextOpeningI18n';
import { parseRestaurantId } from 'pages/Home/Home.utils';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useMenuHandler } from 'hooks/useMenuHandler';
import { useRedirectToHome } from 'hooks/useRedirectHome/useRedirectHome';
import { useGetConsumptionModes } from 'hooks/useGetConsumptionModes';
import { useConsumptionModeHandler } from 'hooks/useConsumptionModeHandler/useConsumptionModeHandler';
import { useCustomFlush } from 'hooks/useCustomFlush';
import { ConsumptionModeType } from '@innovorder/order_detail';

export const useMenuHomeVM = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const { goNext } = useWorkflowStateMachine();
    const { redirectToHome } = useRedirectToHome();
    const { flushCart } = useCustomFlush();

    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const restaurantId = parseRestaurantId(useParams().restaurantId) || null;
    const stateRestaurantId = useAppSelector(getRestaurantId);
    const restaurantTags = useAppSelector(getRestaurantTags);
    const restaurantName = useAppSelector(getRestaurantName);
    const currentMenuId = useAppSelector(getMenuId(restaurantId || null));
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const tableId = useAppSelector(getTableId);

    const orders = useAppSelector(getOrders);
    const brand = useAppSelector(getBrand);
    const restaurantIds = useMemo(() => (restaurantId ? [restaurantId] : []), [restaurantId]);
    useGetConsumptionModes(restaurantIds);

    useConsumptionModeHandler();

    const order = useAppSelector(getOrder(restaurantId));
    const stateOrder = useAppSelector(getOrder(stateRestaurantId));
    const currency = useAppSelector(getBrandCurrency);
    const totalQuantity = useAppSelector(getOrdersTotalQuantity);
    const totalInclTaxWithDiscount = useAppSelector(getOrdersTotalInclTaxWithDiscount);
    const rushDelay = useAppSelector(getRushDelay(restaurantId)) || 0;
    const restaurantSchedule = useAppSelector(getRestaurantScheduleByRestaurantId(restaurantId));
    const nextOpeningI18n = useNextOpeningI18n(restaurantSchedule?.nextOpening);

    const [hideRushDelay, setHideRushDelay] = useState(false);
    const shouldDisplayNextOpening = restaurantSchedule?.isCurrentlyOpen === false;
    const shouldDisplayRushDelay =
        !hideRushDelay && !shouldDisplayNextOpening && isFoodCourt && !!rushDelay && rushDelay > 0;
    const shouldDisplayCart = totalQuantity > 0 && !shouldDisplayNextOpening;

    const amountWithCurrency = currency
        ? computePrice(i18n, totalInclTaxWithDiscount, currency)
        : '';
    const buttonText = t('button_show_basket', {
        amount: amountWithCurrency,
    });

    const tableNumber =
        currentConsumptionModeType === ConsumptionModeType.MODE_SIT_IN ? tableId : null;

    const handleClickOnRushDelay = () => {
        setHideRushDelay(true);
    };

    const { isLoading, isError } = useMenuHandler();

    useEffect(() => {
        if (orders && brand) {
            const restaurantsId = brand.restaurants.map((r) => r.restaurantId);
            const hasOrdersOutsideBrand = Object.values(orders).some(
                (o) => !restaurantsId.includes(o.restaurantId),
            );
            if (hasOrdersOutsideBrand) {
                flushCart();
            }

            const menusId = brand.restaurants.map((r) => r.menus[0].menuId);
            const hasOrdersWithOutdatedMenus = Object.values(orders).some(
                (o) => !menusId.includes(o.menuId),
            );
            if (hasOrdersWithOutdatedMenus) {
                flushCart();
            }
        }
    }, [orders, brand, flushCart]);

    useEffect(() => {
        if (
            stateRestaurantId &&
            stateOrder &&
            stateOrder.restaurantId !== restaurantId &&
            isFoodCourt === false
        ) {
            flushCart();
        }
    }, [stateRestaurantId, restaurantId, currentMenuId, isFoodCourt, stateOrder, order, flushCart]);

    useEffect(() => {
        dispatch(setCurrentCompositeProduct(null));
    }, [dispatch]);

    return {
        isError: isError || !currentMenuId,
        isLoading,
        buttonText,
        rushDelay,
        shouldDisplayRushDelay,
        shouldDisplayNextOpening,
        shouldDisplayCart,
        nextOpeningI18n,
        isFoodCourt,
        restaurantName,
        restaurantTags,
        handleClickOnRushDelay,
        goNext,
        redirectToHome,
        tableNumber,
    };
};
