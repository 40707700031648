import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useCallback } from 'react';
import { getIsFoodCourt, updateAppState } from 'redux/app';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const useRedirectToHome = () => {
    const dispatch = useAppDispatch();
    const { reset } = useWorkflowStateMachine();
    const isFoodCourt = useAppSelector(getIsFoodCourt);

    const redirectToHome = useCallback(() => {
        if (isFoodCourt) {
            dispatch(
                updateAppState({
                    restaurantId: null,
                    restaurantName: null,
                    currentMenuId: null,
                }),
            );
        }
        reset();
    }, [isFoodCourt, reset, dispatch]);

    return { redirectToHome };
};
