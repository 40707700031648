import styled from '@emotion/styled';
import { ScrollContainer } from 'components/HorizontalScrollContainer/HorizontalScrollContainer.style';
import Skeleton from 'react-loading-skeleton';

export const Container = styled.div`
    background: ${({ theme }) => theme.feature.backgroundColor};
`;

export const RestaurantTitleSkeleton = styled(Skeleton)`
    height: 36px;
    width: 300px;
`;

export const BadgesContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.base.spacing[1]};
    margin-top: ${({ theme }) => theme.base.spacing[1]};
`;

export const MenuHomeBadgeSkeleton = styled(Skeleton)`
    height: 24px;
    width: 50px;
`;

export const CategoriesSkeleton = styled(ScrollContainer)`
    gap: ${({ theme }) => theme.base.spacing[1]};
`;

export const CategoryItemSkeleton = styled(Skeleton)`
    height: 40px;
    width: 120px;
`;

export const ScrollbarSkeleton = styled(Skeleton)`
    width: 100%;
    height: 3px;
`;

export const CategoryProductContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const CategoryProductContent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.base.spacing[4]};
    gap: ${({ theme }) => theme.base.spacing[4]};
`;

export const CategoryProductText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

export const CategoryTitleSkeleton = styled(Skeleton)`
    height: 28px;
    width: 250px;
`;

export const ProductTitleSkeleton = styled(Skeleton)`
    height: 20px;
    width: 200px;
`;

export const ProductBadgeSkeleton = styled(Skeleton)`
    height: 16px;
    width: 50px;
`;

export const ProductDescriptionSkeleton = styled(Skeleton)`
    height: 40px;
    width: 100%;
`;

export const ProductImageSkeleton = styled(Skeleton)`
    height: 90px;
    width: 90px;
`;

export const ProductSeparatorSkeleton = styled(Skeleton)`
    height: 4px;
    width: 100%;
`;
