import { useCallback } from 'react';
import { OnClickContainer, OnClickHandler, StyledCheckbox } from './Checkbox.style';

export type CheckboxProps = {
    checked: boolean;
    disabled?: boolean;
    onClick?: () => void;
};

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    checked,
    disabled,
    onClick,
    ...otherProps
}) => {
    const handleOnClick = useCallback(() => {
        !disabled && onClick?.();
    }, [disabled, onClick]);

    if (onClick) {
        return (
            <OnClickContainer>
                <StyledCheckbox checked={checked} disabled={disabled} {...otherProps} />
                <OnClickHandler
                    data-testid={checked ? 'checked' : 'not-checked'}
                    onClick={handleOnClick}
                />
            </OnClickContainer>
        );
    }

    return (
        <StyledCheckbox
            checked={checked}
            disabled={disabled}
            {...otherProps}
            data-testid={checked ? 'checked' : 'not-checked'}
        />
    );
};
