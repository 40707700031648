import { ReactElement, useCallback } from 'react';
import { Text } from 'components';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { SelectOptions } from 'components/RadioSelect';
import { Carret } from 'components/Carret';
import { SelectionSummary, SummarySelectedItem } from 'components/SelectionSummary';
import {
    OptionCheckboxContainer,
    Container,
    OptionContainer,
    OptionNameContainer,
    OptionNameContent,
    OptionContent,
    SummaryContainer,
} from './CheckboxSelect.style';
import { computeIsMaxChoiceReached } from './CheckboxSelect.utils';

export type CheckboxSelectProps<T> = {
    options: SelectOptions<T>[];
    onChange: (value: T, isSummary?: boolean) => void;
    selected?: Map<T, boolean>;
    maxChoice?: number;
    selectedSummary?: Record<number, SummarySelectedItem[]>;
    missingSteps?: Record<number, number>;
};

export const CheckboxSelect = <T,>({
    options,
    onChange,
    selected,
    maxChoice,
    selectedSummary,
    missingSteps,
}: CheckboxSelectProps<T>): ReactElement => {
    const handleOnChange = useCallback(
        (value: T, isSummary?: boolean) => {
            onChange(value, isSummary);
        },
        [onChange],
    );

    const isMaxChoiceReached = computeIsMaxChoiceReached(selected, maxChoice);

    return (
        <Container>
            {options.map(
                ({ name, value, icon: Icon, noTranslation, isStep, description, disabled }) => {
                    const isChecked = !!selected?.get(value);
                    const isDisabled = disabled || (isMaxChoiceReached && !isChecked);
                    const canChange = !disabled && (!isMaxChoiceReached || isChecked);
                    const selectedSummaryItems = selectedSummary?.[Number(value)];
                    const missingItemCount = missingSteps?.[Number(value)];

                    return (
                        <OptionContainer key={name} data-testid="checkbox-select">
                            <OptionContent onClick={() => canChange && handleOnChange(value)}>
                                <OptionNameContainer disabled={isDisabled}>
                                    {Icon && <Icon data-testid="icon" />}
                                    <OptionNameContent>
                                        {noTranslation ? (
                                            <>
                                                <Text text={name} type={2} noTranslation />
                                                {description && (
                                                    <Text
                                                        text={description}
                                                        type={2}
                                                        noTranslation
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Text text={name} type={2} />
                                                {description && (
                                                    <Text text={description} type={2} />
                                                )}
                                            </>
                                        )}
                                    </OptionNameContent>
                                </OptionNameContainer>

                                <OptionCheckboxContainer
                                    data-testid="checkbox-select-checkbox"
                                    disabled={isDisabled}
                                >
                                    <Checkbox checked={isChecked} disabled={isDisabled} />
                                    {isStep && <Carret />}
                                </OptionCheckboxContainer>
                            </OptionContent>

                            {isChecked && (selectedSummaryItems || !!missingItemCount) && (
                                <SummaryContainer>
                                    <SelectionSummary
                                        selectedItems={selectedSummaryItems || []}
                                        missingItemCount={missingItemCount}
                                        onChange={() => handleOnChange(value, true)}
                                    />
                                </SummaryContainer>
                            )}
                        </OptionContainer>
                    );
                },
            )}
        </Container>
    );
};
