import { useTranslation } from 'react-i18next';
import { BottomBar, Button, GrowingContainer } from 'components';
import {
    ColoredPageWithBottomBarContainer,
    ColoredText,
    Highlighted,
    List,
    ListItem,
    Title,
} from './Explanations.style';
import { ReactComponent as Step1 } from './illustrations/step1.svg';
import { ReactComponent as Step2 } from './illustrations/step2.svg';
import { ReactComponent as Step3 } from './illustrations/step3.svg';

const OrderFollowUpExplanations: React.FunctionComponent<{ onClick: () => void }> = ({
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <ColoredPageWithBottomBarContainer>
            <GrowingContainer>
                <Title>
                    {t('order_follow_up.explanations.title_first_part')}{' '}
                    <Highlighted>
                        {t('order_follow_up.explanations.title_highlighted_part')}
                    </Highlighted>
                </Title>
                <List>
                    <ListItem>
                        <Step1 />
                        <ColoredText
                            text={'order_follow_up.explanations.step_1'}
                            weight="Bold"
                            type={3}
                        />
                    </ListItem>
                    <ListItem>
                        <Step2 />
                        <ColoredText
                            text={'order_follow_up.explanations.step_2'}
                            weight="Bold"
                            type={3}
                        />
                    </ListItem>
                    <ListItem>
                        <Step3 />
                        <ColoredText
                            text={'order_follow_up.explanations.step_3'}
                            weight="Bold"
                            type={3}
                        />
                    </ListItem>
                </List>
            </GrowingContainer>
            <BottomBar withPadding>
                <Button fullWidth onClick={onClick} size="m">
                    {t('order_follow_up.explanations.understood')}
                </Button>
            </BottomBar>
        </ColoredPageWithBottomBarContainer>
    );
};

export default OrderFollowUpExplanations;
