import { BrandRestaurant } from 'services/brand/brand.type';

export const filterRestaurantById = (
    id: number | null,
    restaurants?: BrandRestaurant[] | null,
): BrandRestaurant | undefined => {
    if (!restaurants || !id) return undefined;
    const [restaurant] = restaurants.filter(({ restaurantId }) => id === restaurantId);

    return restaurant;
};
