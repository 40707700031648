import { v4 as uuidv4 } from 'uuid';
import { CompositeProduct } from 'redux/currentCompositeProduct';
import { StepDTO } from 'services/order/order.type';
import { MenuState } from 'redux/menu';
import { BrandState } from 'redux/brand';
import { ProductError } from 'redux/ordersErrors/ordersErrors.slice';
import { rectifyCart } from 'utils/cart';
import { OrdersState } from 'redux/orders';
import { RectifiedCart } from './useCartHandler';

export const computeStepsFromState = (
    id: number,
    currentProduct: CompositeProduct | null,
    menu: MenuState,
): StepDTO[] => {
    if (!menu?.products?.[id]?.productStep) {
        return [];
    }

    return menu.products[id].productStep.map(({ stepId, type }) => {
        const currentStep = currentProduct?.steps?.[stepId];
        const selectedSteps = currentStep ? Object.entries(currentStep) : [];

        return {
            stepId: Number(stepId),
            products: selectedSteps.map(([productId, product]) => {
                return {
                    productId: Number(productId),
                    productCartId: uuidv4(),
                    quantity: product.quantity,
                    steps: computeStepsFromState(Number(productId), product, menu),
                };
            }),
            type,
        };
    });
};

export const computeMenuIdFromBrand = (
    brand: BrandState,
    restaurantId: number,
): number | undefined => {
    const restaurant = brand?.restaurants.filter(({ restaurantId: r }) => restaurantId === r);

    if (restaurant === undefined || restaurant[0] === undefined) {
        return undefined;
    }
    const { menuId } = restaurant[0].menus[0];
    return menuId;
};

export const computeRectifiedCarts = (
    errors: ProductError[],
    orders: OrdersState,
    brand: BrandState,
): RectifiedCart[] => {
    return errors
        .map(({ restaurantId }): RectifiedCart | undefined => {
            const cartToRectify = orders?.[restaurantId]?.cart ?? [];
            const rectifiedCart = rectifyCart(cartToRectify, errors);
            const menuId = computeMenuIdFromBrand(brand, restaurantId);

            if (!menuId) return undefined;

            return {
                menuId,
                restaurantId,
                cart: rectifiedCart,
            };
        })
        .filter((a): a is RectifiedCart => a !== undefined);
};
