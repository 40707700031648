import styled from '@emotion/styled';
import { Title } from 'components';

export const Container = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`;

export const CardContent = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[2]};

    > h2 {
        margin-bottom: ${({ theme }) => theme.base.spacing[2]};
    }
`;

export const StripeConfirmTitle = styled(Title)`
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const Line = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.base.spacing[1]};

    > div:first-of-type {
        flex: 2;
    }

    > div:last-child {
        flex: 1;
    }
`;

export const TermsAndConditions = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.base.spacing[2]};
    border-top: ${({ theme }) => `0.5px solid ${theme.feature.thirdaryTextColor}`};
    padding-top: ${({ theme }) => theme.base.spacing[2]};
    margin-top: ${({ theme }) => theme.base.spacing[2]};

    & a {
        text-decoration: underline ${({ theme }) => theme.feature.primaryTextColor};
    }
`;

export const IconsContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.base.spacing[2]};
    padding-top: ${({ theme }) => theme.base.spacing[2]};
    align-items: center;
`;

export const Informations = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${({ theme }) => theme.base.spacing[1]};
`;

export const Privacy = styled.div`
    margin-left: -${({ theme }) => theme.base.spacing[2]};
    margin-right: -${({ theme }) => theme.base.spacing[2]};
    padding: ${({ theme }) => theme.base.spacing[2]};
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;
