"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeDiscountTaxBreakdown = void 0;
var rounding_1 = require("../roundings/rounding");
var compute_tax_rate_1 = require("../tax-rate/compute-tax-rate");
function computeDiscountTaxBreakdown(discounts, totalDiscountInclTax, totalDiscountExclTax) {
    var usedAmountTotal = {
        inclTax: totalDiscountInclTax,
        exclTax: totalDiscountExclTax,
    };
    var usedAmountItems = discounts.map(function (discount) { return ({
        inclTax: discount.usedAmountNotRoundedInclTax,
        exclTax: discount.usedAmountNotRoundedExclTax,
    }); });
    var items = (0, rounding_1.roundItemsConsistently)(usedAmountItems, usedAmountTotal).items;
    var newDiscounts = [];
    for (var i = 0; i < items.length; i++) {
        var discount = discounts[i];
        var usedAmountRoundedInclTax = items[i].inclTax;
        var usedAmountRoundedExclTax = items[i].exclTax;
        var taxRatesBreakdownNotRounded = (0, compute_tax_rate_1.computeDiscountTaxRatesBreakdown)(discount.discountables);
        var discountPriceForAllDiscountables = {
            totalInclTax: usedAmountRoundedInclTax,
            totalExclTax: usedAmountRoundedExclTax,
            totalInclTaxWithRemise: usedAmountRoundedInclTax,
            totalExclTaxWithRemise: usedAmountRoundedExclTax,
            totalInclTaxWithDiscount: usedAmountRoundedInclTax,
            totalExclTaxWithDiscount: usedAmountRoundedExclTax,
        };
        var taxRatesBreakdownRounded = (0, rounding_1.computeTotalAndRounds)(taxRatesBreakdownNotRounded, discountPriceForAllDiscountables).taxBreakdowns;
        newDiscounts.push(__assign(__assign({}, discount), { usedAmountRoundedInclTax: usedAmountRoundedInclTax, usedAmountRoundedExclTax: usedAmountRoundedExclTax, taxRatesBreakdownNotRounded: taxRatesBreakdownNotRounded, taxRatesBreakdownRounded: taxRatesBreakdownRounded }));
    }
    return newDiscounts;
}
exports.computeDiscountTaxBreakdown = computeDiscountTaxBreakdown;
