import styled from '@emotion/styled';
import { Title, Text } from 'components';

export const TermsAndConditionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[1]};
`;

export const Container = styled.div`
    text-align: center;
    padding: ${({ theme }) => theme.base.spacing[2]};
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const MargedTitle = styled(Title)`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const MargedText = styled(Text)`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.base.spacing[5]};
`;
