import { innovorderApi } from '../index';
import { ApiResponse } from '../providers/innovorder.types';
import { LoginResponse, LoginPayload, LogoutPayload } from './authentication.type';

const authenticationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<LoginResponse, LoginPayload>({
            query: (body) => {
                return {
                    url: `/oauth/login`,
                    method: 'POST',
                    body: {
                        ...body,
                        grant_type: 'password',
                        rememberMe: false,
                    },
                };
            },
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<LoginResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        logout: build.mutation<ApiResponse<undefined>, LogoutPayload>({
            query: ({ token }) => ({
                url: `/oauth/logout`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<undefined>) => response,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation } = authenticationApi;
