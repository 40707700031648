import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TranslationKey } from 'locales/fr';
import { computePrice } from 'utils/price';
import { TextStyle, TextStyleprops } from './TextStyle';

export type TextProps = TextStyleprops &
    (
        | {
              noTranslation?: false;
              isCurrency?: false;
              currency?: string;
              text: TranslationKey;
          }
        | {
              noTranslation: true;
              isCurrency?: false;
              currency?: string;
              text: string;
          }
        | {
              noTranslation?: false;
              isCurrency: true;
              currency: string;
              text: number;
          }
    );

export const Text: React.FunctionComponent<TextProps> = ({
    text,
    values,
    isCurrency,
    currency,
    noTranslation = false,
    ...otherProps
}) => {
    const { t, i18n } = useTranslation();
    return (
        <TextStyle {...otherProps}>
            {noTranslation && text}
            {isCurrency && currency && computePrice(i18n, text, currency)}
            {!noTranslation && !isCurrency && <Trans>{t(text as TranslationKey, values)}</Trans>}
        </TextStyle>
    );
};
