import parse from 'html-react-parser';
import React from 'react';

import { ProductAttributesWithRelations } from 'services/menu/menu.type';

import { TextStyle } from 'components/Text/TextStyle';
import { createPortal } from 'react-dom';
import {
    AdditionalInformation,
    PortalBackButton,
    ProductImage,
    ProductTitle,
    SimpleProductContainer,
    ImageSpacer,
    PortalHeader,
    PortalTitle,
    PortalSpacer,
    PortalContent,
    ScrollAnchor,
} from './SimpleProduct.style';
import { useSimpleProductVM } from './useSimpleProductVM';

export type SimpleProductProps = {
    product: ProductAttributesWithRelations;
    isComposite: boolean;
    isNested: boolean;
    currency: string;
    onGoBack: () => void;
};

const SimpleProduct: React.FunctionComponent<SimpleProductProps> = ({
    product,
    isComposite,
    isNested,
    currency,
    onGoBack,
}) => {
    const { name, image, price, description, additionalInformation } = product;
    const shouldShowPrice = price > 0 && !isNested;
    const { showFullPortal, handleScrollTop, variants, ref } = useSimpleProductVM();

    return (
        <>
            {createPortal(
                <PortalHeader
                    variants={variants}
                    initial="small"
                    exit="small"
                    animate={showFullPortal ? 'full' : 'small'}
                >
                    <PortalBackButton onClick={onGoBack} data-testid="back-button" />
                    {showFullPortal ? (
                        <PortalContent whileTap={{ scale: 0.95 }} onClick={handleScrollTop}>
                            <PortalTitle
                                data-testid="portal-title"
                                text={name}
                                noTranslation
                                type={5}
                            />
                        </PortalContent>
                    ) : (
                        <PortalSpacer />
                    )}
                </PortalHeader>,
                document.body,
            )}

            {image ? <ProductImage url={image.fullUrl} /> : <ImageSpacer />}

            <SimpleProductContainer isComposite={isComposite}>
                <ProductTitle text={name} noTranslation type={4} />
                <ScrollAnchor ref={ref} />
                {shouldShowPrice && (
                    <ProductTitle
                        data-testid="product-price"
                        text={price}
                        isCurrency
                        currency={currency}
                        type={5}
                    />
                )}
                {description && (
                    <TextStyle type={3} color="secondaryTextColor">
                        {parse(description)}
                    </TextStyle>
                )}
                {additionalInformation && (
                    <>
                        <AdditionalInformation text={'product_additional_information'} type={5} />
                        <TextStyle weight="Regular">{parse(additionalInformation)}</TextStyle>
                    </>
                )}
            </SimpleProductContainer>
        </>
    );
};

export default SimpleProduct;
