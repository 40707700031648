import '@emotion/react';
import { SAVORY_THEME } from '@innovorder/savory';

export const defaultCssVariables = {
    colors: {
        ...SAVORY_THEME.colors,
        orderingMainColor: ' #FD6268',
        orderingHighlightColor: '#FD363E',
        incognitoColor: '#3F3D56',
    },
    shadow: SAVORY_THEME.shadow,
    typography: SAVORY_THEME.typography,
    spacing: SAVORY_THEME.spacing,
    radius: {
        none: '0px',
        rounded: '28px',
        s: '4px',
        sm: '6px',
        default: '8px',
        md: '10px',
        lg: '12px',
        xl: '16px',
    },
    background:
        'https://images.unsplash.com/photo-1663210253953-4e647b573003?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1965&q=80s',
    logoBrandImage:
        'https://images.unsplash.com/photo-1602934445884-da0fa1c9d3b3?q=80&w=2758&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
};

export type BaseTheme = typeof defaultCssVariables;

export const FeaturesThemeEntities = [
    'primaryColor',
    'headerTextColor',
    'primaryTextColor',
    'secondaryTextColor',
    'thirdaryTextColor',
    'backgroundColor',
    'ctaColor',
    'ctaTextColor',
    'ctaSuccessColor',
    'incognitoColor',
    'errorColor',
    'successColor',
] as const;
export type FeaturesThemeEntity = typeof FeaturesThemeEntities[number];
export type FeaturesTheme = Record<FeaturesThemeEntity, string>;

export type ThemeType = {
    base: BaseTheme;
    feature: FeaturesTheme;
};

declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Theme extends ThemeType {}
}
