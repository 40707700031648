"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosItemsPreprocessor = exports.enrichPosOrderItems = exports.filterForOrderItems = void 0;
var types_1 = require("../types");
var promoCodeRegexp = /Code Promo '([^]+)' TVA+/;
var deliveryFeeRegexp = /(delivery fee)/;
var serviceChargeRegexp = /(service charge)/;
var filterForOrderItems = function (orderItem) {
    return !orderItem.orderItemType ||
        ((orderItem.orderItemType === types_1.PosOrderItemType.PRODUCT ||
            orderItem.orderItemType === types_1.PosOrderItemType.COMPENSATION) &&
            !promoCodeRegexp.test(orderItem.ticketName) &&
            !deliveryFeeRegexp.test(orderItem.ticketName) &&
            !serviceChargeRegexp.test(orderItem.ticketName));
};
exports.filterForOrderItems = filterForOrderItems;
var enrichPosOrderItems = function (orderItem, index) {
    orderItem.applyScopeId = "".concat(index, "-").concat(orderItem.sourceId);
    orderItem.index = index;
    // Temp fix to handle order with missing productOriginalUnitPrice
    if (!orderItem.productOriginalUnitPrice) {
        orderItem.productOriginalUnitPrice = orderItem.originalUnitPrice || orderItem.unitPriceWithTaxIncluded;
    }
    return orderItem;
};
exports.enrichPosOrderItems = enrichPosOrderItems;
var findPreprocessingItem = function (subTree, path) {
    var sourceId = path[0], rest = path.slice(1);
    if (rest.length === 0) {
        return subTree[sourceId];
    }
    return findPreprocessingItem(subTree[sourceId].childItems, rest);
};
var PosItemsPreprocessor = /** @class */ (function () {
    function PosItemsPreprocessor(posOrderItems) {
        this.posOrderItems = posOrderItems;
        this.reset();
    }
    PosItemsPreprocessor.prototype.reset = function () {
        this.preprocessingItemTree = {};
        this.waitingPosOrderItems = {};
        this.pathsMap = {};
        this.waitingChildren = {};
    };
    PosItemsPreprocessor.prototype.addItem = function (childPosOrderItem, path) {
        if (path === void 0) { path = []; }
        if (path.length === 0) {
            this.preprocessingItemTree[childPosOrderItem.sourceId] = __assign(__assign({}, childPosOrderItem), { childItems: {} });
        }
        else {
            var parentOrderItem = findPreprocessingItem(this.preprocessingItemTree, path);
            parentOrderItem.childItems[childPosOrderItem.sourceId] = __assign(__assign({}, childPosOrderItem), { childItems: {} });
        }
        this.pathsMap[childPosOrderItem.sourceId] = __spreadArray(__spreadArray([], path, true), [childPosOrderItem.sourceId], false);
        this.addWaitingChildren(childPosOrderItem.sourceId);
    };
    PosItemsPreprocessor.prototype.addWaitingChildren = function (sourceId) {
        var _this = this;
        if (this.waitingChildren[sourceId]) {
            this.waitingChildren[sourceId].forEach(function (childrenSourceId) {
                _this.addItem(_this.waitingPosOrderItems[childrenSourceId], _this.pathsMap[sourceId]);
                delete _this.waitingPosOrderItems[childrenSourceId];
            });
            delete this.waitingChildren[sourceId];
        }
    };
    PosItemsPreprocessor.prototype.preprocess = function () {
        var _this = this;
        this.reset();
        this.posOrderItems
            .map(exports.enrichPosOrderItems)
            .filter(function (orderItem) { return (0, exports.filterForOrderItems)(orderItem); })
            .forEach(function (posOrderItem) {
            if (!posOrderItem.parentSourceId) {
                _this.addItem(posOrderItem);
            }
            else if (_this.pathsMap[posOrderItem.parentSourceId]) {
                _this.addItem(posOrderItem, _this.pathsMap[posOrderItem.parentSourceId]);
            }
            else {
                _this.waitingPosOrderItems[posOrderItem.sourceId] = posOrderItem;
                if (_this.waitingChildren[posOrderItem.parentSourceId]) {
                    _this.waitingChildren[posOrderItem.parentSourceId].push(posOrderItem.sourceId);
                }
                else {
                    _this.waitingChildren[posOrderItem.parentSourceId] = [posOrderItem.sourceId];
                }
            }
        });
        Object.values(this.waitingPosOrderItems).forEach(function (lostChildren) { return _this.addItem(lostChildren); });
    };
    PosItemsPreprocessor.prototype.convertTreeToArrays = function (partialTree) {
        var _this = this;
        return Object.values(partialTree).map(function (preprocessingItem) { return (__assign(__assign({}, preprocessingItem), { childItems: _this.convertTreeToArrays(preprocessingItem.childItems) })); });
    };
    PosItemsPreprocessor.prototype.getPreprocessedItems = function () {
        this.preprocess();
        return this.convertTreeToArrays(this.preprocessingItemTree);
    };
    return PosItemsPreprocessor;
}());
exports.PosItemsPreprocessor = PosItemsPreprocessor;
