import { useEdenredAuthentication } from 'hooks/useEdenredAuthentication/useEdenredAuthentication';
import { useOrderPrice } from 'hooks/useOrderPrice/useOrderPrice';
import { useEdenredBalance } from 'hooks/useEdenredBalance/useEdenredBalance';
import { useTranslation } from 'react-i18next';

export const useEdenredAuthVM = () => {
    const { t } = useTranslation();

    const {
        isLoading: isLoadingAuthUrl,
        authenticateToEdenred,
        error,
        isAuthenticated,
    } = useEdenredAuthentication();
    const { amountWithCurrency } = useOrderPrice();
    const { balanceWithCurrency } = useEdenredBalance();

    return {
        buttonText: {
            confirm: t('confirm_and_pay_with_amount', { amount: amountWithCurrency }),
            link: t('edenred.authentication.auth_button_url'),
        },
        isLoadingAuthUrl,
        authenticateToEdenred,
        balanceWithCurrency,
        error,
        isAuthenticated,
    };
};
