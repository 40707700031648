import React from 'react';

import { SvyButtonProps } from '@innovorder/savory';
import { StyledButton, DisabledClickHandler, DisabledContainer } from './Button.style';

export type ButtonProps = SvyButtonProps & {
    fullWidth?: boolean;
    onClick?: () => void;
    onDisabledClick?: () => void;
    'data-testid'?: string;
};

export const Button: React.FunctionComponent<ButtonProps> = ({
    children,
    disabled,
    onDisabledClick,
    onClick,
    ...otherProps
}) => {
    const { 'data-testid': dataTestId, ...rest } = otherProps;

    if (disabled && onDisabledClick) {
        return (
            <DisabledContainer fullWidth={otherProps.fullWidth}>
                <StyledButton data-testid="button-disabled" {...rest}>
                    {children}
                </StyledButton>
                <DisabledClickHandler
                    data-testid={dataTestId || 'button'}
                    onClick={onDisabledClick}
                />
            </DisabledContainer>
        );
    }

    return (
        <StyledButton
            data-testid={dataTestId || 'button'}
            {...rest}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </StyledButton>
    );
};
