import { parseRestaurantId } from 'pages/Home/Home.utils';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getRestaurantId, updateAppState } from 'redux/app';
import { getMenuId, getRestaurant } from 'redux/brand';
import { updateMenuState } from 'redux/menu';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useGetMenuHashMapQuery } from 'services/menu/menu.endpoints';

export const useMenuHandler = () => {
    const dispatch = useAppDispatch();

    const paramsRestaurantId = parseRestaurantId(useParams().restaurantId);
    const stateRestaurantId = useAppSelector(getRestaurantId);
    const restaurantId = paramsRestaurantId || stateRestaurantId || null;
    const currentMenuId = useAppSelector(getMenuId(restaurantId || null));
    const restaurant = useAppSelector(getRestaurant(restaurantId || null));

    const {
        isLoading,
        isError,
        data: menu,
        refetch,
    } = useGetMenuHashMapQuery({ menuId: currentMenuId || undefined }, { skip: !currentMenuId });

    useEffect(() => {
        if (menu && restaurant) {
            dispatch(updateMenuState(menu));
            dispatch(
                updateAppState({
                    currentMenuId,
                    restaurantId,
                    restaurantName: restaurant.name,
                }),
            );
        }
    }, [menu, currentMenuId, restaurantId, restaurant, dispatch]);

    const refetchMenu = useCallback(async () => {
        refetch();
    }, [refetch]);

    return {
        isLoading,
        isError,
        data: menu,
        refetchMenu,
    };
};
