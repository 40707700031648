import React from 'react';
import Error, { ErrorEnum } from 'pages/Error';
import { TranslationKey } from 'locales/fr';
import { Button } from 'components';
import {
    ButtonsContainer,
    FoodCourtImage,
    MargedText,
    MargedTitle,
    OverTitleText,
    TitleContainer,
} from './Home.style';
import { useHomeVM } from './useHomeVM';
import AlcoholBanner from './AlcoholBanner/AlcoholBanner';
import LoggedOutModal from './LoggedOutModal';

const Home: React.FunctionComponent = () => {
    const {
        step,
        shouldDisplayContent,
        buttons,
        displayedName,
        isQrCodeError,
        isConsumptionModeError,
        isAlcoolBannerActivated,
        shouldDisplayLogoutPopup,
        logoutPopupCallback,
    } = useHomeVM();

    if (isQrCodeError) {
        return <Error error={ErrorEnum.QR_CODE_ERROR} />;
    }

    if (isConsumptionModeError) {
        return <Error error={ErrorEnum.NO_CONSUMPTION_MODE} />;
    }

    return (
        <>
            {isAlcoolBannerActivated && <AlcoholBanner />}

            <TitleContainer>
                <FoodCourtImage />
                <OverTitleText text={`home_page.${step}.surtitle` as TranslationKey} type={1} />
                <MargedTitle
                    text={`home_page.${step}.title` as TranslationKey}
                    values={{ title: displayedName || '' }}
                    type={4}
                />
            </TitleContainer>
            {shouldDisplayContent && (
                <MargedText text={`home_page.${step}.content` as TranslationKey} type={3} />
            )}

            <ButtonsContainer>
                {buttons.map(({ name, onClick, variant, elementLeft, elementRight }) => (
                    <Button
                        key={name}
                        fullWidth
                        onClick={onClick}
                        elementLeft={elementLeft}
                        elementRight={elementRight}
                        variant={variant}
                    >
                        {name}
                    </Button>
                ))}
            </ButtonsContainer>

            <LoggedOutModal isOpen={shouldDisplayLogoutPopup} onHide={logoutPopupCallback} />
        </>
    );
};

export default Home;
