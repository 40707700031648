"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapDiscountToDiscountRecap = void 0;
var mapDiscountToDiscountRecap = function (discount) { return ({
    label: discount.label,
    id: discount.id,
    amount: discount.usedAmountRoundedInclTax,
    quantity: discount.quantity,
    strategy: {
        type: discount.type,
        applyMethod: discount.legacyApplyMethod,
        value: discount.amount,
    },
    taxRatesBreakdown: discount.taxRatesBreakdownRounded,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    additionalData: discount.additionalData,
    grantRawData: discount.grantRawData,
    pricingRuleRawData: discount.pricingRuleRawData,
}); };
exports.mapDiscountToDiscountRecap = mapDiscountToDiscountRecap;
