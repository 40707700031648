import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const restaurantSchedulesSliceName = 'restaurantSchedules';

export type OpeningDetails = {
    isCurrentlyOpen: boolean;
    nextOpening?: string;
};
export type RestaurantSchedulesState = Record<number, OpeningDetails> | null;

const initialState: RestaurantSchedulesState = null;

export const restaurantSchedulesSlice = createSlice<
    RestaurantSchedulesState,
    SliceCaseReducers<RestaurantSchedulesState>,
    string
>({
    name: restaurantSchedulesSliceName,
    initialState,
    reducers: {
        updateRestaurantSchedules: (state, action: PayloadAction<RestaurantSchedulesState>) =>
            action.payload,
    },
});

export const getRestaurantSchedules = (state: RootState): RestaurantSchedulesState =>
    state.restaurantSchedules;
export const getRestaurantScheduleByRestaurantId =
    (restaurantId: number | null) =>
    (state: RootState): OpeningDetails | undefined =>
        restaurantId ? getRestaurantSchedules(state)?.[restaurantId] : undefined;

export const { updateRestaurantSchedules } = restaurantSchedulesSlice.actions;
