import styled from '@emotion/styled';
import { BackButton, Title } from 'components';
import { backButtonSize } from 'components/BackButton/BackButton.style';
import { motion } from 'framer-motion';

export const SimpleProductContainer = styled.div<{ isComposite?: boolean }>`
    ${({ isComposite }) => !isComposite && `flex: 1`};
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const PortalHeader = styled(motion.div)`
    z-index: 2;
    position: fixed;
    top: ${({ theme }) => theme.base.spacing[2]};
    left: ${({ theme }) => theme.base.spacing[2]};
    width: ${({ theme }) => `calc(100% - ${theme.base.spacing[4]})`};
    height: ${backButtonSize};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.feature.backgroundColor};
    border-radius: ${({ theme }) => theme.base.radius.default};
    box-shadow: ${({ theme }) => theme.base.shadow.outside[300]};
`;

export const PortalBackButton = styled(BackButton)`
    box-shadow: none;
    overflow: unset !important;
    position: absolute !important;
    left: 0;
`;

export const PortalContent = styled(motion.div)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PortalTitle = styled(Title)`
    flex: 1;
    padding: 0 ${backButtonSize};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

export const ScrollAnchor = styled.span``;

export const PortalSpacer = styled.div`
    height: ${backButtonSize};
    flex: 1;
`;

export const ProductTitle = styled(Title)`
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;

export const AdditionalInformation = styled(Title)`
    margin-top: ${({ theme }) => theme.base.spacing[3]};
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;

export const ProductImage = styled.div<{ url: string }>`
    height: 204px;
    width: 100%;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${({ url }) => url}');
`;

export const ImageSpacer = styled.div`
    height: 105px;
`;
