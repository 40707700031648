import { computePrice } from 'utils/price';
import i18n from 'core/InitialiseThirdParties/i18n';
import { AccountPaymentType } from 'services/customer/customer.type';

export const computeCustomerBalance = (
    customerBalance: number,
    ewalletOverdraftAmount: number | null,
    currency: string | undefined,
    accountPaymentType: AccountPaymentType | null,
) => {
    if (!currency) {
        return undefined;
    }

    if (ewalletOverdraftAmount && accountPaymentType === 'POSTPAYMENT') {
        return computePrice(i18n, customerBalance + ewalletOverdraftAmount, currency);
    }

    return computePrice(i18n, customerBalance, currency);
};
