import React from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from 'locales/fr';
import { SvyInputProps } from '@innovorder/savory';
import { ErrorSvyIcon, StyledInput, TouchedSvyIcon } from './Input.style';

export type InputUnitType = 'currency' | 'percentage';

export type InputProps = {
    value?: string;
    placeholder?: TranslationKey;
    label?: string;
    error?: string;
    name?: string;
    type?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    customInput?: React.ReactNode;
    disabled?: boolean;
    autoFocus?: boolean;
    isTouched?: boolean;
    isFocused?: boolean;
    isSuccess?: boolean;
    successIcon?: React.ReactNode;
} & SvyInputProps;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            label,
            error,
            name = '',
            onChange,
            onBlur,
            placeholder,
            type = 'text',
            disabled,
            isTouched,
            isFocused,
            isSuccess,
            successIcon,
            autoFocus = false,
            ...rest
        },
        ref,
    ) => {
        const { t } = useTranslation();

        let elementRight;
        if (isTouched) elementRight = <TouchedSvyIcon icon="check-line" />;
        if (error) elementRight = <ErrorSvyIcon icon="alert-line" />;
        if (isSuccess && successIcon) elementRight = <>{successIcon}</>;

        return (
            <StyledInput
                ref={ref}
                type={type}
                value={value}
                placeholder={placeholder ? t(placeholder) : ''}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                disabled={disabled}
                autoFocus={autoFocus}
                isTouched={isTouched}
                isFocused={isFocused}
                label={label}
                helperText={error}
                hasError={!!error}
                elementRight={elementRight}
                {...rest}
            />
        );
    },
);
Input.displayName = 'Input';
