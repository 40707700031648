import styled from '@emotion/styled';
import { SvyBadge, SvyIcon } from '@innovorder/savory';
import { Button, Callout, GrowingContainer, HeaderTitle, Title } from 'components';

export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: ${({ theme }) => theme.base.spacing[5]};
    margin: ${({ theme }) => theme.base.spacing[2]};
    text-align: center;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: calc(${({ theme }) => theme.base.spacing[1]} - 3px);
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const SpacedHeaderTitle = styled(HeaderTitle)`
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const StyledColoredTitle = styled(Title)`
    color: ${({ theme }) => theme.feature.ctaColor};
`;

export const StyledInfoCallout = styled(Callout)`
    padding: ${({ theme }) => theme.base.spacing[1]};
    border-radius: ${({ theme }) => theme.base.radius.default};
`;

export const OrderFollowUpContentContainer = styled(GrowingContainer)`
    padding: ${({ theme }) => theme.base.spacing[2]};
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledIcon = styled(SvyIcon)`
    font-size: 24px;
    margin-right: ${({ theme }) => theme.base.spacing[1]};
`;

export const BottomBarButtonsContainer = styled.div<{ withPadding?: boolean }>`
    padding: ${({ theme, withPadding }) => withPadding && theme.base.spacing[2]};
    width: 100%;
`;

export const MarginedButton = styled(Button)`
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;

export const PriceBreakDownSummaryContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const OrderNumberBadge = styled(SvyBadge)`
    height: fit-content;
    min-width: fit-content;
`;
