"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyAbsoluteStrategy = exports.shouldApplyRemise = exports.DiscountByAbsolute = void 0;
var types_1 = require("../../adapters/pos-order-adapter/types");
var discount_types_1 = require("../../types/discount.types");
var big_numbers_1 = require("../../utils/big-numbers");
var product_1 = require("../product/product");
var discount_1 = require("./discount");
var DiscountByAbsolute = /** @class */ (function (_super) {
    __extends(DiscountByAbsolute, _super);
    function DiscountByAbsolute(amount, orderDetailDiscount, discountables, usedGrantsShouldBeEffective) {
        if (usedGrantsShouldBeEffective === void 0) { usedGrantsShouldBeEffective = false; }
        var _this = this;
        var _a;
        _this = _super.call(this, orderDetailDiscount, discountables) || this;
        _this.amount = amount;
        _this.discountables = discountables;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        var usedAmount = Number((_a = orderDetailDiscount === null || orderDetailDiscount === void 0 ? void 0 : orderDetailDiscount.additionalData) === null || _a === void 0 ? void 0 : _a.usedAmount);
        _this.usedAmount = Number.isNaN(usedAmount) ? undefined : usedAmount;
        _this.usedGrantsShouldBeEffective = usedGrantsShouldBeEffective;
        return _this;
    }
    Object.defineProperty(DiscountByAbsolute.prototype, "totalAmount", {
        get: function () {
            return this.filteredDiscountables.reduce(function (sum, discountable) { return sum.plus(discountable.discountedAmount); }, big_numbers_1.big0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiscountByAbsolute.prototype, "effective", {
        get: function () {
            return this.usedGrantsShouldBeEffective ? this.effectiveBecauseItUsedByPos() : this.effectiveByBusinessRules();
        },
        enumerable: false,
        configurable: true
    });
    DiscountByAbsolute.prototype.effectiveByBusinessRules = function () {
        var isNotGrant = this.type !== discount_types_1.DISCOUNT_TYPE.GRANT;
        var hasAtLeastOneFilteredDiscountable = !!this.filteredDiscountables.length;
        var totalAmountIsGreaterThanDiscountAmount = this.totalAmount.isGreaterThanOrEqualTo(this.amount);
        return isNotGrant || (hasAtLeastOneFilteredDiscountable && totalAmountIsGreaterThanDiscountAmount);
    };
    DiscountByAbsolute.prototype.effectiveBecauseItUsedByPos = function () {
        var discountIsUsed = !!this.usedAmount;
        return this.effectiveByBusinessRules() || discountIsUsed;
    };
    DiscountByAbsolute.prototype.discount = function () {
        var _this = this;
        if (this.amount.isZero()) {
            return;
        }
        this.appliedAmount = this.apply();
        (0, exports.applyAbsoluteStrategy)(this, this.appliedAmount, function (discountable, discountAmount) {
            _this.discountableDiscountedAmounts.set(discountable.id, discountAmount);
            discountable.discount(discountAmount);
        });
    };
    DiscountByAbsolute.prototype.apply = function () {
        return this.totalAmount.isGreaterThanOrEqualTo(this.amount) ? this.amount : this.totalAmount;
    };
    return DiscountByAbsolute;
}(discount_1.Discount));
exports.DiscountByAbsolute = DiscountByAbsolute;
var shouldApplyRemise = function (_a) {
    var discount = _a.discount, discountable = _a.discountable;
    return discount_types_1.RemiseTypes.includes(discount.type) &&
        discount instanceof DiscountByAbsolute &&
        (!(discountable instanceof product_1.Product) ||
            (discountable.data &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                discountable.data.additionalData &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                discountable.data.additionalData.orderItemType !== types_1.PosOrderItemType.COMPENSATION));
};
exports.shouldApplyRemise = shouldApplyRemise;
var applyAbsoluteStrategy = function (discount, amount, applyDiscountAmount) {
    // The amount is to be distributed amongst discountables based on their share,
    // a share being either:
    // - the amount of the discountable if it is positive
    // - 0 if the amount of the discountable is negative
    // - 1 if all the products are free
    var totalAmountOfFilteredDiscountables = discount.filteredDiscountables.reduce(function (sum, discountable) {
        return discountable.discountedAmount.isGreaterThan(big_numbers_1.big0) ? sum.plus(discountable.discountedAmount) : sum;
    }, big_numbers_1.big0);
    var totalShares = totalAmountOfFilteredDiscountables.isZero()
        ? discount.filteredDiscountables.length
        : totalAmountOfFilteredDiscountables;
    var reportedShare = big_numbers_1.big0;
    discount.filteredDiscountables.forEach(function (discountable) {
        var share;
        if (totalAmountOfFilteredDiscountables.isZero()) {
            share = big_numbers_1.big1;
        }
        else if (discountable.discountedAmount.isGreaterThan(big_numbers_1.big0)) {
            share = discountable.discountedAmount;
        }
        else {
            share = big_numbers_1.big0;
        }
        var appliedAmountShare = share.absoluteValue().multipliedBy(amount).plus(reportedShare);
        var discountAmount = appliedAmountShare.dividedToIntegerBy(totalShares);
        reportedShare = appliedAmountShare.minus(discountAmount.multipliedBy(totalShares));
        applyDiscountAmount(discountable, discountAmount);
        if ((0, exports.shouldApplyRemise)({ discount: discount, discountable: discountable })) {
            discountable.applyRemise(discountAmount);
        }
    });
};
exports.applyAbsoluteStrategy = applyAbsoluteStrategy;
