"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderRecapFixtureFactory = exports.productRecapFixtureFactory = exports.feeRecapFixtureFactory = exports.discountRecapFixtureFactory = exports.discountStrategyFixtureFactory = exports.priceBreakdownFixtureFactory = void 0;
var faker_1 = require("@faker-js/faker");
var __1 = require("..");
var utils_1 = require("../utils/utils");
var priceBreakdownFixtureFactory = function (params) {
    if (params === void 0) { params = {}; }
    return (__assign({ totalInclTax: faker_1.faker.datatype.number(), totalExclTax: faker_1.faker.datatype.number(), totalTax: faker_1.faker.datatype.number(), totalInclTaxWithDiscount: faker_1.faker.datatype.number(), totalExclTaxWithDiscount: faker_1.faker.datatype.number(), totalTaxWithDiscount: faker_1.faker.datatype.number(), totalInclTaxWithRemise: faker_1.faker.datatype.number(), totalExclTaxWithRemise: faker_1.faker.datatype.number(), totalTaxWithRemise: faker_1.faker.datatype.number() }, params));
};
exports.priceBreakdownFixtureFactory = priceBreakdownFixtureFactory;
var discountStrategyFixtureFactory = function (params) {
    if (params === void 0) { params = {}; }
    return (__assign({ type: faker_1.faker.helpers.arrayElement((0, utils_1.enumValues)(__1.DISCOUNT_TYPE)), applyMethod: __1.DiscountApplyMethod.ABSOLUTE_CART, value: faker_1.faker.datatype.number() }, params));
};
exports.discountStrategyFixtureFactory = discountStrategyFixtureFactory;
var discountRecapFixtureFactory = function (params) {
    if (params === void 0) { params = {}; }
    return (__assign({ label: faker_1.faker.random.alphaNumeric(10), id: faker_1.faker.datatype.number().toString(), amount: faker_1.faker.datatype.number(), quantity: 1, strategy: (0, exports.discountStrategyFixtureFactory)() }, params));
};
exports.discountRecapFixtureFactory = discountRecapFixtureFactory;
var feeRecapFixtureFactory = function (params) {
    if (params === void 0) { params = {}; }
    return (__assign({ label: faker_1.faker.random.alphaNumeric(10), id: faker_1.faker.datatype.number().toString(), value: faker_1.faker.datatype.number(), quantity: 1, taxRate: faker_1.faker.datatype.number(), type: faker_1.faker.helpers.arrayElement((0, utils_1.enumValues)(__1.FEE_TYPE)), totalPrice: (0, exports.priceBreakdownFixtureFactory)() }, params));
};
exports.feeRecapFixtureFactory = feeRecapFixtureFactory;
var productRecapFixtureFactory = function (params) {
    if (params === void 0) { params = {}; }
    return (__assign({ label: faker_1.faker.random.alphaNumeric(10), productCartId: faker_1.faker.datatype.uuid(), id: faker_1.faker.datatype.number(), taxRate: faker_1.faker.datatype.number(), totalPrice: (0, exports.priceBreakdownFixtureFactory)(), unitPrice: (0, exports.priceBreakdownFixtureFactory)(), depth: faker_1.faker.datatype.number(5), quantity: faker_1.faker.datatype.number({ min: 1, max: 5 }), tags: [], orderItemType: __1.DISCOUNTABLE_TYPE.PRODUCT }, params));
};
exports.productRecapFixtureFactory = productRecapFixtureFactory;
var orderRecapFixtureFactory = function (_a) {
    var _b = _a === void 0 ? { recapParams: {}, feeRecapParams: [], discountRecapParams: [] } : _a, _c = _b.recapParams, recapParams = _c === void 0 ? {} : _c, _d = _b.feeRecapParams, feeRecapParams = _d === void 0 ? [] : _d, _e = _b.discountRecapParams, discountRecapParams = _e === void 0 ? [] : _e;
    return (__assign({ currency: 'EUR', totalPrice: (0, exports.priceBreakdownFixtureFactory)(), taxRatesBreakdown: [
            {
                taxRate: faker_1.faker.datatype.number(),
                totalPrice: (0, exports.priceBreakdownFixtureFactory)(),
            },
            {
                taxRate: faker_1.faker.datatype.number(),
                totalPrice: (0, exports.priceBreakdownFixtureFactory)(),
            },
        ], discounts: discountRecapParams.length > 0
            ? discountRecapParams.map(function (params) { return (0, exports.discountRecapFixtureFactory)(params); })
            : [(0, exports.discountRecapFixtureFactory)(), (0, exports.discountRecapFixtureFactory)()], fees: feeRecapParams.length > 0
            ? feeRecapParams.map(function (params) { return (0, exports.feeRecapFixtureFactory)(params); })
            : [(0, exports.feeRecapFixtureFactory)(), (0, exports.feeRecapFixtureFactory)()], products: [(0, exports.productRecapFixtureFactory)(), (0, exports.productRecapFixtureFactory)()], totalLuncheonEligibleAmount: faker_1.faker.datatype.number(), globalDiscount: {
            discountRate: faker_1.faker.datatype.number(),
        }, entranceFeeAmount: faker_1.faker.datatype.number(), grantAmount: faker_1.faker.datatype.number(), turnover: faker_1.faker.datatype.number(), turnoverWithTaxExcluded: faker_1.faker.datatype.number(), turnoverTotalTax: faker_1.faker.datatype.number(), offTurnover: faker_1.faker.datatype.number() }, recapParams));
};
exports.orderRecapFixtureFactory = orderRecapFixtureFactory;
