import { ConsumptionModeType } from '@innovorder/order_detail';
import { Title, Text, Modal, Button } from 'components';

import { useTranslation } from 'react-i18next';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { useConsumptionPickerVM } from './useConsumptionPickerVM';
import {
    Container,
    ConsumptionModesContainer,
    ModeButton,
    ModalContainer,
    ModalButtonsContainer,
    ModalIconHeader,
} from './ConsumptionPicker.style';

const ConsumptionPicker: React.FunctionComponent = () => {
    const { mode, modal, handleOnClick, shouldDisplayPicker } = useConsumptionPickerVM();

    const { t } = useTranslation();

    if (!shouldDisplayPicker) return <></>;

    return (
        <Container>
            <Title text={'cart.mode.title'} type={5} />

            <ConsumptionModesContainer>
                <ModeButton
                    size="s"
                    onClick={() => handleOnClick(ConsumptionModeType.MODE_SIT_IN)}
                    selected={mode === ConsumptionModeType.MODE_SIT_IN}
                    elementLeft={<SVGIcon icon={SVGIcons.SIT_IN} />}
                    variant="text"
                >
                    {t('cart.mode.sit_in')}
                </ModeButton>

                <ModeButton
                    size="s"
                    onClick={() => handleOnClick(ConsumptionModeType.MODE_TAKE_AWAY)}
                    selected={mode === ConsumptionModeType.MODE_TAKE_AWAY}
                    elementLeft={<SVGIcon icon={SVGIcons.TAKE_AWAY} />}
                    variant="text"
                >
                    {t('cart.mode.take_away')}
                </ModeButton>
            </ConsumptionModesContainer>

            <Modal
                headerTitle={'cart.mode.title'}
                icon={() => <ModalIconHeader icon="error-warning-line" />}
                isOpen={modal.isOpen}
                onHide={modal.handleCloseModal}
                data-testid="mode-modal"
            >
                <ModalContainer>
                    <Text text={'cart.mode.modal.title'} type={3} />
                    <Text text={'cart.mode.modal.subtitle'} type={3} />
                    <Text text={'cart.mode.modal.question'} type={3} />

                    <ModalButtonsContainer>
                        <Button onClick={modal.handleCloseModal} variant="text" color="destructive">
                            {t('cart.mode.modal.close')}
                        </Button>
                        <Button onClick={modal.handleActionModal} variant="text">
                            {t('cart.mode.modal.action')}
                        </Button>
                    </ModalButtonsContainer>
                </ModalContainer>
            </Modal>
        </Container>
    );
};

export default ConsumptionPicker;
