import { innovorderApi } from '../index';
import { ApiResponse } from '../providers/innovorder.types';
import { MenuHashMap, GetMenuHashMapPayload } from './menu.type';

const brandApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getMenuHashMap: build.query<MenuHashMap, GetMenuHashMapPayload>({
            query: ({ menuId }) => ({
                url: `/menus/${menuId}/hash_map`,
                method: 'GET',
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<MenuHashMap>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetMenuHashMapQuery } = brandApi;
