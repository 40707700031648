import styled from '@emotion/styled';
import { Text, Title } from 'components';

export const MargedTitle = styled(Title)`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.base.spacing[4]};
    font-size: ${({ theme }) => theme.base.typography.Title4};
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.base.spacing[1]};
`;

export const FoodCourtImage = styled.div`
    height: 90px;
    min-width: 90px;
    background-position: center center;
    background-size: 100%;
    border-radius: 50%;
    background-repeat: no-repeat;
    margin-top: -75px;
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
    box-shadow: ${({ theme }) => theme.base.shadow.outside[200]};
    background-color: ${({ theme }) => theme.base.colors.secondary[50]};
    background-image: url('${({ theme }) => theme.base.logoBrandImage}');
`;

export const MargedText = styled(Text)`
    display: block;
    margin-bottom: ${({ theme }) => theme.base.spacing[4]};
`;

export const OverTitleText = styled(Text)`
    font-size: ${({ theme }) => theme.base.typography.Title5};
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
