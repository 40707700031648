import { useCustomFlush } from 'hooks/useCustomFlush';

export const usePaygreenRequireActionModalVM = (onHide: () => void) => {
    const { flushRequiredAction, flushIsPaymentConfirmed } = useCustomFlush();

    const handleOnHide = () => {
        onHide();
    };

    const handlePaymentFailed = () => {
        flushRequiredAction();
        flushIsPaymentConfirmed();
    };

    const handlePaymentSuccess = () => {
        flushRequiredAction();
        flushIsPaymentConfirmed();
    };

    return {
        handleOnHide,
        handlePaymentFailed,
        handlePaymentSuccess,
    };
};
