import { SelectOptions } from 'components/RadioSelect';
import { getCurrentConsumptionModeType, getRestaurantId } from 'redux/app';
import { getRestaurantScheduleByRestaurantId } from 'redux/restaurantSchedules';
import { getBrandCurrency } from 'redux/brand';
import {
    getProductByPath,
    getCurrentPath,
    updateCurrentStep,
    updateCurrentPath,
    getMissingSteps,
    updateMissingSteps,
    getNestedProductId,
    getPreviousPath,
} from 'redux/currentCompositeProduct';
import { getMenuState } from 'redux/menu';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ProductStepRelation, StepType } from 'services/menu/menu.type';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import {
    computeCompositeProductWithActions,
    computeSelectedOptions,
    computeSelectOptions,
    computeStatus,
    computeSelectedSummaryItems,
    CompositeProductWithActions,
    computeResetMissingSteps,
    getNextStepFromPreviousStepId,
    computeFilteredProductSteps,
    filterCrossSellSteps,
    Selected,
} from './ProductSteps.utils';

export type ComputedProductStep = {
    stepId: number;
    name: string;
    minChoice: number;
    maxChoice: number;
    isExcessAllowed: boolean;
    options: SelectOptions<number>[];
    handleOnChange: (value: number | undefined, isSummary?: boolean) => void;
};

export type StatusType = 'primary' | 'destructive' | 'success';
export type SelectType = 'radio' | 'checkbox' | 'quantityPicker';

export type StepStatusType = Record<number, StatusType>;
export type StepSelectType = Record<number, SelectType>;

export const useProductStepsVM = (
    productSteps: ProductStepRelation[],
    hasTriedAddingToCart: boolean,
) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const restaurantId = useAppSelector(getRestaurantId);
    const restaurantSchedule = useAppSelector(getRestaurantScheduleByRestaurantId(restaurantId));
    const currency = useAppSelector(getBrandCurrency);
    const menu = useAppSelector(getMenuState);
    const currentPath = useAppSelector(getCurrentPath);
    const previousPath = useAppSelector(getPreviousPath);
    const missingSteps = useAppSelector(getMissingSteps);
    const currentProduct = useAppSelector(getProductByPath(currentPath || []));
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const nestedProductId = useAppSelector(getNestedProductId);

    const [isTouched, setIsTouched] = useState(false);
    const [currentProductPreviousStepId, setCurrentProductPreviousStepId] = useState<number | null>(
        null,
    );

    const previousPathPreviousStepId =
        (previousPath &&
            previousPath.length > 1 &&
            Number(previousPath[previousPath.length - 2])) ||
        null;
    const isNested = !!nestedProductId;

    const filteredProductSteps = computeFilteredProductSteps(productSteps, currentPath, menu);

    const stepSelectType = filteredProductSteps.reduce<StepSelectType>(
        (acc, { stepId, maxChoice, isExcessAllowed }) => {
            const isRadio = maxChoice === 1 && !isExcessAllowed;
            const stepProducts = menu?.steps?.[stepId]?.stepProduct;
            const isQuantityPicker =
                stepProducts !== undefined &&
                stepProducts.some(
                    (product) =>
                        product.step_product.maxProductQuantity > 1 ||
                        product.step_product.maxProductQuantity === 0,
                );

            if (isRadio) {
                acc[stepId] = 'radio';
            } else if (isQuantityPicker) {
                acc[stepId] = 'quantityPicker';
            } else {
                acc[stepId] = 'checkbox';
            }

            return acc;
        },
        {},
    );

    const selectedOptions = computeSelectedOptions(currentProduct, stepSelectType);

    const computedProductSteps: ComputedProductStep[] = filteredProductSteps.map((step) => {
        const selected = {
            type: stepSelectType[step.stepId],
            value: selectedOptions[stepSelectType[step.stepId]][step.stepId],
        } as Selected;

        const options = computeSelectOptions(
            menu,
            step,
            currency,
            selected,
            currentConsumptionModeType,
            !restaurantSchedule?.isCurrentlyOpen,
        );

        const handleOnChange = (
            productId: number | undefined,
            isSummary?: boolean,
            quantity?: number,
        ) => {
            if (currentProduct && currentPath) {
                setIsTouched(true);
                setCurrentProductPreviousStepId(step.stepId);

                const { compositeProduct, shouldUpdateCurrentPath }: CompositeProductWithActions =
                    computeCompositeProductWithActions(
                        productId,
                        step,
                        currentProduct,
                        stepSelectType[step.stepId],
                        isSummary,
                        quantity,
                    );

                compositeProduct &&
                    dispatch(updateCurrentStep({ compositeProduct, path: currentPath }));

                if (selected.type === 'radio' && missingSteps && productId) {
                    dispatch(
                        updateMissingSteps(
                            computeResetMissingSteps(
                                String(step.stepId),
                                currentProduct,
                                missingSteps,
                                String(productId),
                            ),
                        ),
                    );
                }

                if (selected.type !== 'radio' && missingSteps && productId) {
                    dispatch(
                        updateMissingSteps({
                            ...missingSteps,
                            [step.stepId]: { ...missingSteps[step.stepId], [productId]: 0 },
                        }),
                    );
                }

                let hasNestedSteps = false;
                if (productId) {
                    if (step?.type === StepType.CUSTOMIZATION) {
                        hasNestedSteps = !!filterCrossSellSteps(
                            menu?.products[productId].productStep,
                        ).length;
                    } else {
                        hasNestedSteps = !!menu?.products[productId].productStep.length;
                    }
                }
                if (shouldUpdateCurrentPath && hasNestedSteps) {
                    dispatch(
                        updateCurrentPath([...currentPath, String(step.stepId), String(productId)]),
                    );
                }
            }
        };

        return {
            stepId: step.stepId,
            name: step.name,
            minChoice: step.minChoice,
            maxChoice: step.maxChoice,
            isExcessAllowed: step.isExcessAllowed,
            options,
            handleOnChange,
        };
    });

    const status = computeStatus(
        computedProductSteps,
        selectedOptions,
        missingSteps,
        hasTriedAddingToCart,
    );
    const selectedSummaryItems = computeSelectedSummaryItems(menu, currentProduct, currency);

    useEffect(() => {
        /**
         * reset isTouched on currentPath change
         */
        setIsTouched(false);
    }, [currentPath]);

    const hasAlreadyScroll: Record<number, boolean> = useMemo(() => ({}), []);

    useEffect(() => {
        /**
         * force start on top in every nested product
         */
        if (isNested && !isTouched) {
            window.scrollTo({ top: 0, left: 0 });
            return;
        }

        /**
         * smooth scroll to bottom if all steps are filled in main product
         */
        const statuses = Object.entries(status);
        const allStatusesChecked = statuses.every(([, stepStatus]) => stepStatus === 'success');
        if (allStatusesChecked) {
            const quantityPicker = document.querySelector(`[data-testid="quantity-picker"]`);
            quantityPicker?.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        const previousPathPreviousStep = document.querySelectorAll(
            `[data-step-id="${previousPathPreviousStepId}"]`,
        );

        /**
         * when coming back from a nested product
         * if it is not filled => force start to previous step
         */
        if (
            !isTouched &&
            previousPathPreviousStepId &&
            status[previousPathPreviousStepId] !== 'success'
        ) {
            previousPathPreviousStep?.[0]?.scrollIntoView();
            return;
        }

        /**
         * when coming back from a nested product
         * if it is filled => force start from previous step and then smooth scroll to next not filled step
         * if no next step found => force start at previous step
         */
        if (!isTouched) {
            const nextStep = getNextStepFromPreviousStepId(
                filteredProductSteps,
                status,
                previousPathPreviousStepId,
            );
            if (nextStep.length >= 1) {
                previousPathPreviousStep?.[0]?.scrollIntoView();
                nextStep?.[0]?.scrollIntoView({ behavior: 'smooth' });
            } else {
                previousPathPreviousStep?.[0]?.scrollIntoView();
            }
            return;
        }

        /**
         * when in a current product
         * if it is filled => smooth scroll to next not filled step
         * if no next step found => do nothing (should be at the last step/the end of page)
         */
        const nextStep = getNextStepFromPreviousStepId(
            filteredProductSteps,
            status,
            currentProductPreviousStepId,
        );
        if (
            currentProductPreviousStepId &&
            status[currentProductPreviousStepId] === 'success' &&
            !hasAlreadyScroll[currentProductPreviousStepId] &&
            nextStep.length >= 1
        ) {
            hasAlreadyScroll[currentProductPreviousStepId] = true;
            nextStep?.[0]?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [
        filteredProductSteps,
        isNested,
        isTouched,
        status,
        currentPath,
        currentProductPreviousStepId,
        previousPathPreviousStepId,
        hasAlreadyScroll,
    ]);

    return {
        badgeLabel: t('mandatory'),
        computedProductSteps,
        selectedOptions,
        status,
        selectedSummaryItems,
        stepSelectType,
        missingSteps: missingSteps || {},
    };
};
