import { Text } from 'components/Text';
import {
    ErrorText,
    SelectionSummaryContainer,
    SelectionSummaryContent,
} from './SelectionSummary.style';

export type SummarySelectedItem = {
    name: string;
    value: string;
};

export type SelectionSummaryProps = {
    selectedItems: SummarySelectedItem[];
    missingItemCount?: number;
    onChange?: () => void;
};

export const SelectionSummary: React.FunctionComponent<SelectionSummaryProps> = ({
    selectedItems,
    missingItemCount,
    onChange,
}) => {
    return (
        <>
            {selectedItems && selectedItems.length > 0 && (
                <SelectionSummaryContainer
                    onClick={() => onChange && onChange()}
                    margined={!!missingItemCount}
                >
                    <SelectionSummaryContent>
                        <div>
                            {selectedItems.map(({ name, value }) => (
                                <p key={name + value} data-testid="selected-item">
                                    <Text
                                        noTranslation
                                        text={`${name} : `}
                                        weight="Bold"
                                        type={3}
                                    />
                                    <Text noTranslation text={value} weight="Regular" type={3} />
                                </p>
                            ))}
                        </div>
                    </SelectionSummaryContent>
                </SelectionSummaryContainer>
            )}
            {!!missingItemCount && (
                <div data-testid="missing-item-count-parent" onClick={() => onChange && onChange()}>
                    <ErrorText
                        weight="Bold"
                        type={3}
                        text={
                            missingItemCount === 1
                                ? 'customization_required_one'
                                : 'customization_required_other'
                        }
                        values={{
                            count: missingItemCount,
                        }}
                    />
                </div>
            )}
        </>
    );
};
