"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeGrantAmount = void 0;
var discount_types_1 = require("../../types/discount.types");
function computeGrantAmount(orderRecapDiscounts) {
    return orderRecapDiscounts
        .filter(function (discountRecap) { return discountRecap.strategy.type === discount_types_1.DISCOUNT_TYPE.GRANT; })
        .reduce(function (_grantAmount, discountRecap) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (discountRecap.additionalData && discountRecap.additionalData.usedAmount) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            _grantAmount += discountRecap.additionalData.usedAmount;
        }
        else {
            _grantAmount += discountRecap.amount;
        }
        return _grantAmount;
    }, 0);
}
exports.computeGrantAmount = computeGrantAmount;
