"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildNewItemTotalPriceNotRounded = void 0;
var discount_types_1 = require("../../../types/discount.types");
var compute_tax_rate_1 = require("../../tax-rate/compute-tax-rate");
var buildNewItemTotalPriceNotRounded = function (item, discountType, discountedAmountInclTax) {
    var newPriceInclTaxWithDiscount = item.totalPriceNotRounded.totalInclTaxWithDiscount.minus(discountedAmountInclTax);
    var newPriceExclTaxWithDiscount = (0, compute_tax_rate_1.computeExclTaxFromInclTax)(newPriceInclTaxWithDiscount, item.taxRate);
    var newItemTotalPriceNotRounded = __assign(__assign({}, item.totalPriceNotRounded), { totalInclTaxWithDiscount: newPriceInclTaxWithDiscount, totalExclTaxWithDiscount: newPriceExclTaxWithDiscount });
    if (discount_types_1.RemiseTypes.includes(discountType)) {
        var newPriceInclTaxWithRemise = item.totalPriceNotRounded.totalInclTaxWithRemise.minus(discountedAmountInclTax);
        var newPriceExclTaxWithRemise = (0, compute_tax_rate_1.computeExclTaxFromInclTax)(newPriceInclTaxWithRemise, item.taxRate);
        newItemTotalPriceNotRounded = __assign(__assign({}, newItemTotalPriceNotRounded), { totalInclTaxWithRemise: newPriceInclTaxWithRemise, totalExclTaxWithRemise: newPriceExclTaxWithRemise });
    }
    return newItemTotalPriceNotRounded;
};
exports.buildNewItemTotalPriceNotRounded = buildNewItemTotalPriceNotRounded;
