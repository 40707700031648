import { useFlags } from 'launchdarkly-react-client-sdk';

export type FeatureFlags = {
    useEdenredPayments: boolean;
    usePaygreenPayments: boolean;
};

export const useFeatureFlag = (): FeatureFlags => {
    const { useEdenredPayments, usePaygreenPayments } = useFlags<FeatureFlags>();

    return { useEdenredPayments, usePaygreenPayments };
};
