import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SvyIcon, SvyInput } from '@innovorder/savory';

export const StyledInput = styled(SvyInput)<{ isTouched?: boolean; hasError: boolean }>`
    width: 100% !important;
    margin-bottom: ${({ theme }) => theme.base.spacing[1]} !important;

    ${({ hasError, isTouched, theme }) => {
        if (!hasError && isTouched) {
            return css`
                border: 1px solid ${theme.feature.successColor} !important;

                &:focus {
                    outline: 1px solid ${theme.feature.successColor} !important;
                    border: 1px solid ${theme.feature.successColor} !important;
                }
            `;
        }
        return css``;
    }};
`;

export const TouchedSvyIcon = styled(SvyIcon)`
    color: ${({ theme }) => theme.feature.successColor};
`;

export const ErrorSvyIcon = styled(SvyIcon)`
    color: ${({ theme }) => theme.feature.errorColor};
`;

export const InputContainer = styled.div`
    width: 100%;
`;
