import styled from '@emotion/styled';
import { SvyIcon } from '@innovorder/savory';
import { Button } from 'components';
import { css } from '@emotion/react';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const ConsumptionModesContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;

export const ModeButton = styled(Button)<{ selected?: boolean }>`
    ${({ theme, selected }) => {
        if (selected)
            return css`
                --svy-button-background-color: ${theme.feature.ctaColor}1a !important;
            `;
        return css`
            --svy-button-text-color: ${theme.feature.primaryTextColor} !important;
        `;
    }}
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;

    span {
        margin-bottom: ${({ theme }) => theme.base.spacing[2]};
    }
`;

export const ModalIconHeader = styled(SvyIcon)`
    ${({ theme }) => theme.base.typography.Title5};
`;

export const ModalButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;
