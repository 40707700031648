import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { ENV } from 'utils/env';
import GooglePayForm from './GooglePayForm';

const stripePromise = loadStripe(ENV.STRIPE_PUBLIC_KEY);

const GooglePay: React.FunctionComponent = () => {
    return (
        <Elements stripe={stripePromise}>
            <GooglePayForm />
        </Elements>
    );
};

export default GooglePay;
