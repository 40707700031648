import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    AuthenticationType,
    BrandOptionName,
    BrandRestaurant,
    ModuleOrderingFlow,
    WeborderingConfiguration,
} from 'services/brand/brand.type';
import { ConsumptionMode } from 'services/consumptionMode/consumptionMode.type';
import { ConsumptionModeType } from '@innovorder/order_detail';
import { RootState } from '../store';
import { filterRestaurantById } from './selectorLibs/filterRestaurantById';

export const brandSliceName = 'brand';

export type BrandState = WeborderingConfiguration | null;

const initialState: BrandState = null;

export const brandSlice = createSlice<BrandState, SliceCaseReducers<BrandState>, string>({
    name: brandSliceName,
    initialState,
    reducers: {
        updateBrand: (state, action: PayloadAction<BrandState>) => action.payload,
    },
});

export const getBrand = (state: RootState): BrandState | null => state.brand;
export const getBrandCurrency = (state: RootState): string | undefined => getBrand(state)?.currency;
export const getBrandId = (state: RootState): number | undefined => getBrand(state)?.brandId;
export const getWebsiteMetaTitle = (state: RootState): string | undefined =>
    getBrand(state)?.module_website_meta[0].title;

export const getRestaurant =
    (restaurantId: number | null) =>
    (state: RootState): BrandRestaurant | undefined =>
        filterRestaurantById(restaurantId, getBrand(state)?.restaurants);

export const getModuleOrderingFlow =
    (restaurantId: number | null) =>
    (state: RootState): ModuleOrderingFlow | undefined =>
        getRestaurant(restaurantId)(state)?.module_ordering_flows[0];

export const getConsumptionMode =
    (restaurantId: number | null, consumptionModeType: ConsumptionModeType | null) =>
    (state: RootState): ConsumptionMode | undefined =>
        getRestaurant(restaurantId)(state)?.consumptionModes.find(
            (consumptionMode) => consumptionMode.type === consumptionModeType,
        );

export const getConsumptionModes = (
    state: RootState,
): Record<number, ConsumptionMode[]> | undefined =>
    getBrand(state)?.restaurants.reduce((acc: Record<number, ConsumptionMode[]>, restaurant) => {
        acc[restaurant.restaurantId] = restaurant.consumptionModes;
        return acc;
    }, {});

export const getMenuId =
    (restaurantId: number | null) =>
    (state: RootState): number | undefined =>
        getRestaurant(restaurantId)(state)?.menus[0].menuId; // active menu is the only menu present in array

export const getRushDelay =
    (restaurantId: number | null) =>
    (state: RootState): number | undefined =>
        getModuleOrderingFlow(restaurantId)(state)?.rushDelay;

export const getIsOrderFollowUpEnabled =
    (restaurantId: number | null) =>
    (state: RootState): boolean | undefined =>
        getModuleOrderingFlow(restaurantId)(state)?.isOrderFollowUpEnabled;

export const getConsumptionModeDelay =
    (restaurantId: number | null, consumptionModeType: ConsumptionModeType | null) =>
    (state: RootState): number | undefined =>
        getConsumptionMode(restaurantId, consumptionModeType)(state)?.delay;

export const getDelayListForCreateOrder =
    (consumptionModeType: ConsumptionModeType | null) =>
    (state: RootState): Record<number, number> => {
        const delayList: Record<number, number> = {};

        getBrand(state)?.restaurants.forEach(({ restaurantId }) => {
            const rushDelay = getRushDelay(restaurantId)(state) ?? 0;
            const consumptionModeDelay =
                getConsumptionModeDelay(restaurantId, consumptionModeType)(state) ?? 0;
            delayList[restaurantId] = rushDelay + consumptionModeDelay;
        });

        return delayList;
    };

export const getStripeUserId =
    (restaurantId: number | null) =>
    (state: RootState): string | undefined =>
        filterRestaurantById(restaurantId, getBrand(state)?.restaurants)?.moduleStripe
            ?.stripeUserId;

export const getBrandOptionStatus =
    (option: BrandOptionName) =>
    (state: RootState): boolean | null => {
        const options = getBrand(state)?.options;

        if (!options) return null;
        return Boolean(options[option]?.isActivated);
    };

export const getFoodCourtPictureMap = (state: RootState): string | null | undefined =>
    getBrand(state)?.module_ordering_flows[0].foodCourtPictureMap;

export const getAuthenticationType = (state: RootState): AuthenticationType | undefined =>
    getBrand(state)?.module_ordering_flows[0].v2AuthenticationType;

export const { updateBrand } = brandSlice.actions;
