import React from 'react';
import { Modal, Text } from 'components';
import { useProductErrorModalVM } from './useProductErrorModalVM';

export type ProductErrorModalProps =
    | {
          productId: number;
          displayStepErrorName: boolean;
      }
    | Record<string, never>;

const ProductErrorModal: React.FunctionComponent<ProductErrorModalProps> = ({
    productId,
    displayStepErrorName = false,
}) => {
    const { isOpen, productsName, errorMessage, handleConfirmModal } = useProductErrorModalVM(
        productId,
        displayStepErrorName,
    );

    return (
        <Modal
            isOpen={isOpen}
            onHide={handleConfirmModal}
            headerTitle={'modal_product_error.title_oops'}
            buttonTitle={'modal_product_error.button_confirm'}
            buttonAction={handleConfirmModal}
            data-testid="product-error-modal"
        >
            <Text text={errorMessage} values={{ productsName }} />
        </Modal>
    );
};

export default ProductErrorModal;
