import { ConsumptionModeType } from '@innovorder/order_detail';
import { WeborderingRestaurant } from 'services/brand/brand.type';
import { ConsumptionMode } from 'services/consumptionMode/consumptionMode.type';

export type DecoratedWeborderingRestaurant = Omit<WeborderingRestaurant, 'imageUrl'> & {
    imageUrl: string;
    nextOpeningOverlay?: string;
    rushDelay?: string;
};

export function isDecoratedWeborderingRestaurant(
    x: WeborderingRestaurant,
): x is DecoratedWeborderingRestaurant {
    return x.imageUrl !== null;
}

export function isPartOfTheFamily(restaurant: WeborderingRestaurant, familyId: number | null) {
    return (
        !familyId ||
        restaurant.brandRestaurantFamilies.some(
            (family) => family.brandRestaurantFamilyId === familyId,
        )
    );
}

export function isEligibleToMode(
    list: ConsumptionMode[],
    selectedMode: ConsumptionModeType,
): boolean {
    return list.some((mode) => mode.type === selectedMode);
}
