"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscountForceTotalAmount = void 0;
var discount_1 = require("./discount");
var discount_absolute_1 = require("./discount.absolute");
/**
 * The goal of this virtual discount is to force the total amount of the order to match
 * the POS's by rebalancing the delta into the products.
 * To avoid messing even further with fees, they are not to be impacted by this "discount"
 */
var DiscountForceTotalAmount = /** @class */ (function (_super) {
    __extends(DiscountForceTotalAmount, _super);
    function DiscountForceTotalAmount(data, discountables) {
        var _this = _super.call(this, data, discountables) || this;
        _this.discountables = discountables;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        _this.totalAmountTarget = data.additionalData.targetTotalAmount;
        return _this;
    }
    Object.defineProperty(DiscountForceTotalAmount.prototype, "effective", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    DiscountForceTotalAmount.prototype.discount = function () {
        var _this = this;
        if (this.filteredDiscountables.length === 0) {
            // If there are not applicable discountable, we cannot distribute the error: the order might stay incoherent
            return;
        }
        this.appliedAmount = this.apply();
        if (this.appliedAmount.isZero()) {
            return;
        }
        (0, discount_absolute_1.applyAbsoluteStrategy)(this, this.appliedAmount, function (discountable, discountAmount) {
            _this.discountableDiscountedAmounts.set(discountable.id, discountAmount);
            discountable.discount(discountAmount);
        });
    };
    DiscountForceTotalAmount.prototype.apply = function () {
        return this.discountables
            .reduce(function (sum, discountable) { return sum.minus(discountable.discountedAmount); }, this.totalAmountTarget)
            .negated();
    };
    return DiscountForceTotalAmount;
}(discount_1.Discount));
exports.DiscountForceTotalAmount = DiscountForceTotalAmount;
