import { useEffect, useMemo, useState, useCallback } from 'react';
import { ENV } from 'utils/env';
import { useAppSelector } from 'redux/store';
import { EdenredPaymentCheckoutBuilder } from '@innovorder/payment';
import { useTranslation } from 'react-i18next';
import { getBrandCurrency } from 'redux/brand';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';
import { computePrice } from 'utils/price';

export const useEdenredBalance = () => {
    const currency = useAppSelector(getBrandCurrency);
    const { value: accessToken, setInLocalStorage } = useLocalStorage('edenredAccessToken');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [balance, setBalance] = useState<number>(0);
    const { i18n } = useTranslation();
    const paymentCheckout = useMemo(
        () =>
            new EdenredPaymentCheckoutBuilder()
                .configureCheckout({
                    backendRootUrl: ENV.PAYMENT_SERVICE_URL,
                    locale: i18n.language,
                })
                .build(),
        [i18n.language],
    );

    const getEdenredBalance = useCallback(() => {
        setError(undefined);
        setIsLoading(true);
        paymentCheckout
            .getAnonymousCustomerBalance(accessToken as string)
            .then((edenredBalance) => {
                setBalance(edenredBalance);
            })
            .catch((edenredError) => {
                setError(edenredError);
                setInLocalStorage(null);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [paymentCheckout, setInLocalStorage, accessToken]);

    useEffect(() => {
        if (accessToken) getEdenredBalance();
    }, [getEdenredBalance, accessToken]);

    return {
        balance,
        currency,
        balanceWithCurrency:
            currency && Number.isInteger(balance) ? computePrice(i18n, balance, currency) : '',
        isLoading,
        error,
    };
};
