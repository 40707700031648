import React, { useEffect } from 'react';
import { ScrollContainer } from './HorizontalScrollContainer.style';

export type HorizontalScrollContainerProps = React.PropsWithChildren &
    React.HTMLAttributes<HTMLDivElement> & {
        selectedElementId?: string;
    };

export const HorizontalScrollContainer: React.FunctionComponent<HorizontalScrollContainerProps> = ({
    selectedElementId,
    children,
    ...rest
}) => {
    useEffect(() => {
        if (!selectedElementId) return;

        const containerElement = document.getElementById('horizontal-scroll-container');
        const selectedElement = document.getElementById(selectedElementId);
        const PADDING_OFFSET = 10;

        if (containerElement && selectedElement) {
            containerElement.scrollTo({
                behavior: 'smooth',
                left:
                    selectedElement.getBoundingClientRect().left +
                    containerElement.scrollLeft -
                    PADDING_OFFSET,
            });
        }
    }, [selectedElementId]);

    return (
        <ScrollContainer
            id="horizontal-scroll-container"
            data-testid="horizontal-scroll-menu"
            {...rest}
        >
            {children}
        </ScrollContainer>
    );
};
