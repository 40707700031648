"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapProductToProductRecap = void 0;
var types_1 = require("../../../types");
var compute_original_unit_price_1 = require("../../utils-computing/compute-original-unit-price");
var mapProductToProductRecap = function (product) { return ({
    id: product.id,
    productCartId: product.productCartId,
    label: product.label,
    orderItemType: types_1.DISCOUNTABLE_TYPE.PRODUCT,
    taxRate: product.taxRate,
    totalPrice: product.totalPriceRounded,
    unitPrice: product.unitPriceRounded,
    originalUnitPrice: (0, compute_original_unit_price_1.computeOriginalUnitPrice)(product),
    depth: product.depth,
    quantity: product.quantity,
    tags: product.tags,
    billingCode: product.billingCode,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    additionalData: product.additionalData,
    sku: product.sku,
    purchasePrice: product.purchasePrice,
}); };
exports.mapProductToProductRecap = mapProductToProductRecap;
