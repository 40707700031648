import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${({ theme }) => theme.base.spacing[2]};
`;

export const OptionContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
`;

export const OptionContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const SummaryContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;

export const OptionNameContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.base.spacing[1]};
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const OptionNameContent = styled.div`
    span {
        padding-right: ${({ theme }) => theme.base.spacing[1]};
    }
`;

export const OptionQuantityPickerContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.base.spacing[1]};
    height: fit-content;
    width: fit-content;
`;
