import { PaygreenIntentRequiredAction } from 'services/order/order.type';

export const isPaygreenIntentRequiredAction = (
    requireAction: PaygreenIntentRequiredAction | null,
): boolean => {
    if (!requireAction) {
        return false;
    }
    return !!requireAction?.paymentUrl;
};
