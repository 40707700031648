import React from 'react';
import { Link } from 'react-router-dom';
import { TranslationKey } from 'locales/fr';
import { RoutesPath } from 'core/routes.types';
import { useRedirectToHome } from 'hooks/useRedirectHome/useRedirectHome';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { SvyIcon } from '@innovorder/savory';
import { useTranslation } from 'react-i18next';
import {
    MargedButton,
    MargedCaption,
    MargedTitle,
    StyledIcon,
    TitleContainer,
} from './Error.style';
import { ErrorEnum, ErrorProps } from './Error.type';

type CommonErrorConfiguration = { titleText: TranslationKey; text: TranslationKey };

const ErrorConfiguration: Record<
    ErrorEnum,
    | CommonErrorConfiguration
    | (CommonErrorConfiguration & { redirectToHome: true; buttonText: 'not_found_back_to_home' })
    | (CommonErrorConfiguration & { redirectToCart: true; buttonText: TranslationKey })
    | (CommonErrorConfiguration & { linkTo: string; buttonText: TranslationKey })
> = {
    [ErrorEnum.CART_ERROR]: {
        titleText: 'cart_error_title',
        text: 'cart_error_content',
        redirectToCart: true,
        buttonText: 'back_to_cart',
    },
    [ErrorEnum.ORDER_ERROR]: {
        titleText: 'order_error_title',
        text: 'order_error_content',
        linkTo: `../${RoutesPath.ChoosePayment}`,
        buttonText: 'retry',
    },
    [ErrorEnum.NOT_FOUND]: {
        titleText: 'error_title',
        text: 'not_found_message',
        buttonText: 'not_found_back_to_home',
        redirectToHome: true,
    },
    [ErrorEnum.NO_RESTAURANT]: {
        titleText: 'error_title',
        text: 'restaurants.no_restaurant_error',
        linkTo: RoutesPath.Home,
        buttonText: 'not_found_back_to_home',
    },
    [ErrorEnum.NO_CONSUMPTION_MODE]: {
        titleText: 'error_title',
        text: 'no_consumption_mode',
    },
    [ErrorEnum.QR_CODE_ERROR]: {
        titleText: 'error_title',
        text: 'qrcode_error',
    },
};

const Error: React.FunctionComponent<ErrorProps> = ({ error }) => {
    const { t } = useTranslation();
    const { redirectToHome } = useRedirectToHome();
    const { goCart } = useWorkflowStateMachine();
    const errorConfig = ErrorConfiguration[error];

    const onClick = 'redirectToHome' in errorConfig ? redirectToHome : goCart;

    return (
        <>
            <TitleContainer>
                <StyledIcon icon="close-circle-line" />
                <MargedTitle text={errorConfig.titleText} type={5} />
            </TitleContainer>
            <MargedCaption text={errorConfig.text} />
            {'buttonText' in errorConfig && (
                <>
                    {'linkTo' in errorConfig ? (
                        <Link to={errorConfig.linkTo}>
                            <MargedButton
                                fullWidth
                                color="destructive"
                                elementLeft={<SvyIcon icon="arrow-left-s-line" />}
                            >
                                {t(errorConfig.buttonText)}
                            </MargedButton>
                        </Link>
                    ) : (
                        <MargedButton
                            fullWidth
                            onClick={onClick}
                            color="destructive"
                            elementLeft={<SvyIcon icon="arrow-left-s-line" />}
                        >
                            {t(errorConfig.buttonText)}
                        </MargedButton>
                    )}
                </>
            )}
        </>
    );
};

export default Error;
