import { ProductItem, Text } from 'components';
import React from 'react';
import { ProductAttributesFromCategories } from 'services/menu/menu.type';
import {
    CategorySeparator,
    ListContainer,
    ProductListContainer,
    ProductListTitle,
} from './ProductList.style';
import { useProductListVM } from './useProductListVM';

type Props = {
    categoryId: number;
    productList: ProductAttributesFromCategories[];
    title: string;
    currency: string;
    onProductClick?: (id: number) => void;
};

const ProductList: React.FunctionComponent<Props> = ({
    categoryId,
    productList,
    currency,
    title,
    onProductClick,
}) => {
    const { computedProductList } = useProductListVM(productList);

    return (
        <ProductListContainer>
            <ProductListTitle text={title} noTranslation type={4} isUnderlined={true} />
            <ListContainer data-testid="list-container" id={`category-${String(categoryId)}`}>
                {computedProductList.length ? (
                    computedProductList.map((product) => (
                        <ProductItem
                            {...product}
                            key={product.productId}
                            currency={currency}
                            onClick={onProductClick}
                        />
                    ))
                ) : (
                    <Text text={'empty_product_list'} />
                )}
            </ListContainer>
            <CategorySeparator />
        </ProductListContainer>
    );
};

export default ProductList;
