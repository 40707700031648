import { FunctionComponent } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Container } from './MobileLayout.style';

const MobileLayout: FunctionComponent = () => {
    return (
        <Container data-testid="mobile-layout">
            <ScrollRestoration />
            <Outlet />
        </Container>
    );
};

export default MobileLayout;
