import React from 'react';
import { Title } from 'components';
import { TranslationKey } from 'locales/fr';
import ProductErrorModal from 'components/ProductErrorModal';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { CartRestaurantSubtitle, EmptyRestaurantContainer } from './EmptyRestaurants.style';

type EmptyRestaurantsProps = {
    subtitle: TranslationKey;
};

const EmptyRestaurants: React.FunctionComponent<EmptyRestaurantsProps> = ({ subtitle }) => {
    return (
        <>
            <EmptyRestaurantContainer>
                <SVGIcon icon={SVGIcons.EMPTY} />
                <Title text={'restaurants.no_restaurants'} type={4} />
                <CartRestaurantSubtitle text={subtitle} />
            </EmptyRestaurantContainer>

            <ProductErrorModal />
        </>
    );
};

export default EmptyRestaurants;
