import React from 'react';

import { AccountButtonContainer, SizedSvyIcon } from './AccountButton.style';
import { useAccountButton } from './useAccountButton';

export const AccountButton: React.FunctionComponent = () => {
    const { navigateToAccount, shouldDisplayButton, ariaLabel } = useAccountButton();

    if (!shouldDisplayButton) return <></>;

    return (
        <AccountButtonContainer
            type="button"
            data-testid="account-button"
            aria-label={ariaLabel}
            onClick={navigateToAccount}
            size="s"
        >
            <SizedSvyIcon data-testid="account-icon" icon="user-line" />
        </AccountButtonContainer>
    );
};
