"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertUnreachable = exports.permuteArray = exports.isValidPermutation = exports.round = exports.enumValues = exports.enumKeys = void 0;
var lodash_1 = require("lodash");
var enumKeys = function (theEnum) {
    return Object.keys(theEnum).filter(function (key) { return Number.isNaN(Number(key)); });
};
exports.enumKeys = enumKeys;
var enumValues = function (theEnum) { return (0, exports.enumKeys)(theEnum).map(function (key) { return theEnum[key]; }); };
exports.enumValues = enumValues;
var round = function (value, decimal) { return Math.round(Math.pow(10, decimal) * value) / Math.pow(10, decimal); };
exports.round = round;
var isValidPermutation = function (permutation) {
    var sortedPermutation = __spreadArray([], permutation, true).sort();
    var expectedSortedPermutation = Array.from(Array(permutation.length).keys()); // [0, 1, 2, ..., n]
    return (0, lodash_1.isEqual)(sortedPermutation, expectedSortedPermutation);
};
exports.isValidPermutation = isValidPermutation;
var permuteArray = function (array, permutation) {
    if (array.length !== permutation.length) {
        throw new Error('permutation length must be equal to the array length');
    }
    if (!(0, exports.isValidPermutation)(permutation)) {
        throw new Error('permutation must be a valid permutation');
    }
    return permutation.map(function (permutationIndex) { return array[permutationIndex]; });
};
exports.permuteArray = permuteArray;
// This function is used when emulating exhaustive pattern matching in TypeScript which switch case statements
var assertUnreachable = function (x) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error("assertUnreachable called with ".concat(x));
};
exports.assertUnreachable = assertUnreachable;
