import React from 'react';
import {
    Button,
    Input,
    Title,
    PageWithBottomBarContainer,
    HeaderTitle,
    WithHeader,
    BottomBar,
} from 'components';
import { useTranslation } from 'react-i18next';
import { CartStepper } from 'components/CartStepper';
import { useUserInformationVM } from './useUserInformationVM';
import { UserInformationContentContainer, UserNameContainer } from './UserInformation.style';

const UserInformation: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { buttonText, userName, handleUpdateUserName, goNext, USER_NAME_LENGTH_MIN } =
        useUserInformationVM();

    return (
        <WithHeader isSticky={true}>
            <CartStepper />
            <PageWithBottomBarContainer>
                <HeaderTitle>
                    <Title text={'user_information.title'} type={5} />
                </HeaderTitle>

                <UserInformationContentContainer>
                    <UserNameContainer>
                        <Input
                            onChange={handleUpdateUserName}
                            autoFocus={false}
                            placeholder={'user_information.input_placeholder'}
                            value={userName || ''}
                            label={t('user_information.name')}
                            labelDetail={t('user_information.mandatory')}
                            helperText={t('user_information.name_description')}
                            data-testid="user-name-input"
                        />
                    </UserNameContainer>
                </UserInformationContentContainer>

                <BottomBar withPadding>
                    <Button
                        fullWidth
                        onClick={goNext}
                        data-testid="validate-btn"
                        size="l"
                        disabled={!userName || userName.length < USER_NAME_LENGTH_MIN}
                    >
                        {buttonText}
                    </Button>
                </BottomBar>
            </PageWithBottomBarContainer>
        </WithHeader>
    );
};

export default UserInformation;
