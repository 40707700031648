import styled from '@emotion/styled';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { SvyBadge, SvyIcon } from '@innovorder/savory';
import { keyframes } from '@emotion/react';
import { Chips } from 'components/Chips';
import { Caption } from 'components/Caption';

const animation = {
    duration: '.5s',
    transition: 'cubic-bezier(0.87, 0, 0.13, 1)',
};

const slideUp = keyframes`
    0% {
        height: var(--radix-collapsible-content-height);
    }
    100% {
        height: 0px;
    }
`;

const slideDown = keyframes`
    0% {
        height: 0px;
    }
    100% {
        height: var(--radix-collapsible-content-height);
    }
`;

export const StyledImg = styled.img`
    margin-right: ${({ theme }) => theme.base.spacing[1]};
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid ${({ theme }) => theme.feature.thirdaryTextColor};
    border-radius: 2px;
`;

export const OrdersAccordionRoot = styled(RadixAccordion.Root)`
    max-width: auto;
`;

export const OrdersAccordionItem = styled(RadixAccordion.Item)`
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
    padding-bottom: ${({ theme }) => theme.base.spacing[1]};
    position: relative;

    &:not(:last-of-type):after {
        content: '';
        display: block;
        bottom: 0px;
        left: 0px;
        width: calc(100% - ${({ theme }) => theme.base.spacing[5]});
        transition: width ${animation.duration} ${animation.transition};
        height: 1px;
        background-color: ${({ theme }) => theme.base.colors.secondary[200]};
    }

    &[data-state='open'] {
        &:after {
            width: 100%;
        }
    }
`;

export const OrdersAccordionContent = styled(RadixAccordion.Content)`
    position: relative;
    overflow: hidden;

    &[data-state='open'] {
        animation: ${slideDown} ${animation.duration} ${animation.transition};
    }
    &[data-state='closed'] {
        animation: ${slideUp} ${animation.duration} ${animation.transition};
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & h2 {
        text-align: left;
    }
`;

export const OrdersAccordionHeader = styled(RadixAccordion.Header)`
    width: 100%;
`;

export const SpaceBetweenContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const OrdersAccordionTrigger = styled(RadixAccordion.Trigger)`
    border: none;
    background: none;
    position: absolute;
    right: 0px;
    top: 86px;
    transform: translateY(-50%);
    z-index: 1;
    & * {
        transition: transform ${animation.duration} ${animation.transition};
    }
    &[aria-expanded='true'] .ri-arrow-down-s-line::before {
        transform: rotate(180deg);
    }
`;

export const SpacingContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]} 0px;
`;

export const StyledBadge = styled(SvyBadge)`
    min-width: fit-content;
    margin-left: ${({ theme }) => theme.base.spacing[1]};
`;

export const StyledIcon = styled(SvyIcon)`
    &:before {
        display: block;
        transition: transform ${animation.duration} ${animation.transition};
        ${({ theme }) => theme.base.typography.Title4};
        font-weight: normal;
    }
`;

export const OrdersAccordionTitle = styled(Caption)`
    word-break: break-all;
    ${({ theme }) => theme.base.typography.Title5}
`;

export const OrderNumber = styled(Chips)`
    ${({ theme }) => theme.base.typography['Body/Bold/Text2']}
    background-color: ${({ theme }) => theme.base.colors.primary[100]};
    color: ${({ theme }) => theme.base.colors.primary[900]};
    width: fit-content;
    height: fit-content;
    min-width: fit-content;
    margin-left: calc(${({ theme }) => theme.base.spacing[1]} / 2);
    padding: 2px ${({ theme }) => theme.base.spacing[1]};
`;

export const ArrowIndicator = styled.div`
    width: ${({ theme }) => theme.base.spacing[4]};
    height: ${({ theme }) => theme.base.spacing[4]};
    border-radius: ${({ theme }) => theme.base.radius.rounded};
    background-color: ${({ theme }) => theme.base.colors.secondary[50]};
    box-shadow: ${({ theme }) => theme.base.shadow.outside[300]};
    font-size: ${({ theme }) => theme.base.spacing[3]};
`;
