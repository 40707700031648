import styled from '@emotion/styled';
import { Text } from 'components/Text';

export const StyledItemContainer = styled.li`
    padding: ${({ theme }) => theme.base.spacing[1]} 0;
    max-width: 100%;
    list-style: none;
`;

export const ItemContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 90px;
`;

export const ItemTextContainer = styled.div`
    padding-top: ${({ theme }) => theme.base.spacing[1]};
    padding-right: ${({ theme }) => theme.base.spacing[2]};
    max-width: 70%;
`;

export const ItemTitle = styled(Text)`
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;

export const StyledPrice = styled(Text)`
    display: block;
    margin-top: ${({ theme }) => theme.base.spacing[1]};
`;

export const ItemDescription = styled.div`
    margin-top: ${({ theme }) => theme.base.spacing[1]};
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-height: 4em;
`;

export const ProductImage = styled.div`
    height: 90px;
    min-width: 90px;
    background-position: center center;
    background-size: 100%;
    border-radius: ${({ theme }) => theme.base.radius.xl};
    background-repeat: no-repeat;
    background-size: cover;
`;
