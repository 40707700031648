import { PropsWithChildren } from 'react';
import { ChipsContainer } from './Chips.style';

export type ChipsType = 'primary' | 'secondary' | 'success' | 'error' | 'warning';

export type ChipsProps = {
    onClick?: () => void;
};

export const Chips: React.FunctionComponent<PropsWithChildren<ChipsProps>> = ({
    children,
    ...otherProps
}) => {
    return (
        <ChipsContainer data-testid="chips" {...otherProps}>
            {children}
        </ChipsContainer>
    );
};
