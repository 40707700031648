import { ApiResponse } from '../providers/innovorder.types';
import { innovorderApi } from '../index';
import { GetCustomerBalancePayload } from './customer.type';

const customerApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getCustomerBalance: build.query<{ customerBalance: number }, GetCustomerBalancePayload>({
            query: ({ customerId, token }) => ({
                url: `/customers/${customerId}/balance`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<{ customerBalance: number }>) =>
                response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetCustomerBalanceQuery } = customerApi;
