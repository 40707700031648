import React from 'react';
import parse from 'html-react-parser';
import {
    Button,
    PriceBreakDownSummary,
    Title,
    Loader,
    HeaderTitle,
    WithHeader,
    BottomBar,
    Text,
} from 'components';
import { CartItemDisplayer } from 'components/CartItemDisplayer';
import { CartStepper } from 'components/CartStepper';
import {
    StyledTitle,
    Container,
    CommentContainer,
    MargedContainer,
    Content,
    LoaderContainer,
} from './OrderConfirmation.style';
import { useOrderConfirmationVM } from './useOrderConfirmationVM';
import StripeRequireAction from '../StripeRequireAction';
import EmailReceiptModal from '../EmailReceiptModal';
import { useOrderVM } from '../useOrderVM';
import PaygreenRequireAction from '../PaygreenRequireAction';

const OrderConfirmation: React.FunctionComponent = () => {
    const {
        isPaymentConfirmed,
        hasStripeRequiredAction,
        hasPaygreenRequiredAction,
        paygreenPaymentUrl,
        isFoodCourt,
        showEmailReceiptModal,
        handleShowEmailReceiptModal,
        handleHideEmailReceiptModal,
        isAuthenticated,
    } = useOrderVM();
    const {
        buttonText,
        isLoading,
        isPaid,
        priceBreakdown,
        currency,
        comment,
        restaurantOrders,
        discounts,
        entranceFees,
        grants,
        redirectToHome,
    } = useOrderConfirmationVM();

    if (hasStripeRequiredAction && !isPaymentConfirmed) {
        return <StripeRequireAction />;
    }

    if (hasPaygreenRequiredAction && paygreenPaymentUrl) {
        return <PaygreenRequireAction paymentUrl={paygreenPaymentUrl} />;
    }

    if (hasStripeRequiredAction && isPaymentConfirmed) {
        return (
            <LoaderContainer>
                <Loader size={50} />
                <Text text={'order_loading'} type={2} />
            </LoaderContainer>
        );
    }

    if (isLoading) {
        return (
            <LoaderContainer>
                <Loader size={50} />
            </LoaderContainer>
        );
    }

    return (
        <WithHeader
            isSticky={true}
            onClickBack={redirectToHome}
            onClickLogo={redirectToHome}
            hasAccountButton
        >
            <CartStepper />
            <Container>
                <HeaderTitle>
                    <StyledTitle text={'order_detail'} type={3} />
                </HeaderTitle>

                <Content>
                    <div>
                        {comment && (
                            <CommentContainer data-testid="comment">
                                <Title text={`“${parse(comment)}“`} type={5} noTranslation />
                            </CommentContainer>
                        )}

                        {currency && (
                            <MargedContainer data-testid="products">
                                <CartItemDisplayer
                                    isFoodCourt={isFoodCourt}
                                    restaurantOrders={restaurantOrders}
                                    currency={currency}
                                />
                            </MargedContainer>
                        )}
                    </div>

                    {priceBreakdown && currency && (
                        <MargedContainer data-testid="price-breakdown">
                            <PriceBreakDownSummary
                                {...priceBreakdown}
                                discounts={discounts}
                                entranceFees={entranceFees}
                                grants={grants}
                                currency={currency}
                                isLoading={isLoading}
                            />
                        </MargedContainer>
                    )}
                </Content>

                {isPaid && !isAuthenticated && (
                    <BottomBar withPadding>
                        <Button fullWidth onClick={handleShowEmailReceiptModal}>
                            {buttonText}
                        </Button>
                    </BottomBar>
                )}

                <EmailReceiptModal
                    isOpen={showEmailReceiptModal}
                    onHide={handleHideEmailReceiptModal}
                />
            </Container>
        </WithHeader>
    );
};

export default OrderConfirmation;
