import styled from '@emotion/styled';

export const Container = styled.div`
    position: relative;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${({ theme }) => theme.base.background}');
`;
