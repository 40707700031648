"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateApplyScopeId = exports.getPosAbsoluteDiscountScopeFilter = exports.getPosDiscountScopeFilter = exports.promoCodeRegexp = exports.extractCustomizationsScopeIds = exports.findFormulaChildren = exports.generateCustomizationApplyScopeId = void 0;
var types_1 = require("../../../types");
var big_numbers_1 = require("../../../utils/big-numbers");
var types_2 = require("../types");
var generateCustomizationApplyScopeId = function (orderItem, parentIndex, index) { return "".concat(parentIndex, "-").concat(index, "-").concat(orderItem.customizationProductSourceId); };
exports.generateCustomizationApplyScopeId = generateCustomizationApplyScopeId;
var findFormulaChildren = function (forumlaSourceId, orderItems) {
    return orderItems.filter(function (orderItem) { return orderItem.parentSourceId === forumlaSourceId; });
};
exports.findFormulaChildren = findFormulaChildren;
var extractCustomizationsScopeIds = function (_a) {
    var customizations = _a.customizations, index = _a.index;
    return (customizations || []).map(function (customization, customizationIndex) {
        return (0, exports.generateCustomizationApplyScopeId)(customization, index, customizationIndex);
    });
};
exports.extractCustomizationsScopeIds = extractCustomizationsScopeIds;
exports.promoCodeRegexp = /Code Promo '([^]+)' TVA+/;
var generateManualDiscountScopeFilter = function (orderItems, isDiscountAppliedOnItem) { return ({
    type: types_1.DiscountFilterType.MANUAL,
    value: new Set(orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_2.PosOrderItemType.PRODUCT; })
        .reduce(function (productIds, orderItem) {
        var applyScopeId = orderItem.applyScopeId, sourceId = orderItem.sourceId, discount = orderItem.discount, menu = orderItem.menu;
        if (isDiscountAppliedOnItem(discount)) {
            productIds = __spreadArray(__spreadArray(__spreadArray([], productIds, true), [applyScopeId], false), (0, exports.extractCustomizationsScopeIds)(orderItem), true);
            productIds.push(applyScopeId);
            if (menu) {
                productIds = __spreadArray(__spreadArray([], productIds, true), (0, exports.findFormulaChildren)(sourceId, orderItems).reduce(function (_applyScopeIds, _orderIrem) {
                    _applyScopeIds = __spreadArray(__spreadArray(__spreadArray([], _applyScopeIds, true), [
                        _orderIrem.applyScopeId
                    ], false), (0, exports.extractCustomizationsScopeIds)(_orderIrem), true);
                    return _applyScopeIds;
                }, []), true);
            }
        }
        return productIds;
    }, [])),
}); };
var getPosDiscountScopeFilter = function (orderItems, discountRate) {
    var isDiscountAppliedOnItem = function (discount) { return big_numbers_1.big1.minus(discount).shiftedBy(4).isEqualTo(discountRate); };
    return generateManualDiscountScopeFilter(orderItems, isDiscountAppliedOnItem);
};
exports.getPosDiscountScopeFilter = getPosDiscountScopeFilter;
var getPosAbsoluteDiscountScopeFilter = function (orderItems) {
    var isDiscountAppliedOnItem = function (discount) { return big_numbers_1.big1.minus(discount).isGreaterThan(big_numbers_1.big0); };
    return generateManualDiscountScopeFilter(orderItems, isDiscountAppliedOnItem);
};
exports.getPosAbsoluteDiscountScopeFilter = getPosAbsoluteDiscountScopeFilter;
var generateApplyScopeId = function (orderItem, index) {
    orderItem.applyScopeId = "".concat(index, "-").concat(orderItem.sourceId);
    orderItem.index = index;
    return orderItem;
};
exports.generateApplyScopeId = generateApplyScopeId;
