import { ConsumptionModeType, EnrichedProduct, OrderRecap } from '@innovorder/order_detail';
import { BasicError, ErrorStatusCode } from 'services/providers/innovorder.types';
import { BrandRestaurant } from 'services/brand/brand.type';
import { StepType } from 'services/menu/menu.type';

export type { OrderRecap, ProductRecap, PriceBreakdown } from '@innovorder/order_detail';

export enum ChannelId {
    WEB = 2,
}

type BaseOrder = {
    restaurantId: number;
    channelId: ChannelId.WEB;
    consumptionMode: ConsumptionModeType;
    menuId: number;
    promocode?: string;
    comment?: string;
    tableName?: string;
    userName?: string;
};

export type OrderToVerify = BaseOrder & {
    cart: Cart;
    customerId?: number;
};

export type OrderVerified = BaseOrder & {
    cart: EnrichedProduct[];
    recap: OrderRecap;
};

export type OrderCreated = OrderVerified & {
    orderId: number;
    orderUuid: string;
    requiredAction: StripeIntentRequiredAction | null;
    mainStatus: OrderMainStatus | null;
};

export type Cart = ProductDTO[];

export type ProductDTO = {
    productId: number;
    productCartId?: string;
    quantity: number;
    steps: StepDTO[];
    crossSelling?: CrossSelling;
};

export type CrossSelling = {
    stepId: number;
    productId: number;
};

export type StepDTO = {
    stepId: number;
    products: ProductDTO[];
    type?: StepType;
};

export type OrderToCreate = OrderToVerify & {
    expectedAt: string;
    payments: PaymentDTO[];
};

export type PaymentDTO = {
    paymentMethodId: number;
    amount?: number;
    quantity: number;
    currency?: string;
    metadata?: string;
};

export type StripeIntentRequiredAction = {
    intentId: string;
    requires_action: boolean;
    payment_intent_client_secret: string;
};

export type PaygreenIntentRequiredAction = {
    transactionId: string;
    requires_action: boolean;
    paymentUrl: string;
};

export type OrderConfirmPayload = {
    orderId: number;
    orderUuid: string;
    restaurantId: number;
    channelId: ChannelId;
    paymentIntentId: string;
};

export type GetOrderInformationsPayload = {
    orderUuid: string;
    restaurantId: number;
    channelId: ChannelId;
};

export enum OrderItemType {
    PRODUCT = 'Product',
    LOYALTY = 'Loyalty',
    DISCOUNT = 'Discount',
    GRANT = 'Grant',
    ENTRANCE_FEE = 'EntranceFee',
    DELIVERY = 'Delivery',
    SERVICE = 'Service',
    URSSAFCOMPENSATORYPRODUCT = 'UrssafCompensatoryProduct',
}

// This type comes from the API and is not used yet
export enum OrderMainStatus {
    ORDER_PLACED,
    ORDER_VALIDATED,
    ORDER_PREPARING,
    ORDER_PREPARED,
    ORDER_PROCESSED,
    ORDER_CANCELLED,
    ORDER_REFUSED,
    ORDER_COULD_NOT_BE_PROCESSED,
    ORDER_AWAITING_ACTION,
}

export type TicketStatus =
    | 'WAITING'
    | 'DISPLAYING'
    | 'TO_DISPLAY'
    | 'TO_DO'
    | 'MODIFIED'
    | 'TO_CANCEL'
    | 'CANCELLED'
    | 'DONE';

export const FollowUpStatuses = [
    'COLLECTED',
    'READY',
    'PROCESSING',
    'WAITING',
    'CANCELLED',
    'LOADING',
] as const;
export type FollowUpStatus = typeof FollowUpStatuses[number];

export enum ReportItemStatus {
    PAID = 'PAID',
    VALIDATED = 'VALIDATED', // Not sure this one exists but I don't know for sure, queries timeout in prod environment
    CANCELLED = 'CANCELLED',
}

export enum ReportItemType {
    PRODUCT = 'PRODUCT',
    CUSTOMIZATION = 'CUSTOMIZATION',
    FEE = 'FEE',
    URSSAFCOMPENSATORYPRODUCT = 'URSSAFCOMPENSATORYPRODUCT',
}

export type Item = {
    itemId: number;
    parentItemId: number | null;
    name: string;
    type: OrderItemType;
    status: ReportItemStatus;
    quantity: number;
    totalPriceWithTaxIncluded: number;
    itemType: ReportItemType;
    vatValue: number;
    skuValue: string | null;
    free: boolean;
    tags: {
        value: string;
    }[];
    purchasePrice: number | null;
    billingCode: string | null;
    discounted: boolean;
    reduced: boolean;
    service: number;
    totalItemDiscounted: number;
    totalPriceDiscounted: number;
    cancelReason: string | null;
    deviceName: string;
    deviceId: string;
};

export type EntranceFee = {
    labelTicket: string;
    amount: number;
};

export type Grant = {
    labelTicket: string;
    amount: number;
};

export type OrderInformations = {
    orderUuid: string;
    status: 'PAID' | string;
    orderId: number;
    omnichannelOrderId: string;
    restaurantId: number;
    dailyOrderId: number;
    totalPriceDiscountedWithTaxExcluded: number;
    totalPriceDiscountedWithTaxIncluded: number;
    totalPriceWithTaxExcluded: number;
    totalPriceWithTaxIncluded: number;
    totalTax: number;
    items: Item[];
    discounts: Discount[];
    grants: Grant[];
    entranceFees: EntranceFee[];
    ticketNumber?: string;
    currency: string;
    comment: string;
    cartRawData: string;
    restaurant: BrandRestaurant;
    mainStatus: OrderMainStatus;
    startDate: string;
    createdAt?: string;
};

export type SendAnonymousOrderReceiptPayload = {
    orderUuid: string;
    email: string;
    channelId: ChannelId;
};

export type Discount = {
    name: string;
    value: number;
};

export type OrderErrorPayload = {
    status: ErrorStatusCode;
    data: FormattedSingleOrderError;
};
export type MultiOrderErrorPayload = {
    status: ErrorStatusCode;
    data: FormattedMultiOrderError;
};

export type CartStep = {
    value: string;
    nestedSteps?: string;
};

export type CartProduct = {
    productId: number;
    productCartId?: string;
    name: string;
    quantity: number;
    price: number;
    unitPrice: number;
    steps: CartStep[];
    imageUrl?: string | undefined;
    restaurantId?: number;
    menuId?: number;
};

export type SingleOrderConfirm = (StripeIntentRequiredAction | PaygreenIntentRequiredAction) & {
    orderUuid: string;
    orderId: number;
};

export type FormattedSingleOrderError = BasicError & {
    extraData: {
        errors: Array<BasicError & { extraData: ValidationCart }>;
        restaurantId: number;
        [key: string]: unknown;
    };
};

export type FormattedMultiOrderError = BasicError & {
    extraData: {
        errors: Array<{
            extraData: {
                errors: Array<BasicError & { extraData: ValidationCart }>;
                restaurantId: number;
                [key: string]: unknown;
            };
            [key: string]: unknown;
        }>;
        [key: string]: unknown;
    };
};

export const isSingleOrderError = (
    extraDataError: FormattedSingleOrderError['extraData'] | FormattedMultiOrderError['extraData'],
): extraDataError is FormattedSingleOrderError['extraData'] => {
    return (extraDataError as FormattedSingleOrderError['extraData']).restaurantId !== undefined;
};

export type ValidationStatus = 'VALID' | 'INVALID';

export type ValidationCart = {
    status: ValidationStatus;
    products: ValidationProduct[];
    [key: string]: unknown;
};

export type ValidationStep = {
    status: ValidationStatus;
    reasons: BasicError[];
    products: ValidationProduct[];
} & Omit<StepDTO, 'products'>;

export type ValidationProduct = {
    status: ValidationStatus;
    reasons: BasicError[];
    steps: ValidationStep[];
} & Omit<ProductDTO, 'steps'>;
