import { useTheme } from '@emotion/react';
import { backButtonSize } from 'components/BackButton/BackButton.style';
import { useInView } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';

export const useSimpleProductVM = () => {
    const theme = useTheme();
    const ref = useRef<HTMLSpanElement | null>(null);
    const isProductNameInView = useInView(ref, { margin: `-${theme.base.spacing[2]}` });
    const [showFullPortal, setShowFullPortal] = useState<boolean | null>(null);

    const handleScrollTop = () => {
        window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    };

    useEffect(() => {
        /**
         * Product name is in view but useInView does not find it directly.
         * The following condition allows us to wait for the hook to find it.
         */
        if (showFullPortal === null) {
            isProductNameInView && setShowFullPortal(false);
            return;
        }

        setShowFullPortal(!isProductNameInView);
    }, [isProductNameInView, showFullPortal]);

    return {
        showFullPortal,
        handleScrollTop,
        variants: {
            small: { width: backButtonSize },
            full: { width: `calc(100% - ${theme.base.spacing[4]})` },
        },
        ref,
    };
};
