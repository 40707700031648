"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscountByRateCompensation = exports.DiscountByRate = void 0;
var types_1 = require("../../adapters/pos-order-adapter/types");
var discount_types_1 = require("../../types/discount.types");
var big_numbers_1 = require("../../utils/big-numbers");
var product_1 = require("../product/product");
var discount_1 = require("./discount");
var discount_absolute_1 = require("./discount.absolute");
var discount_helper_1 = require("./discount-helper");
var DiscountByRate = /** @class */ (function (_super) {
    __extends(DiscountByRate, _super);
    function DiscountByRate(rate, data, discountables) {
        var _this = _super.call(this, data, discountables) || this;
        _this.rate = rate;
        _this.discountables = discountables;
        _this.ceiling = (0, discount_helper_1.getCeilingFromOrderDetailDiscount)(data);
        return _this;
    }
    Object.defineProperty(DiscountByRate.prototype, "effective", {
        get: function () {
            return this.type !== discount_types_1.DISCOUNT_TYPE.GRANT || !!this.filteredDiscountables.length;
        },
        enumerable: false,
        configurable: true
    });
    DiscountByRate.prototype.discount = function () {
        var _this = this;
        this.discountableDiscountedAmounts = new Map();
        this.appliedAmount = big_numbers_1.big0;
        this.filteredDiscountables.forEach(function (discountable) {
            var discountAmount = _this.apply(discountable.discountedAmount);
            if (_this.ceiling && _this.appliedAmount.plus(discountAmount).isGreaterThan(_this.ceiling)) {
                discountAmount = (0, big_numbers_1.max)(_this.ceiling.minus(_this.appliedAmount), big_numbers_1.big0);
            }
            _this.discountableDiscountedAmounts.set(discountable.id, discountAmount);
            _this.appliedAmount = _this.appliedAmount.plus(discountAmount);
            discountable.discount(discountAmount);
            if (discount_types_1.RemiseTypes.includes(_this.type) &&
                (!(discountable instanceof product_1.Product) ||
                    (discountable.data &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        discountable.data.additionalData &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        discountable.data.additionalData.orderItemType !== types_1.PosOrderItemType.COMPENSATION))) {
                var remiseAmount = _this.apply(discountable.remisedAmount);
                discountable.applyRemise(remiseAmount);
            }
        });
    };
    DiscountByRate.prototype.apply = function (amount) {
        return amount.multipliedBy(this.rate.dividedBy(big_numbers_1.big10000)).integerValue();
    };
    return DiscountByRate;
}(discount_1.Discount));
exports.DiscountByRate = DiscountByRate;
// The DiscountByRateCompensation correct the rounding errors that might occur by applying
// the discount rate one by one compared to the application of a single totalRate (this.rate) of a scope
var DiscountByRateCompensation = /** @class */ (function (_super) {
    __extends(DiscountByRateCompensation, _super);
    function DiscountByRateCompensation(rate, data, discountables, discounts) {
        var _this = _super.call(this, data, discountables) || this;
        _this.rate = rate;
        _this.discountables = discountables;
        _this.discounts = discounts;
        return _this;
    }
    Object.defineProperty(DiscountByRateCompensation.prototype, "effective", {
        get: function () {
            return this.discounts.every(function (_a) {
                var effective = _a.effective;
                return effective;
            });
        },
        enumerable: false,
        configurable: true
    });
    DiscountByRateCompensation.prototype.discount = function () {
        var _this = this;
        this.discountableDiscountedAmounts = new Map();
        var totalExpectedDiscountAmount = big_numbers_1.big0;
        var totalOriginalAmount = big_numbers_1.big0;
        // Each discountable should have been reduced by the discountByRates of the exact amount of the totalRate (this.rate)
        // If there is a difference, it's applied to the discountable by the last discountByRate
        this.filteredDiscountables.forEach(function (discountable) {
            var discountAmountSum = _this.discounts.reduce(function (_discountAmountSum, discount) {
                return _discountAmountSum.plus(discount.discountableDiscountedAmounts.get(discountable.id));
            }, big_numbers_1.big0);
            var originalAmount = discountable.discountedAmount.plus(discountAmountSum);
            totalOriginalAmount = totalOriginalAmount.plus(originalAmount);
            var expectedDiscountAmount = _this.apply(originalAmount);
            totalExpectedDiscountAmount = totalExpectedDiscountAmount.plus(expectedDiscountAmount);
            if (!discountAmountSum.isEqualTo(expectedDiscountAmount)) {
                var missingAmount = expectedDiscountAmount.minus(discountAmountSum);
                var discount = _this.discounts[_this.discounts.length - 1];
                discountable.discountedAmount = discountable.discountedAmount.minus(missingAmount);
                discount.discountableDiscountedAmounts.set(discountable.id, discount.discountableDiscountedAmounts.get(discountable.id).plus(missingAmount));
            }
        });
        // The sum of the scope should have been reduced by the discountByRates of the exact amount of the totalRate (this.rate)
        // If there is a difference, it's applied to the first discountable by the last discountByRate
        var totalOriginalAmountDiscounted = this.apply(totalOriginalAmount);
        if (!totalExpectedDiscountAmount.isEqualTo(totalOriginalAmountDiscounted)) {
            var missingAmount = totalOriginalAmountDiscounted.minus(totalExpectedDiscountAmount);
            var lastDiscount_1 = this.discounts[this.discounts.length - 1];
            (0, discount_absolute_1.applyAbsoluteStrategy)(this, missingAmount, function (discountable, discountAmount) {
                lastDiscount_1.discountableDiscountedAmounts.set(discountable.id, lastDiscount_1.discountableDiscountedAmounts.get(discountable.id).plus(discountAmount));
                discountable.discount(discountAmount);
            });
        }
    };
    DiscountByRateCompensation.prototype.apply = function (amount) {
        return amount.multipliedBy(this.rate.dividedBy(big_numbers_1.big10000)).integerValue();
    };
    return DiscountByRateCompensation;
}(discount_1.Discount));
exports.DiscountByRateCompensation = DiscountByRateCompensation;
