import styled from '@emotion/styled';
import { SvyIcon } from '@innovorder/savory';

export const ProductContainer = styled.div`
    position: absolute;
    top: 0;
    z-index: 1;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${({ theme }) => theme.feature.backgroundColor};
`;

export const ProductQuantityPickerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.base.spacing[4]} 0px;
`;

export const ProductContentContainer = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const LoaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledIcon = styled(SvyIcon)`
    font-size: 24px;
    margin-right: ${({ theme }) => theme.base.spacing[1]};
`;
