import styled from '@emotion/styled';
import { Title } from 'components';
import { GrowingContainer } from 'components/PageWithBottomBar/PageWithBottomBar.style';

export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: ${({ theme }) => theme.base.spacing[5]};
    margin: ${({ theme }) => theme.base.spacing[2]};
    text-align: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const Content = styled(GrowingContainer)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${({ theme }) => theme.base.spacing[2]};
`;

export const CommentContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
    background-color: ${({ theme }) => theme.base.colors.secondary[200]};
    border-radius: ${({ theme }) => theme.base.radius.md};
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const StyledTitle = styled(Title)`
    padding-bottom: ${({ theme }) => theme.base.spacing[2]};
    margin-right: ${({ theme }) => theme.base.spacing[2]};
    border-bottom: 1px solid ${({ theme }) => theme.feature.thirdaryTextColor};
`;

export const StyledNumberTitle = styled(Title)`
    font-weight: 800;
    margin-bottom: ${({ theme }) => theme.base.spacing[3]};
`;

export const MargedContainer = styled.div`
    margin-top: ${({ theme }) => theme.base.spacing[2]};
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;
