"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processDiscounts = void 0;
var discount_types_1 = require("../../../types/discount.types");
var grant_strategy_1 = require("../../scopes/grant-strategy");
var grant_tags_1 = require("../../scopes/grant-tags");
var compute_total_price_from_items_1 = require("../../utils-computing/compute-total-price-from-items");
var apply_discount_on_items_1 = require("../apply-discounts-on-items/apply-discount-on-items");
var utils_1 = require("./utils");
var processDiscounts = function (items, discounts) {
    discounts = discounts.map(function (discount) {
        // evaluate if the discount strategy can be applied
        if (!(0, grant_strategy_1.canApplyGrantStrategy)(items, discount)) {
            return discount;
        }
        // evaluate for every discounts what items are in the scope
        var scopedItems = items.filter(function (item) { return (0, grant_tags_1.isItemInDiscountScope)(item, discount); });
        // compute the total price of those scope items only
        var scopedTotalPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)(scopedItems);
        if (discount.type === discount_types_1.DISCOUNT_TYPE.GRANT &&
            !(0, utils_1.isPriceBeyondThreshold)(discount, scopedTotalPrice.totalInclTaxWithDiscount)) {
            return discount;
        }
        // apply the discount on the scoped items
        var updatedProductsAndDiscount = (0, apply_discount_on_items_1.applyDiscountOnItems)(items, discount, scopedTotalPrice.totalInclTaxWithDiscount);
        // if the new total value of the scoped items is below the grant threshold, we don't update the items value
        var newScopedTotalPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)(updatedProductsAndDiscount.itemsUpdated);
        if (discount.type === discount_types_1.DISCOUNT_TYPE.GRANT &&
            !(0, utils_1.isPriceBeyondThreshold)(discount, newScopedTotalPrice.totalInclTaxWithDiscount)) {
            return discount;
        }
        // update the new current prices
        items = updatedProductsAndDiscount.itemsUpdated;
        discount = updatedProductsAndDiscount.discountUpdated;
        return discount;
    });
    return {
        products: items.filter(function (item) { return item.discountableType === discount_types_1.DISCOUNTABLE_TYPE.PRODUCT; }),
        fees: items.filter(function (item) { return item.discountableType === discount_types_1.DISCOUNTABLE_TYPE.FEE; }),
        discounts: discounts,
    };
};
exports.processDiscounts = processDiscounts;
