import { ReactElement, useCallback } from 'react';
import { Text } from 'components';
import { QuantityPicker } from 'components/QuantityPicker/QuantityPicker';
import { SelectOptions as RadioSelectOptions } from 'components/RadioSelect';
import { Carret } from 'components/Carret';
import { SelectionSummary, SummarySelectedItem } from 'components/SelectionSummary';
import {
    OptionQuantityPickerContainer,
    Container,
    OptionContainer,
    OptionNameContainer,
    OptionNameContent,
    OptionContent,
    SummaryContainer,
} from './QuantityPickerSelect.style';
import { computeIsMaxChoiceReached } from './QuantityPickerSelect.utils';

export type SelectOptions<T> = RadioSelectOptions<T> & { max?: number };

export type QuantityPickerSelectProps<T> = {
    options: SelectOptions<T>[];
    onChange: (value: T, isSummary: boolean, newValue: number) => void;
    selected?: Map<T, number>;
    maxChoice?: number;
    selectedSummary?: Record<number, SummarySelectedItem[]>;
    missingSteps?: Record<number, number>;
};

export const QuantityPickerSelect = <T,>({
    options,
    onChange,
    selected,
    maxChoice,
    selectedSummary,
    missingSteps,
}: QuantityPickerSelectProps<T>): ReactElement => {
    const handleOnChange = useCallback(
        (value: T, isSummary?: boolean) => (newValue: number) => {
            onChange(value, isSummary ?? false, newValue);
        },
        [onChange],
    );

    const isMaxChoiceReached = computeIsMaxChoiceReached(selected, maxChoice);

    return (
        <Container>
            {options.map(
                ({
                    name,
                    value,
                    icon: Icon,
                    noTranslation,
                    isStep,
                    description,
                    disabled,
                    max,
                }) => {
                    const selectedQuantity = selected?.get(value) || 0;
                    const isSingleMaxReached =
                        max !== undefined && max !== 0 && selectedQuantity >= max;
                    const isDisabled = disabled || (isMaxChoiceReached && selectedQuantity === 0);
                    const isMaxReached = isMaxChoiceReached || isSingleMaxReached;
                    const canChange = !disabled && (!isMaxChoiceReached || selectedQuantity > 0);

                    const selectedSummaryItems = selectedSummary?.[Number(value)];
                    const missingItemCount = missingSteps?.[Number(value)];

                    return (
                        <OptionContainer key={name} data-testid="quantityPicker-select">
                            <OptionContent onClick={() => canChange && handleOnChange(value)}>
                                <OptionNameContainer
                                    disabled={isDisabled}
                                    data-testid="quantityPicker-select-name"
                                >
                                    {Icon && <Icon data-testid="icon" />}
                                    <OptionNameContent>
                                        {noTranslation ? (
                                            <>
                                                <Text text={name} type={2} noTranslation />
                                                {description && (
                                                    <Text
                                                        text={description}
                                                        type={2}
                                                        noTranslation
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Text text={name} type={2} />
                                                {description && (
                                                    <Text text={description} type={2} />
                                                )}
                                            </>
                                        )}
                                    </OptionNameContent>
                                </OptionNameContainer>

                                <OptionQuantityPickerContainer data-testid="quantityPicker-select-quantityPicker">
                                    <QuantityPicker
                                        value={selectedQuantity}
                                        disabled={isDisabled}
                                        onValueChange={handleOnChange(value)}
                                        variant="secondary"
                                        maxReached={isMaxReached}
                                    />
                                    {isStep && <Carret />}
                                </OptionQuantityPickerContainer>
                            </OptionContent>

                            {selectedQuantity > 0 &&
                                (selectedSummaryItems || !!missingItemCount) && (
                                    <SummaryContainer>
                                        <SelectionSummary
                                            selectedItems={selectedSummaryItems || []}
                                            missingItemCount={missingItemCount}
                                            onChange={() => onChange(value, true, selectedQuantity)}
                                        />
                                    </SummaryContainer>
                                )}
                        </OptionContainer>
                    );
                },
            )}
        </Container>
    );
};
