import styled from '@emotion/styled';
import { Chips, PageWithBottomBarContainer } from 'components';
import { SvyBadge, SvyIcon } from '@innovorder/savory';
import { hexadecimalLowOpacity } from 'components/SelectionSummary/SelectionSummary.style';

export const MenuHomeContainer = styled(PageWithBottomBarContainer)`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const ButtonContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
    width: 100%;
`;

export const StyledIcon = styled(SvyIcon)`
    font-size: 24px;
    margin-right: ${({ theme }) => theme.base.spacing[1]};
`;

export const RestaurantTagsContainer = styled.div`
    padding-left: ${({ theme }) => theme.base.spacing[2]};
    display: flex;
    column-gap: ${({ theme }) => theme.base.spacing[1]};
`;

export const RestaurantTag = styled(Chips)`
    width: fit-content;
    border-radius: ${({ theme }) => theme.base.radius.default};
    padding: 4px ${({ theme }) => theme.base.spacing[1]};
    height: 24px;
    font-size: 12px;
    color: ${({ theme }) => theme.feature.primaryColor};
    background-color: ${({ theme }) => theme.feature.primaryColor}${hexadecimalLowOpacity};
`;

export const StyledSvyBadge = styled(SvyBadge)`
    font-size: 14px !important;
`;

export const TableNumberContainer = styled.div`
    padding-left: 16px;
`;
