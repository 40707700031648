"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsumptionModeType = void 0;
var ConsumptionModeType;
(function (ConsumptionModeType) {
    ConsumptionModeType["MODE_DELIVERY"] = "MODE_DELIVERY";
    ConsumptionModeType["MODE_TAKE_AWAY"] = "MODE_TAKE_AWAY";
    ConsumptionModeType["MODE_SIT_IN"] = "MODE_SIT_IN";
    ConsumptionModeType["MODE_DRIVE"] = "MODE_DRIVE";
})(ConsumptionModeType = exports.ConsumptionModeType || (exports.ConsumptionModeType = {}));
