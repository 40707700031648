// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker/locale/fr';
import {
    CategoryAttributes,
    ImageAttributes,
    MenuHashMap,
    ProductAttributes,
    ProductAttributesWithRelations,
    StepAttributes,
    StepProductAttributes,
} from 'services/menu/menu.type';

faker.locale = 'fr';

export const defaultCurrentQuantity = 10000;

export const productFixtureFactory = (
    params: Partial<ProductAttributes> = {},
): ProductAttributes => {
    const productId = faker.datatype.number();
    const defaultValues: ProductAttributes = {
        signature: '1',
        version: 1,
        name: faker.commerce.product(),
        description: faker.commerce.productDescription(),
        additionalInformation: faker.commerce.productAdjective(),
        price: faker.datatype.number({ min: 1000, max: 2000 }), // [0€00 - 20€00]
        isFormula: true,
        webAvailability: true,
        kioskAvailability: false,
        vat: 1000, // 10%
        isUpselling: false,
        isFinalProduct: true,
        consumptionFlags: 15,
        isProducible: false,
        currentQuantity: defaultCurrentQuantity,
        defaultQuantity: 0,
        isSitInEnabled: true,
        isDeliveryEnabled: true,
        isTakeAwayEnabled: true,
        imageId: null,
        menuId: 1,
        canApplyGrant: false,
        canApplyEntranceFee: false,
        servingCount: faker.datatype.number(),
        isEligibleLuncheon: faker.datatype.boolean(),
        skuId: null,
        sku: null,
        purchasePrice: 0,
        billingCode: null,
        productId,
        category_product: {
            productId,
            categoryId: faker.datatype.number(),
            position: 0,
        },
        created_at: faker.date.past(),
        updated_at: faker.date.past(),
        deleted_at: null,
    };
    return { ...defaultValues, ...params };
};

export const imageFixtureFactory = (params: Partial<ImageAttributes> = {}): ImageAttributes => {
    const defaultValues: ImageAttributes = {
        imageId: faker.datatype.number(),
        filename: faker.system.commonFileName('img'),
        originalFilename: '',
        fullUrl: faker.image.food(),
        absoluteFilePath: '',
        size: faker.datatype.number(),
        mimeType: 'png',
        created_at: faker.date.past(),
        updated_at: faker.date.past(),
        deleted_at: null,
        focalX: faker.datatype.number(5),
        focalY: faker.datatype.number(5),
        zoom: faker.datatype.number(100),
        restaurantId: faker.datatype.number(),
        brandId: faker.datatype.number(),
    };

    return { ...defaultValues, ...params };
};

export const productWithRelationsFixtureFactory = (
    params: Partial<ProductAttributesWithRelations> = {},
): ProductAttributesWithRelations => {
    return {
        // Attributes
        ...productFixtureFactory(params),
        // relation
        tags: [],
        productExternalReference: [],
        productStep: [],
        image: imageFixtureFactory(),
        // Overrides
        ...params,
    };
};

export const categoryFixtureFactory = (
    params: Partial<CategoryAttributes> = {},
): CategoryAttributes => {
    const defaultValues: CategoryAttributes = {
        name: faker.address.country(),
        imageId: null,
        image: null,
        products: [productFixtureFactory()],
        position: faker.datatype.number(10),
        parentCategoryId: null,
        menuId: null,
        webAvailability: faker.datatype.boolean(),
        kioskAvailability: faker.datatype.boolean(),
        categoryId: faker.datatype.number(),
        created_at: faker.date.past(),
        updated_at: faker.date.past(),
        deleted_at: null,
        subCategory: [],
    };
    return { ...defaultValues, ...params };
};

export const stepFixtureFactory = (params: Partial<StepAttributes> = {}): StepAttributes => {
    const defaultValues: StepAttributes = {
        signature: faker.datatype.uuid(),
        version: 1,
        name: faker.commerce.product(),
        ticketLabel: faker.random.word(),
        minChoice: 0,
        maxChoice: faker.datatype.number({ min: 2, max: 4 }),
        isQuantitySelectionEnabled: faker.datatype.boolean(),
        isWeightStrategyEnabled: faker.datatype.boolean(),
        isImageDisplayEnabled: faker.datatype.boolean(),
        isDescriptionDisplayEnabled: faker.datatype.boolean(),
        isGroupEnabled: faker.datatype.boolean(),
        isExcessAllowed: faker.datatype.boolean(),
        menuId: faker.datatype.number(),
        webAvailability: faker.datatype.boolean(),
        kioskAvailability: faker.datatype.boolean(),
        stepId: faker.datatype.number(),
        created_at: faker.date.past(),
        updated_at: faker.date.past(),
        deleted_at: null,
        stepProduct: [],
    };
    return { ...defaultValues, ...params };
};

export const productStepFixtureFactory = (
    params: Partial<StepProductAttributes> = {},
): StepProductAttributes => {
    const defaultValues: StepProductAttributes = {
        stepId: 1,
        menuId: 1,
        productId: 1,
        position: faker.datatype.number(15),
        price: faker.datatype.number(),
        exceedPrice: 0,
        groupName: faker.random.word(),
        maxProductQuantity: faker.datatype.number(),
        weight: 1,
    };
    return { ...defaultValues, ...params };
};

export const menuFixtureFactory = (params: Partial<MenuHashMap> = {}): MenuHashMap => {
    const defaultValues: MenuHashMap = {
        steps: { 1: stepFixtureFactory() },
        categories: {
            1: categoryFixtureFactory({ webAvailability: true, position: 3, name: 'category 1' }),
            2: categoryFixtureFactory({ webAvailability: false, position: 2, name: 'category 2' }),
            3: categoryFixtureFactory({ webAvailability: true, position: 1, name: 'category 3' }),
        },
        layout: [],
        products: { 1: productWithRelationsFixtureFactory() },
    };

    return { ...defaultValues, ...params };
};
