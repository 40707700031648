import { Title, RadioSelect, CheckboxSelect, QuantityPickerSelect } from 'components';
import React from 'react';
import { ProductStepRelation } from 'services/menu/menu.type';
import { SvyIcon } from '@innovorder/savory';
import {
    ProductStepsContainer,
    Separator,
    Step,
    StepHeader,
    StyledBadge,
    TextWithOpacity,
} from './ProductSteps.style';
import { StatusType, useProductStepsVM } from './useProductStepsVM';

export type ProductStepsProps = {
    productSteps: ProductStepRelation[];
    hasTriedAddingToCart?: boolean;
};

const STATUS_ICONS: Record<StatusType, string | undefined> = {
    success: 'check-line',
    destructive: 'alert-line',
    primary: undefined,
};

const ProductSteps: React.FunctionComponent<ProductStepsProps> = ({
    productSteps,
    hasTriedAddingToCart = false,
}) => {
    const {
        computedProductSteps,
        selectedOptions,
        stepSelectType,
        status,
        selectedSummaryItems,
        missingSteps,
        badgeLabel,
    } = useProductStepsVM(productSteps, hasTriedAddingToCart);

    return (
        <ProductStepsContainer data-testid="product-steps">
            {computedProductSteps.map(
                ({
                    stepId,
                    name,
                    minChoice,
                    maxChoice,
                    isExcessAllowed,
                    options,
                    handleOnChange,
                }) => {
                    const statusIcon = STATUS_ICONS[status[stepId]];
                    const statusBadge = statusIcon
                        ? { elementLeft: <SvyIcon icon={statusIcon} /> }
                        : {};

                    return (
                        <Step data-step-id={String(stepId)} key={stepId}>
                            <Separator />
                            <StepHeader>
                                <div>
                                    <Title text={name} noTranslation type={5} />
                                    {minChoice > 1 && (
                                        <div>
                                            <TextWithOpacity
                                                text={'pick_number_min'}
                                                values={{
                                                    minChoice,
                                                }}
                                                type={3}
                                            />
                                        </div>
                                    )}
                                    {(isExcessAllowed || maxChoice > 1) && (
                                        <div>
                                            <TextWithOpacity
                                                text={
                                                    isExcessAllowed
                                                        ? `included_options_${
                                                              maxChoice > 1 ? 'other' : 'one'
                                                          }`
                                                        : 'pick_number_max'
                                                }
                                                values={{
                                                    maxChoice,
                                                }}
                                                type={3}
                                            />
                                        </div>
                                    )}
                                </div>

                                {minChoice > 0 && (
                                    <StyledBadge
                                        color={status[stepId]}
                                        label={badgeLabel}
                                        {...statusBadge}
                                        data-mandatory-status={status[stepId]}
                                    />
                                )}
                            </StepHeader>

                            {stepSelectType[stepId] === 'radio' && (
                                <RadioSelect
                                    options={options}
                                    onChange={handleOnChange}
                                    selected={selectedOptions.radio[stepId]}
                                    selectedSummary={selectedSummaryItems[stepId]}
                                    missingSteps={missingSteps[stepId]}
                                    canUnselect={minChoice === 0}
                                />
                            )}
                            {stepSelectType[stepId] === 'checkbox' && (
                                <CheckboxSelect
                                    options={options}
                                    onChange={handleOnChange}
                                    selected={selectedOptions.checkbox[stepId]}
                                    selectedSummary={selectedSummaryItems[stepId]}
                                    missingSteps={missingSteps[stepId]}
                                    maxChoice={isExcessAllowed ? 0 : maxChoice}
                                />
                            )}
                            {stepSelectType[stepId] === 'quantityPicker' && (
                                <QuantityPickerSelect
                                    options={options}
                                    onChange={handleOnChange}
                                    selected={selectedOptions.quantityPicker[stepId]}
                                    selectedSummary={selectedSummaryItems[stepId]}
                                    missingSteps={missingSteps[stepId]}
                                    maxChoice={isExcessAllowed ? 0 : maxChoice}
                                />
                            )}
                        </Step>
                    );
                },
            )}
        </ProductStepsContainer>
    );
};

export default ProductSteps;
