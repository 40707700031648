import styled from '@emotion/styled';
import { StyledButtonProps, SvyButton } from '@innovorder/savory';
import { Theme, css } from '@emotion/react';
import { ButtonProps } from './Button';

const mapAttributesFromProps = ({ theme, variant }: { theme: Theme } & StyledButtonProps) => {
    switch (variant) {
        case 'outline':
            return css`
                --svy-button-border-size: 1px !important;
                --svy-button-border-color: ${theme.feature.ctaColor} !important;
                --svy-button-text-color: ${theme.feature.ctaColor} !important;
                --svy-button-background-color: ${theme.base.colors.secondary[50]} !important;
                --svy-button-hover-background-color: ${theme.base.colors.secondary[50]} !important;
                --svy-button-active-background-color: ${theme.base.colors.secondary[50]} !important;
            `;
        case 'text':
            return css`
                --svy-button-background-color: ${theme.base.colors.secondary[50]} !important;
                --svy-button-text-color: ${theme.feature.ctaColor} !important;
                --svy-button-hover-background-color: ${theme.feature.ctaColor}1a !important;
                --svy-button-active-background-color: ${theme.feature.ctaColor}1a !important;
            `;
        case 'default':
        default:
            return css`
                --svy-button-background-color: ${theme.feature.ctaColor} !important;
                --svy-button-hover-background-color: ${theme.feature.ctaColor} !important;
                --svy-button-active-background-color: ${theme.feature.ctaColor} !important;
            `;
    }
};

export const StyledButton = styled(SvyButton)<ButtonProps>`
    ${(props) => {
        if (props.color) {
            return ``;
        }

        return mapAttributesFromProps(props);
    }};

    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    &:active {
        transform: scale(0.99);
    }

    transition: 0.1s ease;
`;

export const DisabledContainer = styled.div<ButtonProps>`
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    position: relative;

    &:active {
        transform: scale(0.99);
    }

    transition: 0.1s ease;
`;

export const DisabledClickHandler = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
`;
