import { HeaderTitle } from 'components';
import {
    RestaurantTitleSkeleton,
    Container,
    MenuHomeBadgeSkeleton,
    BadgesContainer,
    ScrollbarSkeleton,
    CategoriesSkeleton,
    CategoryItemSkeleton,
    CategoryTitleSkeleton,
    ProductBadgeSkeleton,
    ProductDescriptionSkeleton,
    ProductTitleSkeleton,
    ProductImageSkeleton,
    CategoryProductContainer,
    CategoryProductContent,
    CategoryProductText,
    ProductSeparatorSkeleton,
} from './MenuHomeSkeleton.style';

type Props = { isFoodCourt: boolean | null };

const MenuHomeSkeleton: React.FunctionComponent<Props> = ({ isFoodCourt }) => {
    return (
        <Container data-testid="menu-home-skeleton">
            {isFoodCourt && (
                <HeaderTitle>
                    <RestaurantTitleSkeleton />
                    <BadgesContainer>
                        <MenuHomeBadgeSkeleton />
                        <MenuHomeBadgeSkeleton />
                        <MenuHomeBadgeSkeleton />
                    </BadgesContainer>
                </HeaderTitle>
            )}

            <CategoriesSkeleton>
                {new Array(8).fill(1).map((_, index) => (
                    <div key={`category-${index}`}>
                        <CategoryItemSkeleton />
                    </div>
                ))}
            </CategoriesSkeleton>
            <ScrollbarSkeleton />

            {new Array(2).fill(1).map((_, index) => (
                <CategoryProductContainer key={`category-product-${index}`}>
                    <CategoryTitleSkeleton />
                    <CategoryProductContent>
                        <CategoryProductText>
                            <ProductTitleSkeleton />
                            <ProductBadgeSkeleton />
                            <ProductDescriptionSkeleton />
                        </CategoryProductText>
                        <ProductImageSkeleton />
                    </CategoryProductContent>
                </CategoryProductContainer>
            ))}

            <ProductSeparatorSkeleton />

            {new Array(2).fill(1).map((_, index) => (
                <CategoryProductContainer key={`category-product-${index}`}>
                    <CategoryTitleSkeleton />
                    <CategoryProductContent>
                        <CategoryProductText>
                            <ProductTitleSkeleton />
                            <ProductBadgeSkeleton />
                            <ProductDescriptionSkeleton />
                        </CategoryProductText>
                        <ProductImageSkeleton />
                    </CategoryProductContent>
                </CategoryProductContainer>
            ))}
        </Container>
    );
};

export default MenuHomeSkeleton;
