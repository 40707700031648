import { ChannelId } from 'services/order/order.type';

export enum Module {
    ordering_flow = 2,
    style_style = 3,
    device_printing = 4,
    payment_stripe = 5,
    ordering_visibility = 7,
    ordering_schedule = 8,
    payment_refund = 10,
    website_configuration = 11,
    reception_config = 12,
    website_canvas = 13,
    website_page = 14,
    pos_device = 15,
    website_meta = 16,
    loyalty_royalty = 17,
} // See services/api/src/infra/models/module/types.ts

export enum WeekDay {
    MONDAY = 'MON',
    TUESDAY = 'TUE',
    WEDNESDAY = 'WED',
    THURSDAY = 'THU',
    FRIDAY = 'FRI',
    SATURDAY = 'SAT',
    SUNDAY = 'SUN',
} // See services/api/src/domain/factories/schedules/types.ts

export type WeborderingScheduleAttributes = {
    day: WeekDay;
    start: number;
    end: number;
    weborderingScheduleId: number;
    moduleOrderingScheduleId: number;
    is_enabled: boolean;
    created_at: Date;
    updated_at: Date;
}; // See services/api/src/infra/models/webordering_schedule/types.ts

export type ModuleOrderingScheduleAttributesWithSchedules = {
    moduleOrderingScheduleId: number;
    moduleId: Module.ordering_schedule;
    brandId: number | null;
    restaurantId: number | null;
    channelId: ChannelId.WEB;
    isPreorderingEnabled: boolean;
    is_enabled: boolean;
    created_at: Date;
    updated_at: Date;
    weborderingSchedules: WeborderingScheduleAttributes[];
}; // See services/api/src/infra/models/module_ordering_schedule/types.ts
