import { Modal, Title, Text } from 'components';
import { TranslationKey } from 'locales/fr';
import { PropsWithChildren } from 'react';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { Container, TriggerModal } from './CartModal.style';

export type CartModalProps = PropsWithChildren<{
    title: TranslationKey;
    headerTitle: TranslationKey;
    buttonTitle: TranslationKey;
    icon?: React.FunctionComponent;
    isOpen: boolean;
    isLoading: boolean;
    onShow: () => void;
    onHide: () => void;
    onSubmit: () => void;
    value: string | undefined;
    isValid?: boolean;
}>;

const CartModal: React.FunctionComponent<CartModalProps> = ({
    children,
    title,
    headerTitle,
    buttonTitle,
    icon,
    isOpen,
    isLoading,
    onShow,
    onHide,
    onSubmit,
    value,
    isValid,
}) => {
    const Icon = icon;

    return (
        <Container>
            <Title text={title} type={5} />
            <TriggerModal data-testid="trigger-modal" onClick={onShow} isValid={isValid}>
                {!isOpen && value ? (
                    <Text text={value} type={3} noTranslation />
                ) : (
                    <Text text={headerTitle} type={3} />
                )}
                {isValid ? (
                    <SVGIcon icon={SVGIcons.CARRET} data-testid="valid-modal" />
                ) : (
                    Icon && <Icon />
                )}
            </TriggerModal>
            <Modal
                isOpen={isOpen}
                onHide={onHide}
                icon={icon}
                headerTitle={headerTitle}
                buttonTitle={buttonTitle}
                buttonAction={onSubmit}
                isLoading={isLoading}
            >
                {children}
            </Modal>
        </Container>
    );
};

export default CartModal;
