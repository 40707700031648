import { Discount, Item, OrderInformations } from 'services/order/order.type';

export const isPosDiscount = (discounts: Discount[]): boolean =>
    discounts.reduce((isPos, actualDiscount) => {
        return isPos ? actualDiscount.value === 0 : false;
    }, true);

export const searchForDiscountInItems = (discount: Discount, items: Item[]): Item | false => {
    const item = items.find(({ name }) => name.match(new RegExp(`${discount.name} `)));
    return item ?? false;
};

export const convertItemToDiscount = (
    { totalPriceWithTaxIncluded }: Item,
    name: string,
): Discount => ({
    name,
    value: Math.abs(totalPriceWithTaxIncluded),
});

export const mapItemsToDiscount = (discounts: Discount[], items: Item[]): Discount[] => {
    return discounts
        .map((discount) => {
            const item = searchForDiscountInItems(discount, items);
            return item ? convertItemToDiscount(item, discount.name) : false;
        })
        .filter<Discount>((discount): discount is Discount => !!discount);
};

export const mapOmnicanalOrderToDiscount = (order?: OrderInformations): Discount[] => {
    if (!order?.discounts?.length) {
        return [];
    }

    if (!isPosDiscount(order.discounts)) {
        return order.discounts;
    }

    return mapItemsToDiscount(order.discounts, order.items);
};
