import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
import {
    restaurantCardMaxWidth,
    restaurantCardMinHeight,
    restaurantCardMinWidth,
} from 'components/RestaurantCard/RestaurantCard.style';
import { CarouselHeight } from 'components/Carousel/Carousel.style';
import { Container as RestaurantsGrid } from '../RestaurantGrid/RestaurantGrid.style';

export const Container = styled.div`
    background: ${({ theme }) => theme.feature.backgroundColor};
`;

export const CarouselContainer = styled.div`
    display: flex;
`;

export const CarouselItemContainer = styled.div`
    padding: 0 ${({ theme }) => theme.base.spacing[1]};
    margin-right: ${({ theme }) => theme.base.spacing[1]};
`;

export const CarouselItemImage = styled(Skeleton)`
    height: ${CarouselHeight};
    width: ${CarouselHeight};
`;

export const CarouselItemText = styled(Skeleton)`
    height: 20px;
    width: ${CarouselHeight};
`;

export const RestaurantsGridContainer = styled(RestaurantsGrid)``;

export const RestaurantsGridImage = styled(Skeleton)`
    width: 100%;
    min-width: ${restaurantCardMinWidth};
    max-width: ${restaurantCardMaxWidth};
    height: ${restaurantCardMinHeight};
`;

export const RestaurantsGridText = styled(Skeleton)`
    width: 100%;
    min-width: ${restaurantCardMinWidth};
    max-width: ${restaurantCardMaxWidth};
    height: 24px;
`;
