import React from 'react';
import { RestaurantCard } from 'components';
import Error from 'pages/Error/Error';
import { ErrorEnum } from 'pages/Error';
import { Container } from './RestaurantGrid.style';
import { DecoratedWeborderingRestaurant } from '../type';

export type RestaurantGridProps = {
    restaurants: DecoratedWeborderingRestaurant[];
    onSelectRestaurant: (restaurantId: number) => void;
};

const RestaurantGrid: React.FunctionComponent<RestaurantGridProps> = ({
    restaurants,
    onSelectRestaurant,
}) => {
    const handleSelectRestaurant = (restaurantId: number) => {
        onSelectRestaurant(restaurantId);
    };

    return (
        <>
            {restaurants.length > 0 && (
                <Container data-testid="restaurant-grid">
                    {restaurants.map((restaurant) => (
                        <RestaurantCard
                            key={restaurant.restaurantId}
                            imageUrl={restaurant.imageUrl}
                            name={restaurant.name}
                            restaurantTags={restaurant.restaurantTags}
                            overlayText={restaurant.nextOpeningOverlay}
                            rushDelay={restaurant.rushDelay}
                            onClick={() => handleSelectRestaurant(restaurant.restaurantId)}
                        />
                    ))}
                </Container>
            )}

            {!restaurants.length && (
                <Error error={ErrorEnum.NO_RESTAURANT} data-testid="no-restaurant-error" />
            )}
        </>
    );
};

export default RestaurantGrid;
