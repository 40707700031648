import { EdenredPaymentServiceClient } from './payment_service.client';
import { EdenredCheckoutConfiguration } from './types';

export class EdenredPaymentCheckout {
    private paymentServiceClient: EdenredPaymentServiceClient;

    private checkoutConfiguration: EdenredCheckoutConfiguration;

    public constructor(
        paymentServiceClient: EdenredPaymentServiceClient,
        checkoutConfiguration: EdenredCheckoutConfiguration,
    ) {
        this.paymentServiceClient = paymentServiceClient;
        this.checkoutConfiguration = checkoutConfiguration;
    }

    /**
     * If the customer is authenticated (to Api and Edenred), returns his daily balance
     */
    public async getCustomerBalance(token: string): Promise<number> {
        return this.paymentServiceClient.getCustomerBalance(token);
    }

    public async getAnonymousCustomerBalance(token: string): Promise<number> {
        return this.paymentServiceClient.getAnonymousCustomerBalance(token);
    }

    public async authenticateCustomer(source: string, token?: string): Promise<string> {
        return this.paymentServiceClient.customerAuthenticationUrl(this.checkoutConfiguration.locale, source, token);
    }

    public async logout(token: string): Promise<string> {
        return this.paymentServiceClient.logout(token);
    }
}
