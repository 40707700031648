import { useCallback } from 'react';
import { SvyIcon } from '@innovorder/savory';
import { Loader } from 'components/Loader';
import {
    Container,
    PRIMARY_HEIGHT,
    PRIMARY_WIDTH,
    QuantityPickerCountContainer,
    QuantityPickerLoaderContainer,
    QuantityPickerModifierContainer,
    SECONDARY_HEIGHT,
    SECONDARY_WIDTH,
} from './QuantityPicker.style';

export type QuantityPickerProps = {
    value: number;
    onValueChange: (newValue: number) => void;
    variant: 'primary' | 'secondary';
    disabled?: boolean;
    isLoading?: boolean;
    isDebouncing?: boolean;
    hideTrash?: boolean;
    maxReached?: boolean;
};

export const QuantityPicker: React.FunctionComponent<QuantityPickerProps> = ({
    value,
    onValueChange,
    variant,
    disabled,
    isLoading,
    isDebouncing,
    hideTrash,
    maxReached,
}) => {
    const handleOnDecrease = useCallback(() => {
        const canDecrease = !disabled && !(value === 1 && hideTrash) && value > 0;
        canDecrease && onValueChange(value - 1);
    }, [onValueChange, value, disabled, hideTrash]);

    const handleOnIncrease = useCallback(() => {
        !disabled && !maxReached && onValueChange(value + 1);
    }, [onValueChange, value, disabled, maxReached]);

    const showFullQuantityPicker = value > 0 || isLoading || isDebouncing;
    const animation = showFullQuantityPicker ? `${variant}Full` : `${variant}Small`;

    return (
        <Container
            data-testid="quantity-picker-container"
            value={value}
            disabled={disabled}
            isLoading={isLoading}
            variant={variant}
            variants={{
                primaryFull: { width: PRIMARY_WIDTH },
                primarySmall: { width: PRIMARY_HEIGHT },
                secondaryFull: { width: SECONDARY_WIDTH },
                secondarySmall: { width: SECONDARY_HEIGHT },
            }}
            initial={animation}
            animate={animation}
        >
            {showFullQuantityPicker && (
                <>
                    <QuantityPickerModifierContainer
                        onClick={handleOnDecrease}
                        data-testid="quantity-picker-minus-btn"
                        disabled={value === 1 && hideTrash}
                        variant={variant}
                    >
                        {value <= 1 && !hideTrash && (
                            <QuantityPickerModifierContainer
                                data-testid="quantity-picker-trash-btn"
                                variant={variant}
                                disabled={value === 0}
                            >
                                <SvyIcon icon="delete-bin-6-line" />
                            </QuantityPickerModifierContainer>
                        )}
                        {(value > 1 || (value === 1 && hideTrash)) && (
                            <SvyIcon icon="subtract-line" />
                        )}
                    </QuantityPickerModifierContainer>
                    {!isLoading ? (
                        <QuantityPickerCountContainer
                            text={String(value)}
                            type={2}
                            noTranslation
                            data-testid="quantity-picker-value-displayed"
                            variant={variant}
                        />
                    ) : (
                        <QuantityPickerLoaderContainer data-testid="quantity-picker-loader-container">
                            <Loader size={18} />
                        </QuantityPickerLoaderContainer>
                    )}
                </>
            )}

            <QuantityPickerModifierContainer
                onClick={handleOnIncrease}
                data-testid="quantity-picker-plus-btn"
                variant={variant}
                disabled={maxReached}
            >
                <SvyIcon icon="add-line" />
            </QuantityPickerModifierContainer>
        </Container>
    );
};
