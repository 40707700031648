"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateScopeTypeFromApplyMethod = void 0;
var discount_types_1 = require("../../types/discount.types");
/* TODO
Those methods exist to handle a lack of scope informations in some discount inputs
If the scope is missing some informations, we overwrite it based on the applyMethod field
In an ideal world, the scope informations should NEVER be incomplete and those functions should not exist
*/
var generateScopeTypeFromApplyMethod = function (applyMethod) {
    switch (applyMethod) {
        case discount_types_1.DiscountApplyMethod.RATE:
        case discount_types_1.DiscountApplyMethod.ABSOLUTE_GLOBAL:
        case discount_types_1.DiscountApplyMethod.GRANT_ABSOLUTE_GLOBAL:
            return new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT, discount_types_1.DISCOUNTABLE_TYPE.FEE]);
        case discount_types_1.DiscountApplyMethod.ABSOLUTE_CART:
            return new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT]);
        default:
            return new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT, discount_types_1.DISCOUNTABLE_TYPE.FEE]);
    }
};
exports.generateScopeTypeFromApplyMethod = generateScopeTypeFromApplyMethod;
