import styled from '@emotion/styled';
import { SvyCheckbox } from '@innovorder/savory';
import { css } from '@emotion/react';
import { CheckboxProps } from './Checkbox';

export const StyledCheckbox = styled(SvyCheckbox)<CheckboxProps>`
    ${({ theme, color, checked }) => {
        if (color) {
            return css``;
        }

        if (checked) {
            return css`
                --svy-checkbox-backgroud: ${theme.feature.ctaColor} !important;
                --svy-checkbox-border-color: ${theme.feature.ctaColor} !important;
            `;
        }

        return css``;
    }};

    &:not(:disabled):hover {
        ${({ theme, color, checked }) => {
            if (color) {
                return css``;
            }

            if (checked) {
                return css`
                    --svy-checkbox-backgroud: ${theme.feature.ctaColor} !important;
                    --svy-checkbox-border-color: ${theme.feature.ctaColor} !important;
                `;
            }

            return css`
                --svy-checkbox-border-color: ${theme.feature.ctaColor} !important;
            `;
        }}
    }
`;

export const OnClickContainer = styled.div`
    position: relative;
`;

export const OnClickHandler = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
`;
