import { RestaurantFamily } from 'services/brand/brand.type';

export const weborderingRestaurantFamilyFixtureFactory = (
    params: Partial<RestaurantFamily> = {},
): RestaurantFamily => {
    const defaultValues: RestaurantFamily = {
        brandRestaurantFamilyId: 1,
        name: 'family',
        imageUrl:
            'https://static-dev.innovorder.fr/uploads/2f250927455d/c42c0dd5ba3c9da0740f6db0d1a752f3.jpg',
        position: 0,
    };

    return { ...defaultValues, ...params };
};
