import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const ordersErrorsSliceName = 'ordersErrors';

export type OrderStepError = {
    name: string | null;
    errorMessage?: string;
};

export type ProductError = {
    productId: number;
    restaurantId: number;
    productCartId?: string;
    productName: string | null;
    errorFromSteps: boolean;
    productStepsInError?: OrderStepError[];
    errorMessage?: string;
};
export type OrdersErrorsState = ProductError[] | null;

const initialState: OrdersErrorsState = null;

export const ordersErrorsSlice = createSlice<
    OrdersErrorsState,
    SliceCaseReducers<OrdersErrorsState>,
    string
>({
    name: ordersErrorsSliceName,
    initialState,
    reducers: {
        addOrdersErrors: (state, action: PayloadAction<ProductError[]>) =>
            action.payload.concat(state ?? []),
        resetOrdersErrors: (_state, _action: PayloadAction) => null,
    },
});

export const getOrdersErrors = (state: RootState): OrdersErrorsState => state.ordersErrors;

export const { addOrdersErrors, resetOrdersErrors } = ordersErrorsSlice.actions;
