export type GetMenuHashMapPayload = {
    menuId?: number;
};

export type HashMappedStepProduct = Omit<ProductAttributesWithRelations, 'tags' | 'productStep'> & {
    step_product: Omit<StepProductAttributes, 'stepProductId'>;
};

export interface StepAttributes {
    stepId: number;
    signature: string;
    version: number;
    name: string;
    ticketLabel: string;
    minChoice: number;
    maxChoice: number;
    isQuantitySelectionEnabled: boolean;
    isWeightStrategyEnabled: boolean;
    isImageDisplayEnabled: boolean;
    isDescriptionDisplayEnabled: boolean;
    isGroupEnabled: boolean;
    isExcessAllowed: boolean;
    menuId: number;
    webAvailability: boolean;
    kioskAvailability: boolean;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
    stepProduct: HashMappedStepProduct[];
    type?: StepType;
}

export enum StepType {
    CUSTOMIZATION = 1,
    CROSS_SELLING = 2,
}

export type StepProductAttributes = {
    stepId: number;
    menuId: number;
    productId: number;
    position: number;
    price: number;
    weight: number;
    exceedPrice: number;
    groupName: string | null;
    maxProductQuantity: number;
};

export interface ImageAttributes {
    imageId: number;
    filename: string;
    originalFilename: string;
    fullUrl: string;
    absoluteFilePath: string;
    size: number;
    mimeType: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
    focalX: number;
    focalY: number;
    zoom: number;
    restaurantId: number;
    brandId: number;
}

type ProductStepAttributes = {
    stepId: number;
    productId: number;
    menuId: number;
    position: number | null;
};

export type ProductStepRelation = StepAttributes & {
    product_step: Omit<ProductStepAttributes, 'productStepId'>;
};

export interface ProductExternalReferenceAttributes {
    productExternalReferenceId: number;
    signature: string | number;
    provider: string;
    reference: string;
    productId: number | null;
    restaurantId: number | null;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
}

export interface ProductAttributesWithRelations extends ProductAttributes {
    tags: TagWithRelationsAttributes[];
    image?: ImageAttributes;
    productStep: ProductStepRelation[];
    productExternalReference: ProductExternalReferenceAttributes[];
}

export interface ProductAttributesFromCategories extends ProductAttributes {
    image?: ImageAttributes;
}

type TagAttributes = {
    tagId: number;
    brandId: number | null;
    name: string;
    color: string | null;
    is_enabled: boolean;
    created_at: Date | null;
    updated_at: Date | null;
};
type TagWithRelationsAttributes = TagAttributes & {
    product_tag?: ProductTagAttributes;
};

type ProductTagAttributes = {
    productId: number;
    tagId: number;
    is_enabled: boolean | null;
};

type Flags = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

export type CategoryProduct = {
    categoryId: number;
    position: number;
    productId: number;
};
export interface ProductAttributes {
    productId: number;

    menuId: number;
    signature: string;
    version: number;
    name: string;
    skuId: number | null;
    description: string | null;
    additionalInformation: string | null;
    price: number;
    isFormula: boolean | null;
    webAvailability: boolean;
    kioskAvailability: boolean;
    vat: number;
    isUpselling: boolean | null;
    isFinalProduct: boolean | null;
    consumptionFlags: Flags;
    isProducible: boolean | null;
    currentQuantity: number | null;
    defaultQuantity: number | null;
    isSitInEnabled: boolean | null; // Deprecated: consumptionFlags is used instead
    isDeliveryEnabled: boolean | null; // Deprecated: consumptionFlags is used instead
    isTakeAwayEnabled: boolean | null; // Deprecated: consumptionFlags is used instead
    imageId: number | null;
    canApplyGrant: boolean | null;
    canApplyEntranceFee: boolean | null;
    servingCount: number | null; // There are 75 products of the 2,500,000 products of the production database which have a null servingCount
    isEligibleLuncheon: boolean | null;
    sku: SkuImport | null;
    billingCode: string | null;
    purchasePrice: number | null;
    category_product: CategoryProduct;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
}

export interface SkuAttributes {
    skuId: number;
    value: string;
    brandId: number;
    deliveryVat: number;
    driveVat: number;
    sitInVat: number;
    takeAwayVat: number;
    createdAt: Date;
    updatedAt: Date | null;
}

type SkuImport = Omit<SkuAttributes, 'skuId' | 'brandId' | 'createdAt' | 'updatedAt'> & {
    skuId?: number;
    brandId?: number;
};

type CategoryLayout = {
    categoryId: number;
    image: null;
    kioskAvailability: boolean;
    webAvailability: boolean;
};

export interface CategoryAttributes {
    categoryId: number;
    name: string;
    imageId: number | null;
    image: ImageAttributes | null;
    position: number;
    parentCategoryId: number | null;
    menuId: number | null;
    webAvailability: boolean;
    products: { [k: number]: ProductAttributesFromCategories };
    kioskAvailability: boolean;
    deleted_at: Date | null;
    subCategory: [];
    created_at: Date;
    updated_at: Date | null;
}

export type MenuHashMap = {
    categories: { [k: number]: CategoryAttributes };
    products: { [k: number]: ProductAttributesWithRelations };
    steps: { [k: number]: StepAttributes };
    layout: CategoryLayout[];
};
