import { useEffect, useState } from 'react';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, onSnapshot } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getStatuses, updateOrdersStatus } from 'redux/ordersStatus/ordersStatus.slice';
import { ENV } from 'utils/env';
import { getBrandOptionStatus, getIsOrderFollowUpEnabled } from 'redux/brand';
import { getCurrentOrderId, getRestaurantId, updateAppState } from 'redux/app';
import {
    getOrderStatusAcknowledged,
    updateOrdersStatusAcknowledged,
} from 'redux/ordersStatusAcknowledged/ordersStatusAcknowledged.slice';
import { mapToFollowUpStatus } from './useLiveOrdersStatus.utils';

export const useLiveOrdersStatus = () => {
    const dispatch = useAppDispatch();
    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const currentRestaurantId = useAppSelector(getRestaurantId);
    const isOrderFollowUpEnabled = useAppSelector(getIsOrderFollowUpEnabled(currentRestaurantId));
    const ordersStatus = useAppSelector(getStatuses);
    const ordersStatusAcknowledged = useAppSelector(getOrderStatusAcknowledged);
    const currentOrderId = useAppSelector(getCurrentOrderId);
    const [isInitialized, setIsInitialized] = useState(false);

    const allOrdersReadyAndAcknowledged = ordersStatus
        ? Object.values(ordersStatus).every(
              ({ status }) => status === 'COLLECTED' || status === 'CANCELLED',
          )
        : false;

    useEffect(() => {
        const handleOffline = () => dispatch(updateAppState({ isDisconnected: true }));

        window.addEventListener('offline', handleOffline);

        return () => window.removeEventListener('handleOffline', handleOffline);
    }, [dispatch]);

    useEffect(() => {
        if (!isFoodCourt && !isOrderFollowUpEnabled) return;
        if (!ordersStatus) return;
        if (allOrdersReadyAndAcknowledged) return;
        if (isInitialized) return;

        const app = initializeApp({
            apiKey: ENV.FIRESTORE_API_KEY,
            authDomain: ENV.FIRESTORE_AUTH_DOMAIN,
            projectId: ENV.FIRESTORE_PROJECT_ID,
            storageBucket: ENV.FIRESTORE_STORAGE_BUCKET,
            messagingSenderId: ENV.FIRESTORE_MESSAGING_SENDER_ID,
            appId: ENV.FIRESTORE_APP_ID,
        });

        const db = getFirestore(app);

        Object.values(ordersStatus).forEach(({ orderId, restaurantId, status }) => {
            if (status === 'CANCELLED') return;

            const ticketCollection = collection(db, `restaurants/${restaurantId}/kds-tickets`);

            onSnapshot(
                doc(ticketCollection, orderId),
                { includeMetadataChanges: true },
                (updatedDoc) => {
                    const fireStoreStatus = mapToFollowUpStatus(updatedDoc.data()?.status);

                    if (updatedDoc.metadata.fromCache) {
                        /*
                            If fromCache is true, it means either :
                            - We lost connection
                            - Something happened to the snapshot
                        */
                        dispatch(updateAppState({ isDisconnected: true }));
                    }

                    if (fireStoreStatus === 'PROCESSING') {
                        dispatch(
                            updateOrdersStatusAcknowledged({
                                [orderId]: false,
                            }),
                        );
                    }

                    dispatch(
                        updateOrdersStatus({
                            [orderId]: {
                                restaurantId,
                                orderId,
                                status: fireStoreStatus,
                            },
                        }),
                    );
                    dispatch(updateAppState({ currentOrderId }));
                },
            );
        });

        setIsInitialized(true);
    }, [
        isFoodCourt,
        isOrderFollowUpEnabled,
        currentOrderId,
        ordersStatus,
        ordersStatusAcknowledged,
        allOrdersReadyAndAcknowledged,
        dispatch,
        isInitialized,
    ]);

    useEffect(() => {
        if (!ordersStatus) return;
        if (!allOrdersReadyAndAcknowledged) return;

        dispatch(updateAppState({ currentOrderId: undefined }));
        setIsInitialized(false);
    }, [ordersStatus, allOrdersReadyAndAcknowledged, dispatch]);

    return {};
};
