"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectOffTurnoverProductsAndFees = exports.computeTurnoverTotalTax = exports.computeTurnoverExclTax = exports.computeTurnoverInclTax = exports.computeOffTurnover = exports.computeTurnoverFromTotalPrice = void 0;
var types_1 = require("../../adapters/pos-order-adapter/types");
var types_2 = require("../../types");
function computeTurnoverFromTotalPrice(totalPriceDiscountedWithTaxIncluded, offTurnover, grantAmount) {
    return totalPriceDiscountedWithTaxIncluded - offTurnover + Math.abs(grantAmount);
}
exports.computeTurnoverFromTotalPrice = computeTurnoverFromTotalPrice;
function computeOffTurnover(feeRecaps, urssafCompensatoryProduct) {
    var royaltyFeesAmount = feeRecaps
        .filter(function (feeRecap) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return (feeRecap.additionalData && feeRecap.additionalData.type === types_2.FEE_TYPE.ROYALTY_FEE) ||
            feeRecap.type === types_2.FEE_TYPE.ROYALTY_FEE;
    })
        .reduce(function (sumAmount, royaltyFee) { return sumAmount + royaltyFee.value; }, 0);
    var urssafCompensatoryProductAmount = urssafCompensatoryProduct ? urssafCompensatoryProduct.amount : 0;
    return royaltyFeesAmount + urssafCompensatoryProductAmount;
}
exports.computeOffTurnover = computeOffTurnover;
function computeTurnoverInclTax(totalInclTaxWithRemise, offTurnover) {
    return totalInclTaxWithRemise - offTurnover;
}
exports.computeTurnoverInclTax = computeTurnoverInclTax;
function computeTurnoverExclTax(totalExclTaxWithRemise, offTurnover) {
    return totalExclTaxWithRemise - offTurnover;
}
exports.computeTurnoverExclTax = computeTurnoverExclTax;
function computeTurnoverTotalTax(turnover, turnoverExclTax) {
    return turnover - turnoverExclTax;
}
exports.computeTurnoverTotalTax = computeTurnoverTotalTax;
function selectOffTurnoverProductsAndFees(products, fees, isDepositIncludedInTurnover) {
    var compensatoryProducts = products.filter(function (product) {
        return product.data &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            product.data.additionalData &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            product.data.additionalData.orderItemType === types_1.PosOrderItemType.COMPENSATION;
    });
    var DEPOSIT_TAG = 'consigne';
    var depositProducts = products.filter(function (product) { return product.tags && product.tags.includes(DEPOSIT_TAG); });
    var royaltyFees = fees.filter(function (fee) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return (fee.data && fee.data.additionalData && fee.data.additionalData.type === types_2.FEE_TYPE.ROYALTY_FEE) ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fee.data.type === types_2.FEE_TYPE.ROYALTY_FEE;
    });
    if (isDepositIncludedInTurnover) {
        return __spreadArray(__spreadArray([], compensatoryProducts, true), royaltyFees, true);
    }
    return __spreadArray(__spreadArray(__spreadArray([], compensatoryProducts, true), royaltyFees, true), depositProducts, true);
}
exports.selectOffTurnoverProductsAndFees = selectOffTurnoverProductsAndFees;
