import { Text } from 'components';
import React, { useCallback } from 'react';
import {
    CategoryItem,
    ScrollbarItem,
    Content,
    Scrollbar,
    ScrollContainer,
    Container,
} from './HorizontalScrollMenu.style';

export type MenuItem = {
    id: number;
    name: string;
};

export type HorizontalScrollMenuProps = {
    items: MenuItem[];
    selectedItemId?: number | null;
    onChange?: (id: number) => void;
};

export const HorizontalScrollMenu: React.FunctionComponent<HorizontalScrollMenuProps> = ({
    items,
    selectedItemId,
    onChange,
}) => {
    const handleOnClick = useCallback(
        (id: number) => () => {
            onChange && onChange(id);
        },
        [onChange],
    );

    const selectedElementId = `menu-${selectedItemId}`;

    return (
        <Container>
            <ScrollContainer selectedElementId={selectedElementId}>
                {items.map(({ id, name }) => (
                    <Content key={id}>
                        <CategoryItem
                            id={`menu-${id}`}
                            selected={selectedItemId === id}
                            onClick={handleOnClick(id)}
                        >
                            <Text text={name} noTranslation type={2} weight="Regular" />
                        </CategoryItem>
                        <ScrollbarItem
                            selected={selectedItemId === id}
                            data-testid={`selected-${id}`}
                        />
                    </Content>
                ))}
            </ScrollContainer>
            <Scrollbar />
        </Container>
    );
};
