import styled from '@emotion/styled';
import { BottomBar, Callout, GrowingContainer, PageWithBottomBarContainer } from 'components';

export const Container = styled(PageWithBottomBarContainer)``;

export const Picker = styled(GrowingContainer)`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: ${({ theme }) => theme.base.spacing[2]};

    > h2 {
        margin-bottom: ${({ theme }) => theme.base.spacing[3]};
    }

    > div[role='radiogroup'] {
        padding-bottom: ${({ theme }) => theme.base.spacing[1]};
        border-bottom: ${({ theme }) => `0.5px solid ${theme.feature.thirdaryTextColor}`};
        margin-bottom: ${({ theme }) => theme.base.spacing[3]};
    }
`;

export const SummaryContainer = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const StyledBottomBar = styled(BottomBar)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: ${({ theme }) => theme.feature.backgroundColor};
    position: sticky;
    width: 100%;
    bottom: 0;
    left: 0;
`;

export const ButtonContainer = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
    width: 100%;
`;

export const StyledCallout = styled(Callout)`
    height: auto;
    padding: ${({ theme }) => theme.base.spacing[1]};
    gap: ${({ theme }) => theme.base.spacing[1]};
`;

export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: ${({ theme }) => theme.base.spacing[5]};
    margin: ${({ theme }) => theme.base.spacing[2]};
    text-align: center;
`;
