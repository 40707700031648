import styled from '@emotion/styled';
import { GrowingContainer } from 'components';

export const UserInformationContentContainer = styled(GrowingContainer)`
    padding: 0 ${({ theme }) => theme.base.spacing[2]};
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;

export const UserNameContainer = styled.div`
    display: flex;
    flex-direction: column;

    /**
    To avoid iOS auto zooming in Input
    https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming#bootstrap-default-input-font-sizing-1rem
    */
    * {
        font-size: 16px;
    }
`;
