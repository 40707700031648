import { RoutesPath } from 'core/routes.types';
import { WorkflowStateContext, WorkflowStateEvent } from 'hooks/useWorkflowStateMachine/types';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { getCurrentConsumptionModeType, getRestaurantId } from 'redux/app';
import { useAppSelector } from 'redux/store';
import { convertModeToUrl } from 'utils/consumptionModes';

export type CustomNavigateFunctions = keyof ReturnType<typeof useCustomNavigate>;

export const useCustomNavigate = () => {
    const navigate = useNavigate();
    const restaurantId = useAppSelector(getRestaurantId);
    const consumptionMode = convertModeToUrl(useAppSelector(getCurrentConsumptionModeType));

    /**
     * HOME
     */
    const navigateToHome = useCallback(() => navigate(RoutesPath.Home), [navigate]);
    const navigateToRestaurants = useCallback(() => {
        if (consumptionMode) {
            navigate(
                generatePath(RoutesPath.Restaurants, {
                    mode: consumptionMode,
                }),
            );
        }
    }, [consumptionMode, navigate]);

    /**
     * MENU
     */
    const navigateToMenuHome = useCallback(() => {
        if (restaurantId && consumptionMode) {
            navigate(
                generatePath(RoutesPath.MenuHome, {
                    restaurantId: String(restaurantId),
                    mode: consumptionMode,
                }),
            );
        }
    }, [restaurantId, consumptionMode, navigate]);
    const navigateBackToMenuHome = useCallback(() => {
        restaurantId &&
            navigate(
                `../${generatePath(RoutesPath.MenuHome, {
                    restaurantId: String(restaurantId),
                    mode: consumptionMode,
                })}`,
            );
    }, [restaurantId, consumptionMode, navigate]);

    /**
     * LOGIN
     */
    const navigateToLogin = useCallback(() => {
        navigate(RoutesPath.Login);
    }, [navigate]);

    /**
     * ACCOUNT
     */
    const navigateToAccount = useCallback(() => {
        navigate(`../${RoutesPath.Account}`);
    }, [navigate]);

    /**
     * PRODUCT
     */
    const navigateToProduct = useCallback((id: number) => navigate(`products/${id}`), [navigate]);

    /**
     * CROSS SELLING
     */
    const navigateToCrossSelling = useCallback(() => navigate(`cross-selling`), [navigate]);

    /**
     * CART
     */
    const navigateToCart = useCallback(() => navigate(RoutesPath.Cart), [navigate]);

    /**
     * USER INFORMATION
     */
    const navigateToUserInformation = useCallback(
        () => navigate(`${RoutesPath.UserInformation}`),
        [navigate],
    );

    /**
     * PAYMENT
     */
    const navigateToChoosePayment = useCallback(
        () => navigate(RoutesPath.ChoosePayment),
        [navigate],
    );
    const navigateBackToChoosePayment = useCallback(
        () => navigate(`../${RoutesPath.ChoosePayment}`),
        [navigate],
    );
    const navigateToCardPayment = useCallback(() => {
        navigate(`../${RoutesPath.CardPayment}`);
    }, [navigate]);
    const navigateToGooglePayment = useCallback(() => {
        navigate(`../${RoutesPath.GooglePay}`);
    }, [navigate]);
    const navigateToApplePayment = useCallback(() => {
        navigate(`../${RoutesPath.ApplePay}`);
    }, [navigate]);
    const navigateToEdenredPayment = useCallback(() => {
        navigate(`../${RoutesPath.Edenred}`);
    }, [navigate]);
    const navigateToPaygreenPayment = useCallback(() => {
        navigate(`../${RoutesPath.Paygreen}`);
    }, [navigate]);
    const navigateToLuncheonVoucherPayment = useCallback(() => {
        navigate(`../${RoutesPath.LuncheonVoucherChoice}`);
    }, [navigate]);
    const navigateToPaymentLater = useCallback(() => {
        navigate(`../${RoutesPath.PaymentLaterChoice}`);
    }, [navigate]);
    const navigateToPaymentWithError = useCallback(
        (errorType: 'threeDS' | 'googleOrApplePay') =>
            navigate(`../${RoutesPath.ChoosePayment}`, {
                state:
                    errorType === 'googleOrApplePay'
                        ? { googlePayError: true, applePayError: true }
                        : { threeDSError: true },
            }),
        [navigate],
    );

    /**
     * ORDER
     */
    const navigateToOrderError = useCallback(
        () => navigate(`../${RoutesPath.OrderError}`),
        [navigate],
    );
    const navigateToCartError = useCallback(
        () => navigate(`../${RoutesPath.CartError}`),
        [navigate],
    );
    const navigateToOrderConfirmation = useCallback(
        (_context: WorkflowStateContext, event: WorkflowStateEvent) => {
            event.currentOrderId &&
                navigate(
                    generatePath(RoutesPath.OrderConfirmation, {
                        orderUuid: event.currentOrderId,
                    }),
                );
        },
        [navigate],
    );
    const navigateToOrderFollowUp = useCallback(
        (_context: WorkflowStateContext, event: WorkflowStateEvent) => {
            event.currentOrderId &&
                navigate(
                    generatePath(RoutesPath.OrderFollowUp, {
                        orderUuid: event.currentOrderId,
                    }),
                );
        },
        [navigate],
    );

    return {
        navigateToHome,
        navigateToRestaurants,
        navigateToMenuHome,
        navigateToLogin,
        navigateToAccount,
        navigateBackToMenuHome,
        navigateToProduct,
        navigateToCart,
        navigateToCrossSelling,
        navigateToUserInformation,
        navigateToChoosePayment,
        navigateBackToChoosePayment,
        navigateToCardPayment,
        navigateToGooglePayment,
        navigateToApplePayment,
        navigateToEdenredPayment,
        navigateToPaygreenPayment,
        navigateToLuncheonVoucherPayment,
        navigateToPaymentLater,
        navigateToPaymentWithError,
        navigateToOrderError,
        navigateToCartError,
        navigateToOrderConfirmation,
        navigateToOrderFollowUp,
    };
};
