import { CalloutProps } from './Callout.interface';
import { StyledContainer } from './Callout.style';

export const Callout: React.FunctionComponent<CalloutProps> = ({
    variant = 'warning',
    children,
    ...otherProps
}) => {
    return (
        <StyledContainer {...otherProps} variant={variant} data-testid="callout">
            {children}
        </StyledContainer>
    );
};
