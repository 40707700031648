import React from 'react';
import { Link } from 'react-router-dom';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Button, Text, InputWrapper, Checkbox, BottomBar } from 'components';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { RoutesPath } from 'core/routes.types';
import {
    Container,
    CardContent,
    Line,
    TermsAndConditions,
    IconsContainer,
    Privacy,
    StripeConfirmTitle,
    Informations,
} from './StripeForm.style';
import { useStripeFormVM } from './useStripeFormVM';

type Props = {
    handleStripePaymentSubmit: (stripePaymentMethodId: string | undefined) => void;
    isLoading?: boolean;
};

const StripeForm: React.FunctionComponent<Props> = ({ handleStripePaymentSubmit, isLoading }) => {
    const {
        buttonText,
        termsAndConditions,
        canSubmit,
        options,
        numberStatus,
        expiryStatus,
        cvcStatus,
        handleChange,
        handleSubmit,
        handleTermsAndConditionsChange,
        focus,
        handleFocusChange,
    } = useStripeFormVM(isLoading);

    return (
        <Container onSubmit={handleSubmit(handleStripePaymentSubmit)}>
            <CardContent>
                <StripeConfirmTitle text={'card_information'} type={5} />
                <Line>
                    <InputWrapper
                        error={numberStatus?.error || ''}
                        isTouched={numberStatus?.complete}
                        disabled={isLoading}
                        isFocused={focus.number}
                    >
                        <CardNumberElement
                            options={options}
                            onBlur={handleFocusChange('number')(false)}
                            onFocus={handleFocusChange('number')(true)}
                            onChange={handleChange('number')}
                        />
                    </InputWrapper>
                </Line>
                <Line>
                    <InputWrapper
                        error={expiryStatus?.error || ''}
                        isTouched={expiryStatus?.complete}
                        disabled={isLoading}
                        isFocused={focus.expiry}
                    >
                        <CardExpiryElement
                            options={options}
                            onBlur={handleFocusChange('expiry')(false)}
                            onFocus={handleFocusChange('expiry')(true)}
                            onChange={handleChange('expiry')}
                        />
                    </InputWrapper>
                    <InputWrapper
                        error={cvcStatus?.error || ''}
                        isTouched={cvcStatus?.complete}
                        disabled={isLoading}
                        isFocused={focus.cvc}
                    >
                        <CardCvcElement
                            options={options}
                            onBlur={handleFocusChange('cvc')(false)}
                            onFocus={handleFocusChange('cvc')(true)}
                            onChange={handleChange('cvc')}
                        />
                    </InputWrapper>
                </Line>
                <Informations>
                    <TermsAndConditions>
                        <Checkbox
                            checked={termsAndConditions}
                            onClick={handleTermsAndConditionsChange}
                            disabled={isLoading}
                        />
                        <div>
                            <Text text={'terms_and_conditions.accept'} type={1} />{' '}
                            <Link to={`../${RoutesPath.TermsAndConditions}`}>
                                <Text text={'terms_and_conditions.title_short'} type={1} />
                            </Link>
                        </div>
                    </TermsAndConditions>
                    <Privacy>
                        <Text text={'payment.crypted_data'} type={1} />
                        <IconsContainer>
                            <SVGIcon icon={SVGIcons.MASTERCARD} />
                            <SVGIcon icon={SVGIcons.VISA} />
                            <SVGIcon icon={SVGIcons.LOCK} />
                            <SVGIcon icon={SVGIcons.SSL} />
                        </IconsContainer>
                    </Privacy>
                </Informations>
            </CardContent>
            <BottomBar withPadding>
                <Button
                    fullWidth
                    disabled={!canSubmit}
                    type="submit"
                    isLoading={isLoading}
                    size="l"
                >
                    {buttonText}
                </Button>
            </BottomBar>
        </Container>
    );
};

export default StripeForm;
