import { useAppSelector } from 'redux/store';
import { getBrand } from 'redux/brand';
import { useCustomNavigate } from 'hooks/useCustomNavigate';

export const useTermsAndConditionsVM = () => {
    const { navigateToCardPayment } = useCustomNavigate();

    const brand = useAppSelector(getBrand);

    return {
        navigateToCardPayment,
        brandName: brand?.name ?? '',
    };
};
