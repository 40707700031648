import { ChangeEvent, useState } from 'react';
import { Container } from './TextArea.style';

export type TextAreaProps = {
    rows: number;
    maxCount: number;
    onChange?: (value: string) => void;
    defaultValue?: string;
    autoFocus?: boolean;
};

export const TextArea: React.FunctionComponent<TextAreaProps> = ({
    rows,
    maxCount,
    onChange,
    defaultValue,
    autoFocus = false,
}) => {
    const [value, setValue] = useState(defaultValue || '');

    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (!maxCount || (maxCount && event.target.value.length <= maxCount)) {
            setValue(event.target.value);
            onChange && onChange(event.target.value);
        }
    };

    return (
        <Container>
            <textarea
                data-testid="text-area"
                value={value}
                rows={rows}
                onChange={handleOnChange}
                autoFocus={autoFocus}
            />
            {maxCount && (
                <p>
                    {value.length} / {maxCount}
                </p>
            )}
        </Container>
    );
};
