import { ChannelId } from 'services/order/order.type';
import { ConsumptionMode } from 'services/consumptionMode/consumptionMode.type';
import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../providers/innovorder.types';

const consumptionModeApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        consumptionModeList: build.mutation<
            ConsumptionMode[],
            ApiRequest<{ channelId: ChannelId; restaurantId?: number }>
        >({
            query: ({ channelId, restaurantId }) => ({
                url: '/consumption_modes',
                method: 'GET',
                params: { channel_id: channelId, restaurant_id: restaurantId },
            }),
            transformResponse: (response: ApiResponse<ConsumptionMode[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useConsumptionModeListMutation } = consumptionModeApi;
