import styled from '@emotion/styled';
import { HorizontalScrollContainer } from 'components/HorizontalScrollContainer';

export const Container = styled.div`
    position: relative;
`;

export const ScrollContainer = styled(HorizontalScrollContainer)`
    height: 60px;
`;

export const CategoryItem = styled.div<{ selected?: boolean }>`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.base.spacing[1]};

    h2,
    span {
        line-height: 18px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Scrollbar = styled.div`
    position: absolute;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.feature.primaryColor};
    opacity: 0.1;
`;

export const ScrollbarItem = styled.div<{ selected?: boolean }>`
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.feature.primaryColor};
    opacity: ${({ selected }) => (selected ? 1 : 0)};
`;
