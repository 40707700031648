import React, { useEffect } from 'react';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { init } from '@elastic/apm-rum';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ENV } from 'utils/env';
import './i18n';
import { getBrand } from 'redux/brand';
import { useAppSelector } from 'redux/store';

export const InitialiseThirdParties: React.FunctionComponent = () => {
    const ldClient = useLDClient();
    const brand = useAppSelector(getBrand);

    useEffect(() => {
        Sentry.init({
            dsn: ENV.SENTRY_DSN,
            integrations: [
                new Integrations.BrowserTracing(),
                new Sentry.Integrations.Breadcrumbs(),
            ],
            tracesSampleRate: 1.0,
            environment: ENV.APP_ENV,
        });
    }, []);

    useEffect(() => {
        if (ENV.ELASTIC_APM_ACTIVE) {
            init({
                active: ENV.ELASTIC_APM_ACTIVE,
                environment: ENV.ELASTIC_APM_ENVIRONMENT,
                serviceName: ENV.ELASTIC_APM_SERVICE_NAME,
                serverUrl: ENV.ELASTIC_APM_SERVER_URL,
                serviceVersion: ENV.APP_VERSION,
            });
        }
    }, []);

    useEffect(() => {
        if (ldClient !== undefined && brand) {
            ldClient.identify({
                key: `brandId-${brand.brandId}`,
                name: brand.name,
                kind: 'org',
            });
        }
    }, [ldClient, brand]);

    return null;
};

export default InitialiseThirdParties;
