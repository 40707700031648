import styled from '@emotion/styled';
import { HorizontalScrollContainer } from 'components/HorizontalScrollContainer';

export const CarouselHeight = '54px';

export const ScrollContainer = styled(HorizontalScrollContainer)`
    height: 74px;
    margin-top: 0;
`;

export const CarouselItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${({ theme }) => theme.base.spacing[1]};
    margin-right: ${({ theme }) => theme.base.spacing[1]};
    flex-direction: column;
`;

export const ImageContainer = styled.div<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${CarouselHeight};
    width: ${CarouselHeight};
    padding: ${({ theme }) => theme.base.spacing[1]};

    border-radius: 50%;
    overflow: hidden;
    transition: transform 0.5s ease;

    ${({ theme, selected }) =>
        selected && `background-color: ${theme.feature.ctaColor}40; transform: rotate(10deg);`}
    img {
        display: block;
        width: 100%;
        max-width: 50px;
        max-height: 50px;
        height: auto;
        width: auto;
        transition: transform 0.5s ease;
        ${({ selected }) => (selected ? 'transform: scale(0.7)' : '')};
    }
`;
