import { WorkflowStateMachineContext } from 'hooks/useWorkflowStateMachine';
import { useInitWorkflowStateMachine } from 'hooks/useWorkflowStateMachine/useInitWorkflowStateMachine';
import React, { PropsWithChildren } from 'react';

const WorkflowStateMachineProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
    const workflowService = useInitWorkflowStateMachine();

    return (
        <WorkflowStateMachineContext.Provider value={{ workflowService }}>
            {children}
        </WorkflowStateMachineContext.Provider>
    );
};

export default WorkflowStateMachineProvider;
