import { ConsumptionModeType } from '@innovorder/order_detail';
import { TranslationKey } from 'locales/fr';
import { nextOpeningToI18n } from 'hooks/useNextOpeningI18n';
import { TFunction } from 'i18next';
import { RestaurantSchedulesState } from 'redux/restaurantSchedules';
import { ConsumptionMode } from 'services/consumptionMode/consumptionMode.type';
import { RestaurantFamilies, WeborderingRestaurants } from 'services/brand/brand.type';
import {
    DecoratedWeborderingRestaurant,
    isDecoratedWeborderingRestaurant,
    isEligibleToMode,
    isPartOfTheFamily,
} from './type';

export const computeWeborderingRestaurants = ({
    t,
    restaurants,
    restaurantSchedules,
    currentFamilyFilter,
    consumptionMode,
    consumptionModeList,
}: {
    t: TFunction;
    restaurants: WeborderingRestaurants | undefined;
    restaurantSchedules: RestaurantSchedulesState;
    currentFamilyFilter: number | null;
    consumptionMode: ConsumptionModeType | null;
    consumptionModeList: Record<number, ConsumptionMode[]> | undefined;
}): { restaurants: DecoratedWeborderingRestaurant[]; subtitle: TranslationKey | null } => {
    const earlyReturn = (subtitle: TranslationKey) => ({
        restaurants: [],
        subtitle,
    });

    if (!restaurants || !consumptionModeList || !consumptionMode)
        return earlyReturn('restaurants.no_restaurant_error');

    const withImages = restaurants.filter(isDecoratedWeborderingRestaurant);
    if (!withImages.length) return earlyReturn('restaurants.subtitle.no_image');

    const belongToFamilies = withImages.filter((r) => isPartOfTheFamily(r, currentFamilyFilter));
    if (!belongToFamilies.length) return earlyReturn('restaurants.no_restaurant_error');

    const eligibleToMode = belongToFamilies.filter(({ restaurantId }) =>
        isEligibleToMode(consumptionModeList[restaurantId], consumptionMode),
    );
    if (!eligibleToMode.length) return earlyReturn('restaurants.subtitle.wrong_mode');

    const computedRestaurants = eligibleToMode.map((restaurant) => {
        const scheduleInfo = restaurantSchedules?.[restaurant.restaurantId];
        const shouldDisplayNextOpening = scheduleInfo?.isCurrentlyOpen === false;
        const nextOpeningOverlay = nextOpeningToI18n(t, scheduleInfo?.nextOpening);

        const { module_ordering_flows } = restaurant;
        const rushDelay = module_ordering_flows?.[0]?.rushDelay;

        return {
            ...restaurant,
            nextOpeningOverlay: shouldDisplayNextOpening ? nextOpeningOverlay : undefined,
            rushDelay:
                rushDelay && rushDelay > 0 ? t('rush_delay_badge', { rushDelay }) : undefined,
        };
    });

    return { restaurants: computedRestaurants, subtitle: null };
};

export const computeRestaurantFamilies = ({
    families,
}: {
    families: RestaurantFamilies | undefined;
}): { id: number; name: string; pictureUrl: string }[] => {
    if (!families) return [];

    return families
        .map(({ brandRestaurantFamilyId, name, imageUrl, position }) => ({
            id: brandRestaurantFamilyId,
            name,
            pictureUrl: imageUrl,
            position,
        }))
        .sort((a, b) => a.position - b.position);
};
