"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEE_TYPE = exports.AccountPaymentType = void 0;
var AccountPaymentType;
(function (AccountPaymentType) {
    AccountPaymentType["CASH"] = "CASH";
    AccountPaymentType["POSTPAYMENT"] = "POSTPAYMENT";
    AccountPaymentType["PREPAYMENT"] = "PREPAYMENT";
})(AccountPaymentType = exports.AccountPaymentType || (exports.AccountPaymentType = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
var FEE_TYPE;
(function (FEE_TYPE) {
    FEE_TYPE["DELIVERY"] = "DELIVERY";
    FEE_TYPE["SERVICE"] = "SERVICE";
    FEE_TYPE["ENTRANCE"] = "ENTRANCE_FEE";
    FEE_TYPE["ADMISSION_FEE"] = "AdmissionFee";
    FEE_TYPE["ROYALTY_FEE"] = "RoyaltyFee";
})(FEE_TYPE = exports.FEE_TYPE || (exports.FEE_TYPE = {}));
