"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isItemInDiscountScope = exports.isItemTagInDiscountScope = exports.isItemInDiscountScopeType = void 0;
var discount_types_1 = require("../../types/discount.types");
var isItemInDiscountScopeType = function (item, discount) {
    return discount.scopeTypes.has(item.discountableType);
};
exports.isItemInDiscountScopeType = isItemInDiscountScopeType;
var isItemTagInDiscountScope = function (item, discount) {
    if (!discount.scopeFilterValue) {
        return true;
    }
    // IF CHILD PRODUCT
    if (item.discountableType !== discount_types_1.DISCOUNTABLE_TYPE.FEE && item.depth > 0) {
        return (discount.scopeFilterValue === null ||
            item.parentTags.find(function (parentTag) {
                return discount.scopeFilterValueUncased.has(parentTag.toLocaleLowerCase());
            }) !== undefined);
    }
    // CASE 2 - PARENT PRODUCT OR FEE
    return (discount.scopeFilterValue === null ||
        item.tags.find(function (tag) { return discount.scopeFilterValueUncased.has(tag.toLocaleLowerCase()); }) !== undefined);
};
exports.isItemTagInDiscountScope = isItemTagInDiscountScope;
var isItemInDiscountScope = function (item, discount) {
    return (0, exports.isItemInDiscountScopeType)(item, discount) && (0, exports.isItemTagInDiscountScope)(item, discount);
};
exports.isItemInDiscountScope = isItemInDiscountScope;
