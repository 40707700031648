import { EnrichedProduct } from '@innovorder/order_detail';
import { CartItemDisplayerProps } from 'components/CartItemDisplayer/CartItemDisplayer';
import { computeOrderProductsForCart, computeProductsForCart } from 'pages/Cart/Cart.utils';
import { OrdersState } from 'redux/orders';
import { MultiOrderInformations } from 'services/multi-order/multi-order.type';
import { OrderInformations } from 'services/order/order.type';

export const computeRestaurantOrdersForConfirmation = ({
    data: order,
    isMultiOrder,
}:
    | {
          data?: MultiOrderInformations;
          isMultiOrder: true;
      }
    | {
          data?: OrderInformations;
          isMultiOrder: false;
      }): CartItemDisplayerProps['restaurantOrders'] => {
    if (!order) return [];

    const orders = isMultiOrder ? order.orders : [order];

    return orders.map((restaurantOrder) => {
        const {
            currency,
            restaurantId,
            cartRawData,
            orderId,
            omnichannelOrderId,
            dailyOrderId,
            ticketNumber,
            restaurant,
            mainStatus,
        } = restaurantOrder;

        const dailyOrderNumber = ticketNumber
            ? Number.parseFloat(ticketNumber.split('_').pop() ?? '')
            : dailyOrderId;
        const parsedCartRawData = JSON.parse(cartRawData) as { enrichedCart: EnrichedProduct[] };

        return {
            dailyOrderNumber,
            restaurantId,
            restaurantName: restaurant.name,
            cartProducts: computeProductsForCart(parsedCartRawData.enrichedCart, currency),
            orderId: isMultiOrder ? orderId : parseInt(omnichannelOrderId, 10),
            mainStatus,
        };
    });
};

export const computeRestaurantOrdersForCart = ({
    orders,
    isFoodCourt,
}: {
    orders: OrdersState;
    isFoodCourt: boolean;
}): CartItemDisplayerProps['restaurantOrders'] => {
    if (!orders) return [];

    return Object.values(orders).map((verifiedOrder) => {
        const restaurantName = verifiedOrder.restaurantAttributes?.name;
        return {
            ...(isFoodCourt && restaurantName ? { restaurantName } : {}),
            restaurantId: verifiedOrder.restaurantId,
            cartProducts: computeOrderProductsForCart(verifiedOrder),
        };
    });
};

export const computeRestaurantNameWithDailyOrderNumber = (
    restaurantName: string,
    dailyOrderNumber?: number,
) =>
    dailyOrderNumber !== undefined ? `${restaurantName} (n°${dailyOrderNumber})` : restaurantName;
