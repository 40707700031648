import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentOrderId } from 'redux/app';
import { getBrandOptionStatus } from 'redux/brand';
import { useAppSelector } from 'redux/store';

const RedirectToOrderFollowUp = () => {
    const { pathname } = useLocation();
    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const currentOrderId = useAppSelector(getCurrentOrderId);
    const { goCurrentOrder } = useWorkflowStateMachine();

    useEffect(() => {
        if (currentOrderId && isFoodCourt) {
            goCurrentOrder(currentOrderId);
        }
    }, [currentOrderId, isFoodCourt, goCurrentOrder, pathname]);
    return <></>;
};

export default RedirectToOrderFollowUp;
