"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortDiscountsByPosition = exports.sortByPosition = void 0;
var discount_types_1 = require("../../types/discount.types");
var sortByPosition = function (discounts) {
    var discountsWithoutPosition = discounts.filter(function (discount) { return discount.position === null; });
    var discountsWithoutPositionAbs = discountsWithoutPosition.filter(function (discount) { return discount.applyMethod === discount_types_1.APPLY_METHOD.ABSOLUTE; });
    var discountsWithoutPositionRate = discountsWithoutPosition.filter(function (discount) { return discount.applyMethod === discount_types_1.APPLY_METHOD.RATE; });
    var discountsWithPosition = discounts
        .filter(function (discount) { return discount.position !== null; })
        .sort(function (a, b) {
        if (a.position < b.position)
            return -1;
        if (a.position > b.position)
            return 1;
        return 0;
    });
    return __spreadArray(__spreadArray(__spreadArray([], discountsWithPosition, true), discountsWithoutPositionAbs, true), discountsWithoutPositionRate, true);
};
exports.sortByPosition = sortByPosition;
var sortDiscountsByPosition = function (discounts) {
    var grants = discounts.filter(function (discount) { return !discount_types_1.RemiseTypes.includes(discount.type); });
    var remisesAndLoyalties = discounts.filter(function (discount) { return discount_types_1.RemiseTypes.includes(discount.type); });
    var remises = remisesAndLoyalties.filter(function (discount) { return discount.type !== discount_types_1.DISCOUNT_TYPE.LOYALTY; });
    var loyalties = remisesAndLoyalties.filter(function (discount) { return discount.type === discount_types_1.DISCOUNT_TYPE.LOYALTY; });
    var sortedDiscounts = __spreadArray(__spreadArray(__spreadArray([], (0, exports.sortByPosition)(remises), true), (0, exports.sortByPosition)(grants), true), (0, exports.sortByPosition)(loyalties), true);
    return sortedDiscounts;
};
exports.sortDiscountsByPosition = sortDiscountsByPosition;
