import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.base.spacing[2]};
    padding: ${({ theme }) => theme.base.spacing[2]} 0;
`;

export const TriggerModal = styled.div<{ isValid?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.base.spacing[1]};
    background-color: ${({ theme }) => theme.base.colors.secondary[200]};
    border-radius: ${({ theme }) => theme.base.radius.default};
    padding: ${({ theme }) => theme.base.spacing[1]};
    border: ${({ isValid, theme }) => (isValid ? `1px solid ${theme.feature.successColor}` : '')};

    h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
