import { MultiOrderToVerify, VerifiedMultiOrder } from '@innovorder/order_detail';
import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../providers/innovorder.types';
import {
    CreatedMultiOrder,
    GetMultiOrderInformationsPayload,
    MultiOrderToCreate,
    MultiOrderInformations,
    MultiOrderConfirmPayload,
    SendAnonymousMultiOrderReceiptPayload,
} from './multi-order.type';

const orderApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        previewMultiOrder: build.mutation<
            VerifiedMultiOrder,
            ApiRequest<MultiOrderToVerify & { accessToken?: string }>
        >({
            query: ({ accessToken, ...body }) => {
                return {
                    url: `/multi-orders/preview`,
                    method: 'POST',
                    body: {
                        ...body,
                        comment: undefined,
                        promocode: undefined,
                    },
                    headers: {
                        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                    },
                };
            },
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<VerifiedMultiOrder>) => ({
                verifiedOrders: response.data.verifiedOrders,
                totalPrice: response.data.totalPrice,
            }),
            extraOptions: { maxRetries: 3 },
        }),

        getMultiOrder: build.query<
            MultiOrderInformations,
            GetMultiOrderInformationsPayload & { accessToken?: string }
        >({
            query: ({ multiOrderId, channelId, accessToken }) => ({
                url: `/multi-orders/${multiOrderId}`,
                method: 'GET',
                params: { channelId },
                headers: {
                    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<MultiOrderInformations>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        postMultiOrder: build.mutation<CreatedMultiOrder, ApiRequest<MultiOrderToCreate>>({
            query: ({ idempotencyKey, accessToken, ...body }) => {
                return {
                    url: `/multi-orders/`,
                    method: 'POST',
                    body: {
                        ...body,
                        comment: undefined,
                        promocode: undefined,
                    },
                    headers: {
                        'idempotency-key': idempotencyKey,
                        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                    },
                };
            },
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<CreatedMultiOrder>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        confirmMultiOrder: build.mutation<
            CreatedMultiOrder['multiCreatedOrders'],
            ApiRequest<MultiOrderConfirmPayload>
        >({
            query: (body) => ({
                url: `/multi-orders/confirm`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<CreatedMultiOrder['multiCreatedOrders']>) =>
                response.data,
            extraOptions: { maxRetries: 3 },
        }),

        sendAnonymousMultiOrderReceipt: build.mutation<
            undefined,
            ApiRequest<SendAnonymousMultiOrderReceiptPayload & { accessToken?: string }>
        >({
            query: ({ multiOrderId, email, channelId, accessToken }) => ({
                url: `/multi-orders/receipt`,
                method: 'POST',
                body: {
                    email,
                    multiOrderId,
                    channelId,
                },
                headers: {
                    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                },
            }),
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<undefined>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    usePreviewMultiOrderMutation,
    useGetMultiOrderQuery,
    usePostMultiOrderMutation,
    useConfirmMultiOrderMutation,
    useSendAnonymousMultiOrderReceiptMutation,
} = orderApi;
