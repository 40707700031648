"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addParentTagsToChildProducts = void 0;
var addParentTagsToChildProducts = function (products) {
    var productsWithParentsTag = [];
    for (var i = 0; i < products.length; i++) {
        if (products[i].tags.length > 0) {
            var currentDepth = products[i].depth;
            var parentTags = products[i].tags;
            for (var j = i + 1; j < products.length; j++) {
                if (products[j].depth <= currentDepth) {
                    break;
                }
                products[j].parentTags = products[j].parentTags.concat(parentTags);
            }
        }
        productsWithParentsTag.push(products[i]);
    }
    return productsWithParentsTag;
};
exports.addParentTagsToChildProducts = addParentTagsToChildProducts;
