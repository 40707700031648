import { CartItem, Title } from 'components';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { RestaurantOrder } from 'services/multi-order/multi-order.type';
import { UpdateProductQuantityInCartPayload } from 'hooks/useCartHandler';
import { OrdersStatusInformation } from 'pages/Order/OrderFollowUp/OrderFollowUp.utils';
import { SvyBadge } from '@innovorder/savory';
import { useTranslation } from 'react-i18next';
import { ShowOrderStatusModalPayload } from 'pages/Order/OrderFollowUp/useOrderFollowUpVM';
import { AppState } from 'redux/app';
import { OrderNumber, StyledImg, TitleContainer } from './CartItemDisplayer.style';

export type CartItemDisplayerProps = {
    isFoodCourt?: boolean;
    isLoading?: boolean;
    currency: string;
    restaurantOrders: RestaurantOrder[];
    onValueChange?: (payload: UpdateProductQuantityInCartPayload) => void;
    ordersStatusInformation?: OrdersStatusInformation;
    onClick?: (payload: ShowOrderStatusModalPayload) => void;
    debouncingQuantity?: AppState['debouncingQuantity'];
};

export const CartItemDisplayer: React.FunctionComponent<CartItemDisplayerProps> = ({
    isFoodCourt,
    isLoading,
    currency,
    restaurantOrders,
    onValueChange,
    ordersStatusInformation,
    onClick,
    debouncingQuantity,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {restaurantOrders.map(
                ({ orderId, restaurantName, imageUrl, dailyOrderNumber, cartProducts }, index) => {
                    const badgeType = ordersStatusInformation?.[orderId!]?.badgeType;
                    const badgeText = ordersStatusInformation?.[orderId!]?.text;

                    const handleOnClick = () => {
                        onClick &&
                            orderId &&
                            restaurantName &&
                            onClick({ orderId, restaurantName, dailyOrderNumber });
                    };

                    return (
                        <div key={`${restaurantName} + ${index}`}>
                            {isFoodCourt && !!restaurantName && (
                                <>
                                    <TitleContainer
                                        isFirst={index === 0}
                                        onClick={handleOnClick}
                                        data-testid="title-container"
                                    >
                                        <div>
                                            {imageUrl && <StyledImg src={imageUrl} />}
                                            <Title
                                                data-testid="cart-restaurant-name"
                                                text={restaurantName}
                                                noTranslation={true}
                                                type={5}
                                            />
                                        </div>
                                        {badgeType && badgeText && (
                                            <SvyBadge color={badgeType} label={t(badgeText)} />
                                        )}
                                    </TitleContainer>
                                </>
                            )}
                            {dailyOrderNumber !== undefined && (
                                <OrderNumber onClick={handleOnClick}>
                                    <SvyBadge
                                        color="primary"
                                        label={t('order_confirmation_title')}
                                    />

                                    <SvyBadge
                                        color="primary"
                                        label={t('order_number_short', {
                                            number: dailyOrderNumber,
                                        })}
                                        data-testid="daily-order-number"
                                    />
                                </OrderNumber>
                            )}
                            {cartProducts.map((product) => (
                                <CartItem
                                    key={uuidv4()}
                                    {...product}
                                    onValueChange={onValueChange}
                                    isLoading={isLoading}
                                    currency={currency}
                                    debouncingQuantity={debouncingQuantity}
                                />
                            ))}
                        </div>
                    );
                },
            )}
        </>
    );
};
