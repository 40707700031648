"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyDiscountOnItems = void 0;
var grant_tags_1 = require("../../scopes/grant-tags");
var compute_discounted_amount_1 = require("../compute-discounted-amount/compute-discounted-amount");
var utils_1 = require("./utils");
// eslint-disable-next-line import/no-cycle
var applyDiscountOnItems = function (items, discount, totalPriceWithDiscount) {
    var usedAmountNotRoundedInclTax = discount.usedAmountNotRoundedInclTax, usedAmountNotRoundedExclTax = discount.usedAmountNotRoundedExclTax;
    var discountables = discount.discountables;
    var itemsUpdated = items.map(function (item) {
        if (!(0, grant_tags_1.isItemInDiscountScope)(item, discount)) {
            return item;
        }
        var _a = (0, compute_discounted_amount_1.computeDiscountedAmount)({
            itemPriceNotRounded: item.totalPriceNotRounded,
            taxRate: item.taxRate,
            discount: discount,
            totalPriceWithDiscount: totalPriceWithDiscount,
        }), discountedAmountInclTax = _a.discountedAmountInclTax, discountedAmountExclTax = _a.discountedAmountExclTax;
        usedAmountNotRoundedInclTax = usedAmountNotRoundedInclTax.plus(discountedAmountInclTax);
        usedAmountNotRoundedExclTax = usedAmountNotRoundedExclTax.plus(discountedAmountExclTax);
        discountables.push({
            id: item.id,
            taxRate: item.taxRate,
            discountedAmountInclTax: discountedAmountInclTax,
            discountedAmountExclTax: discountedAmountExclTax,
        });
        var newItemTotalPriceNotRounded = (0, utils_1.buildNewItemTotalPriceNotRounded)(item, discount.type, discountedAmountInclTax);
        return __assign(__assign({}, item), { totalPriceNotRounded: newItemTotalPriceNotRounded });
    });
    var discountUpdated = __assign(__assign({}, discount), { usedAmountNotRoundedInclTax: usedAmountNotRoundedInclTax, usedAmountNotRoundedExclTax: usedAmountNotRoundedExclTax, discountables: discountables });
    return { itemsUpdated: itemsUpdated, discountUpdated: discountUpdated };
};
exports.applyDiscountOnItems = applyDiscountOnItems;
