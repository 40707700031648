import styled from '@emotion/styled';

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const ModalContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.base.spacing[1]};
`;
