import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
    MultiOrderErrorPayload,
    OrderErrorPayload,
    isSingleOrderError,
} from 'services/order/order.type';
import { BasicError } from 'services/providers/innovorder.types';

export const retrieveMessage = (unkownError: unknown): string => {
    const error = unkownError as BasicError;
    if (error === undefined || !error.status) {
        return '';
    }

    return error.message;
};

export const stringifyError = (
    error: FetchBaseQueryError | SerializedError | undefined,
): string => {
    if (!error) {
        return '';
    }

    if ('status' in error) {
        return retrieveMessage(error.data);
    }

    return error?.message ?? '';
};

export const extractFirstErrorMessage = (error: OrderErrorPayload): string => {
    if (!error || !error.data || !error.data.extraData || !error.data.extraData.errors.length) {
        return '';
    }

    return retrieveMessage(error.data.extraData.errors[0]);
};

export const extractErrorsCode = (error: OrderErrorPayload | MultiOrderErrorPayload): string[] => {
    if (!error || !error.data || !error.data.extraData || !error.data.extraData.errors) {
        return [];
    }

    const errors = isSingleOrderError(error.data.extraData)
        ? error.data.extraData.errors
        : error.data.extraData.errors.map((e) => e.extraData.errors).flat();

    if (!errors.length) return [];

    return errors.map((e) => e.code);
};
