import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[2]};
    gap: ${({ theme }) => theme.base.spacing[3]};

    > button {
        width: 50%;
        align-self: center;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.base.spacing[2]};
    padding-top: ${({ theme }) => theme.base.spacing[2]};
    align-items: center;
`;

export const SuccessContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.base.spacing[1]};
    padding-top: ${({ theme }) => theme.base.spacing[2]};
    align-items: center;
`;

export const BalanceContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.base.spacing[1]};
    padding-top: ${({ theme }) => theme.base.spacing[1]};
    align-items: center;
`;

export const ErrorContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};

    span {
        color: ${({ theme }) => theme.feature.errorColor};
    }
`;
