import { Button, Modal, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { ModalContainer, ModalContent } from './NoPaymentMethodModal.style';

export type NoPaymentMethodModalProps = {
    isOpen: boolean;
    onHide: () => void;
};

const NoPaymentMethodModal: React.FunctionComponent<NoPaymentMethodModalProps> = ({
    isOpen,
    onHide,
}) => {
    const { t } = useTranslation();

    return (
        <Modal headerTitle={'modal_no_payment_method.title'} isOpen={isOpen} onHide={onHide}>
            <ModalContainer>
                <ModalContent>
                    <Text text={'modal_no_payment_method.content'} type={3} />
                </ModalContent>
                <Button onClick={onHide} variant="text">
                    {t('modal_no_payment_method.button')}
                </Button>
            </ModalContainer>
        </Modal>
    );
};

export default NoPaymentMethodModal;
