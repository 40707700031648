import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCrossSellingAlreadyTriggered, updateAppState } from 'redux/app';

import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine/useWorkflowStateMachine';
import { useCartHandler } from 'hooks/useCartHandler';
import { useCrossSelling } from 'hooks/useCrossSelling/useCrossSelling';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useTranslation } from 'react-i18next';
import { getBrandCurrency } from 'redux/brand';
import { SelectedProduct, formatCrossSellingProducts } from './utils';

export const useCrossSellingVM = () => {
    // ---- HOOKS ----
    const { goNext, goCart } = useWorkflowStateMachine();
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const currency = useAppSelector(getBrandCurrency);
    const isCrossSellingAlreadyTriggered = useAppSelector(getCrossSellingAlreadyTriggered);
    const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | null>(null);
    const { data, isLoading, findCrossSellingEnabled, isSuccess } = useCrossSelling();
    const {
        handleAddProductToCart,
        isLoading: isPreviewLoading,
        data: previewData,
    } = useCartHandler();

    // ---- FUNCTIONS ----
    const handleSelectProduct = useCallback(
        (product: SelectedProduct) => {
            if (product && product.menuId && product.restaurantId) {
                setSelectedProduct({
                    productId: product.productId,
                    restaurantId: product.restaurantId,
                    menuId: product.menuId,
                });
                handleAddProductToCart({
                    productId: product.productId,
                    quantity: 1,
                    restaurantId: product.restaurantId,
                    menuId: product.menuId,
                });
            }
        },
        [handleAddProductToCart, setSelectedProduct],
    );

    const handleClickOnNext = useCallback(() => {
        goNext();
        dispatch(updateAppState({ crossSellingAlreadyTriggered: true }));
    }, [goNext, dispatch]);

    // ---- EFFECTS ----
    /*
     * Fetching crossSelling products
     */
    useEffect(() => {
        findCrossSellingEnabled();
    }, [findCrossSellingEnabled]);

    /*
     * setting crossSellingAlreadyTriggered after a product is added to the cart
     */
    useEffect(() => {
        if (!isPreviewLoading && previewData) {
            dispatch(updateAppState({ crossSellingAlreadyTriggered: true }));
        }
    }, [isPreviewLoading, goNext, previewData, dispatch]);

    /*
     * Navigate to next if cross-selling list is empty
     */
    useEffect(() => {
        if (isSuccess && !data?.length) {
            goNext();
        }
    }, [goNext, data, isSuccess]);

    /*
     * Navigate to cart when product is added and redux is updated
     */
    useEffect(() => {
        if (!isPreviewLoading && previewData && isCrossSellingAlreadyTriggered) {
            goCart();
        }
    }, [isPreviewLoading, goCart, previewData, isCrossSellingAlreadyTriggered]);

    // ---- COMPUTED DATA ----
    const formattedProducts = useMemo(() => {
        if (data?.length) {
            return data
                ? formatCrossSellingProducts({
                      products: data[0].products,
                      selectedProducts: selectedProduct ? [selectedProduct] : [],
                      translate: i18n,
                      currency: currency ?? 'EUR',
                  })
                : [];
        }
        return [];
    }, [data, i18n, currency, selectedProduct]);

    const title = useMemo(() => {
        if (data?.length) {
            return data[0].title;
        }
        return '';
    }, [data]);

    return {
        data,
        title,
        isLoading,
        isButtonLoading: isPreviewLoading,
        formattedProducts,
        handleSelectProduct,
        handleClickOnNext,
        goCart,
    };
};
