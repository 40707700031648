import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.base.spacing[2]};
`;

export const OptionContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const OptionContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const SummaryContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;

export const OptionNameContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.base.spacing[1]};
    svg {
        width: 30px;
    }
`;

export const OptionNameContent = styled.div<{ isMultiline?: boolean }>`
    display: flex;
    flex-flow: ${({ isMultiline }) => (isMultiline ? 'column' : 'row')} nowrap;
    justify-content: center;
    align-items: ${({ isMultiline }) => (isMultiline ? 'flex-start' : 'center')};
    gap: ${({ theme }) => theme.base.spacing[1]};
`;

export const OptionIconsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.base.spacing[1]};
`;
