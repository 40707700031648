import {
    Container,
    DelayBadge,
    ImgContainer,
    OverlayBackground,
    OverlayTextContainer,
    StyledOverlayText,
    StyledText,
    TagBadge,
} from './RestaurantCard.style';

export type RestaurantTag = {
    value: string;
};

export type RestaurantCardProps = {
    imageUrl: string;
    name: string;
    restaurantTags: RestaurantTag[];
    onClick: () => void;
    overlayText?: string;
    rushDelay?: string;
};

export const RestaurantCard: React.FunctionComponent<RestaurantCardProps> = ({
    imageUrl,
    name,
    restaurantTags,
    onClick,
    overlayText,
    rushDelay,
}) => {
    return (
        <Container>
            <ImgContainer onClick={onClick} data-testid="restaurant-card-img" imgUrl={imageUrl}>
                {!!overlayText && (
                    <>
                        <OverlayBackground />
                        <OverlayTextContainer>
                            <StyledOverlayText
                                text={overlayText}
                                noTranslation
                                type={2}
                                weight="Bold"
                            />
                        </OverlayTextContainer>
                    </>
                )}
                {!overlayText && restaurantTags.length > 0 && (
                    <TagBadge
                        data-testid="restaurant-tag"
                        color="primary"
                        label={restaurantTags[0].value}
                    />
                )}
                {!overlayText && rushDelay && (
                    <DelayBadge data-testid="delay-badge" color="warning" label={rushDelay} />
                )}
            </ImgContainer>

            <StyledText text={name} noTranslation type={2} weight="Bold" />
        </Container>
    );
};
