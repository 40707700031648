import { HeaderTitle } from 'components';
import {
    Container,
    CrossSellingBadgeSkeleton,
    CrossSellingImageSkeleton,
    CrossSellingTextSkeleton,
    SkeletonGridContainer,
    CrossSellingTitleSkeleton,
} from './CrossSellingSkeleton.style';

const CrossSellingSkeleton: React.FunctionComponent = () => {
    return (
        <Container data-testid="cross-selling-skeleton">
            <HeaderTitle>
                <CrossSellingTitleSkeleton />
            </HeaderTitle>
            <SkeletonGridContainer>
                {new Array(6).fill(1).map((_, index) => (
                    <div key={`cross-selling-item-${index}`}>
                        <CrossSellingImageSkeleton />
                        <CrossSellingTextSkeleton />
                        <CrossSellingBadgeSkeleton />
                    </div>
                ))}
            </SkeletonGridContainer>
        </Container>
    );
};

export default CrossSellingSkeleton;
