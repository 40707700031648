import styled from '@emotion/styled';
import { Title } from 'components/Title';
import { Separator } from 'pages/Menu/Product/ProductSteps/ProductSteps.style';

export const ProductListContainer = styled.ul`
    padding: ${({ theme }) => theme.base.spacing[2]};
    padding-bottom: 0;
`;

export const ListContainer = styled.ul`
    max-width: 100%;
`;

export const ProductListTitle = styled(Title)`
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
    position: relative;
`;

export const CategorySeparator = styled(Separator)`
    height: 4px;
`;
