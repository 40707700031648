import { BaseTheme, defaultCssVariables, FeaturesTheme, ThemeType } from 'core/styled';
import { ModuleStyleStyles } from 'services/brand/brand.type';
import produce from 'immer';

const computeBaseTheme = (defaultBaseTheme: BaseTheme, apiTheme?: ModuleStyleStyles): BaseTheme => {
    if (!apiTheme?.is_enabled) {
        return defaultBaseTheme;
    }

    return produce<BaseTheme>(defaultBaseTheme, (draftTheme) => {
        draftTheme.colors.orderingMainColor = apiTheme.style.orderingMainColor;
        draftTheme.colors.orderingHighlightColor = apiTheme.style.orderingHighlightColor;
        draftTheme.background = apiTheme.style.backgroundImage || '';
        draftTheme.logoBrandImage = apiTheme.style.logoBrandImage || '';
    });
};

const computeFeaturesTheme = (
    defaultFeaturesTheme: FeaturesTheme,
    apiTheme?: ModuleStyleStyles,
): FeaturesTheme => {
    if (!apiTheme?.is_enabled) {
        return defaultFeaturesTheme;
    }

    return produce(defaultFeaturesTheme, (draftTheme) => {
        // When api has theme prorerties insert here.
        return draftTheme;
    });
};

const camelToKebabCase = (str: string) =>
    str.replace(/[A-Z]/g, (letter: string) => `-${letter.toLowerCase()}`);

const buildCssTheme = (
    theme?: Record<string, unknown>,
    parentKey?: string,
): Array<[string, string]> => {
    return Object.entries(theme || {}).flatMap(([key, value]) => {
        const currentKey = parentKey ? `${parentKey}-${key}` : key;

        if (typeof value === 'string') {
            return [[`--${camelToKebabCase(currentKey)}`, value]];
        }

        return buildCssTheme(value as Record<string, unknown>, currentKey);
    });
};

export const makeCssTheme = (theme: BaseTheme) => Object.fromEntries(buildCssTheme(theme));

export const makeTheme = (apiTheme?: ModuleStyleStyles) => {
    const baseTheme = computeBaseTheme(defaultCssVariables, apiTheme);

    const defaultFeaturesTheme: FeaturesTheme = {
        primaryColor: baseTheme.colors.orderingMainColor, // orderingMainColor
        headerTextColor: baseTheme.colors.secondary[50],
        primaryTextColor: baseTheme.colors.neutral[900],
        secondaryTextColor: baseTheme.colors.neutral[600],
        thirdaryTextColor: baseTheme.colors.neutral[300],
        backgroundColor: baseTheme.colors.secondary[50],
        ctaColor: baseTheme.colors.orderingHighlightColor, // orderingHighlightColor
        ctaTextColor: baseTheme.colors.secondary[50],
        ctaSuccessColor: baseTheme.colors.primary[500],
        incognitoColor: baseTheme.colors.incognitoColor, // incognitoColor
        errorColor: baseTheme.colors.destructive[600],
        successColor: baseTheme.colors.success[500],
    };

    const featuresTheme = computeFeaturesTheme(defaultFeaturesTheme, apiTheme);

    const theme: ThemeType = {
        base: baseTheme,
        feature: featuresTheme,
    };

    return {
        themeCss: makeCssTheme(baseTheme),
        theme,
    };
};
