import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
import { crossSellingProductHeight } from 'components/CrossSellingProduct/CrossSellingProduct.style';
import { GridContainer } from '../CrossSelling.style';

export const Container = styled.div`
    background: ${({ theme }) => theme.feature.backgroundColor};
`;

export const CrossSellingTitleSkeleton = styled(Skeleton)`
    height: 32px;
    width: 300px;
`;

export const SkeletonGridContainer = styled(GridContainer)``;

export const CrossSellingImageSkeleton = styled(Skeleton)`
    height: ${crossSellingProductHeight};
    width: 100%;
`;

export const CrossSellingTextSkeleton = styled(Skeleton)`
    height: 24px;
    width: 100%;
`;

export const CrossSellingBadgeSkeleton = styled(Skeleton)`
    height: 24px;
    width: 50px;
`;
