import { Button, Modal, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { ModalContainer, ModalContent } from './ThreeDSErrorModal.style';

export type ThreeDSErrorModalProps = {
    isOpen: boolean;
    onHide: () => void;
};

const ThreeDSErrorModal: React.FunctionComponent<ThreeDSErrorModalProps> = ({ isOpen, onHide }) => {
    const { t } = useTranslation();

    return (
        <Modal headerTitle={'order_error_3ds.modal_title'} isOpen={isOpen} onHide={onHide}>
            <ModalContainer>
                <ModalContent>
                    <Text text={'order_error_3ds.modal_description'} type={3} />
                </ModalContent>
                <Button onClick={onHide} variant="text">
                    {t('order_error_3ds.modal_button')}
                </Button>
            </ModalContainer>
        </Modal>
    );
};

export default ThreeDSErrorModal;
