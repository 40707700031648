import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { Button, Title } from 'components';
import { TranslationKey } from 'locales/fr';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Content,
    ContentBody,
    ContentChildren,
    ContentHeader,
    HeaderAndIconContainer,
    Overlay,
    StyledIcon,
} from './Modal.style';

/**
 * The 'modal-root' div allows the portal to append on it
 * https://fr.reactjs.org/docs/portals.html
 */
const getModalRoot = () => {
    let modalRoot = document.getElementById('modal-root');

    if (!modalRoot) {
        modalRoot = document.createElement('div');
        modalRoot.setAttribute('id', 'modal-root');
        document.body.appendChild(modalRoot);
    }

    return modalRoot;
};

const modalRoot = getModalRoot();

export type ModalProps = PropsWithChildren<{
    isOpen: boolean;
    onHide?: () => void;
    icon?: React.FunctionComponent;
    headerTitle?: TranslationKey;
    buttonTitle?: TranslationKey;
    buttonAction?: () => void;
    isLoading?: boolean;
}>;

export const Modal: React.FunctionComponent<ModalProps> = ({
    children,
    isOpen,
    onHide,
    icon,
    headerTitle,
    buttonTitle,
    buttonAction,
    isLoading,
}) => {
    const Icon = icon;
    const { t } = useTranslation();

    if (!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <Container onClick={onHide}>
            <Overlay data-testid="modal-overlay" />
            <Content data-testid="modal" onClick={(e) => e.stopPropagation()}>
                {headerTitle && (
                    <ContentHeader>
                        <HeaderAndIconContainer>
                            {Icon && <Icon />}
                            {headerTitle && <Title text={headerTitle} type={5} />}
                        </HeaderAndIconContainer>
                        {onHide && (
                            <StyledIcon
                                icon="close-line"
                                onClick={onHide}
                                data-testid="modal-close"
                            />
                        )}
                    </ContentHeader>
                )}
                <ContentBody>
                    <ContentChildren withMargin={!!(buttonTitle && buttonAction)}>
                        {children}
                    </ContentChildren>
                    {buttonTitle && buttonAction && (
                        <Button
                            onClick={buttonAction}
                            isLoading={isLoading}
                            disabled={isLoading}
                            data-testid="modal-validate-btn"
                            size="m"
                        >
                            {t(buttonTitle)}
                        </Button>
                    )}
                </ContentBody>
            </Content>
        </Container>,
        modalRoot,
    );
};
