import React, { useCallback } from 'react';
import parse from 'html-react-parser';
import { ProductAttributesFromCategories } from 'services/menu/menu.type';
import { TextStyle } from 'components/Text/TextStyle';
import {
    ItemContentContainer,
    ProductImage,
    StyledItemContainer,
    ItemTextContainer,
    ItemTitle,
    ItemDescription,
    StyledPrice,
} from './ProductItem.style';

export type ProductItemProps = ProductAttributesFromCategories & {
    currency: string;
    onClick?: (id: number) => void;
};

export const ProductItem: React.FunctionComponent<ProductItemProps> = ({
    name,
    price,
    description,
    image,
    currency,
    productId,
    onClick,
}) => {
    const handleOnProductClick = useCallback(() => {
        if (onClick) onClick(productId);
    }, [productId, onClick]);

    return (
        <StyledItemContainer onClick={handleOnProductClick}>
            <ItemContentContainer>
                <ItemTextContainer>
                    <ItemTitle text={name} noTranslation type={3} weight="Bold" />
                    <br />
                    {price > 0 && (
                        <StyledPrice text={price} isCurrency currency={currency} type={3} />
                    )}
                    {description && (
                        <ItemDescription>
                            <TextStyle type={3}>{parse(description)}</TextStyle>
                        </ItemDescription>
                    )}
                </ItemTextContainer>
                {image && (
                    <ProductImage
                        data-testid="product-image"
                        style={{ backgroundImage: `url("${image.fullUrl}")` }}
                    />
                )}
            </ItemContentContainer>
        </StyledItemContainer>
    );
};
