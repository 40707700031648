import { ConsumptionModeType } from '@innovorder/order_detail';
import { RoutesPath } from 'core/routes.types';
import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { convertModeToUrl } from 'utils/consumptionModes';

const sitInMode = convertModeToUrl(ConsumptionModeType.MODE_SIT_IN);

const RedirectToNewUrl = ({ newUrl }: { newUrl: RoutesPath }) => {
    const navigate = useNavigate();
    const { restaurantId } = useParams();

    useEffect(() => {
        if (newUrl === RoutesPath.MenuHome && restaurantId)
            navigate(`../${generatePath(RoutesPath.MenuHome, { restaurantId, mode: sitInMode })}`);
        if (newUrl === RoutesPath.Restaurants)
            navigate(`../${generatePath(RoutesPath.Restaurants, { mode: sitInMode })}`);
    }, [navigate, restaurantId, newUrl]);
    return <></>;
};

export default RedirectToNewUrl;
