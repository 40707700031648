import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SvyIcon } from '@innovorder/savory';
import { Button } from 'components/Button';

export const backButtonSize = '40px';

export const BackButtonContainer = styled(Button)`
    width: ${backButtonSize};

    ${({ theme }) => css`
        --svy-button-background-color: ${theme.feature.backgroundColor} !important;
        --svy-button-hover-background-color: ${theme.feature.backgroundColor} !important;
        --svy-button-active-background-color: ${theme.feature.backgroundColor} !important;
        --svy-button-text-color: ${theme.feature.primaryTextColor} !important;
    `};

    box-shadow: ${({ theme }) => theme.base.shadow.outside[300]};
`;

export const StyledIcon = styled(SvyIcon)`
    &:before {
        font-size: 28px;
        font-weight: normal;
    }
`;
