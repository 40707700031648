"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canApplyGrantStrategy = void 0;
var discount_types_1 = require("../../types/discount.types");
var types_1 = require("../types");
var canApplyGrantStrategy = function (items, discount) {
    if (discount.strategy === discount_types_1.GrantStrategy.TagSubventionnable) {
        return !!items.find(function (item) { return item.tags.find(function (tag) { return tag === types_1.ProductTag.Subventionnable; }); });
    }
    return true;
};
exports.canApplyGrantStrategy = canApplyGrantStrategy;
