import styled from '@emotion/styled';

export const ScrollContainer = styled.div`
    background-color: ${({ theme }) => theme.feature.backgroundColor};
    margin: ${({ theme }) => theme.base.spacing[2]};
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
`;
