import { StyledRadio } from './Radio.style';

export type RadioProps = {
    checked: boolean;
    disabled?: boolean;
};

export const Radio: React.FunctionComponent<RadioProps> = ({ checked, ...otherProps }) => {
    return (
        <StyledRadio
            checked={checked}
            {...otherProps}
            data-testid={checked ? 'checked' : 'not-checked'}
        />
    );
};
