import React from 'react';
import { BottomDrawer, Button } from 'components';
import { TranslationKey } from 'locales/fr';
import {
    ButtonsContainer,
    Container,
    FoodCourtImage,
    MargedText,
    MargedTitle,
    OverTitleText,
    PictureContainer,
    TitleContainer,
} from './Incognito.style';
import { useIncognitoVM } from './useIncognitoVM';
import { ReactComponent as Picture } from './incognito.svg';

const Incognito: React.FunctionComponent = () => {
    const {
        step,
        button: { name, onClick, elementRight },
    } = useIncognitoVM();

    return (
        <Container data-testid="incognito-container">
            <PictureContainer>
                <Picture />
            </PictureContainer>

            <BottomDrawer>
                <TitleContainer>
                    <FoodCourtImage />
                    <OverTitleText text={`incognito_page.warning` as TranslationKey} type={1} />
                    <MargedTitle
                        text={`incognito_page.title` as TranslationKey}
                        type={4}
                        color="errorColor"
                    />
                </TitleContainer>

                <MargedText text={`incognito_page.${step}.content` as TranslationKey} type={3} />

                <ButtonsContainer>
                    <Button
                        key={name}
                        fullWidth
                        onClick={onClick}
                        elementRight={elementRight}
                        color="destructive"
                    >
                        {name}
                    </Button>
                </ButtonsContainer>
            </BottomDrawer>
        </Container>
    );
};

export default Incognito;
